import { Component } from "react";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { FinancingObject } from "../../../../../models/Engine";
import {
    getCreditConditionsComparisonWithLoadingTimeOut,
    setCreditAmountConditions,
    setIncomeConditions,
    setInterestTypeConditions,
    setLoadingCreditConditions,
    setOwnFundingConditions,
    setRuntimeConditions,
} from "../../../../../redux/actions/engine.actions";
import { INTEREST_TYPES_Value } from "../../../../../redux/reducers/engine.reducer";
import { RootState, ThunkBaseDispatch } from "../../../../../redux/store";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { SurveyWrapper } from "../../../../styles/base";
import { BankLogos } from "../creditConditions/bankLogos.component";
import ErrorMessage from "../creditConditions/errorMessage.component";
import GetInContact from "../creditConditions/getInContact.component";
import Information from "../creditConditions/information.component";
import CreditConditionsList from "../creditConditions/list/creditConditionsList.component";
import LoadingModal from "../creditConditions/loadingModal.component";
import Menu from "../creditConditions/menu/menu.component";
import NoResults from "../creditConditions/noResults.component";

export type MortgageCalculatorPageProps = MortgageCalculatorPageDispatchProps & MortgageCalculatorPageStateProps;

export class MortgageCalculatorPage extends Component<MortgageCalculatorPageProps> {
    isInitialRequestSent = false;

    state = {
        chosenOfferData: false,
        showAppointmentStep: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchCreditConditions(true);
    }

    fetchCreditConditions(initialFetch?: boolean) {
        const salary = this.props.income;
        const capital = this.props.ownFunding;
        const credit = this.props.creditAmount;
        const creditPurpose = "buy";
        const fixedYears = this.props.runtime;

        this.props.loadCreditConditions({
            salary,
            capital,
            credit,
            creditPurpose,
            fixedYears,
        });

        if (initialFetch) {
            this.isInitialRequestSent = true;
        } else {
            GtmServiceInstance.track({
                event: "creditCheck.modified",
            });
        }
    }

    onSubmit = () => {
        setTimeout(() => this.fetchCreditConditions(), 100);
    };

    onRuntimeChange = (event) => {
        this.props.setRuntime(parseInt(event.target.value));

        setTimeout(() => this.fetchCreditConditions(), 100);
    };

    onInterestTypeChange = (event) => {
        this.props.setInterestType(event.target.value);

        setTimeout(() => this.fetchCreditConditions(), 100);
    };

    onCreditAmountChange = (value) => {
        this.props.setCreditAmount(parseInt(value));

        setTimeout(() => this.fetchCreditConditions(), 100);
    };

    onOwnFundingChange = (value) => {
        this.props.setOwnFunding(parseInt(value));

        setTimeout(() => this.fetchCreditConditions(), 100);
    };

    onIncomeChange = (value: number) => {
        this.props.setIncome(Number(value));

        setTimeout(() => this.fetchCreditConditions(), 100);
    };

    onClickedCreditCondition = (chosenOfferData) => {
        this.setState({
            showAppointmentStep: true,
            chosenOfferData: `${chosenOfferData}&ownFunding=${this.props.ownFunding}&creditAmount=${this.props.creditAmount}&income=${this.props.income}`,
        });
    };

    handleBackToList = () => {
        this.setState({
            showAppointmentStep: false,
        });
    };

    renderError() {
        GtmServiceInstance.track({
            event: "creditCheck.error",
        });

        return <ErrorMessage onRepeatClick={() => this.fetchCreditConditions()} />;
    }

    renderNoResults() {
        GtmServiceInstance.track({
            event: "creditCheck.noResults",
        });

        return (
            <NoResults
                onNextClick={() => {
                    // TODO: go to booking appointment
                }}
            />
        );
    }

    renderInformationAccordion() {
        return <Information />;
    }

    renderMenu() {
        return (
            <Menu
                runtimeOptions={this.props.runtimeOptions}
                onRuntimeChange={this.onRuntimeChange}
                runtime={this.props.runtime}
                interestTypeOptions={this.props.interestTypeOptions}
                onInterestTypeChange={this.onInterestTypeChange}
                interestTypeSelected={this.props.interestType}
                onCreditAmountChange={this.onCreditAmountChange}
                creditAmountSelected={this.props.creditAmount}
                onOwnFundingChange={this.onOwnFundingChange}
                ownFunding={this.props.ownFunding}
                onIncomeChange={this.onIncomeChange}
                income={this.props.income}
                onSubmit={this.onSubmit}
            />
        );
    }

    renderHeaderBankLogos() {
        return <BankLogos />;
    }

    renderConditionsList() {
        return (
            <CreditConditionsListWrapper>
                <CreditConditionsList
                    conditions={this.props.creditConditions}
                    onClickedCreditCondition={this.onClickedCreditCondition}
                />
            </CreditConditionsListWrapper>
        );
    }

    renderLoadingModal() {
        return (
            <CSSTransition in={this.props.isLoading && !this.props.isError} timeout={500} unmountOnExit>
                <LoadingModal title="Die besten Kreditangebote werden berechnet..." />
            </CSSTransition>
        );
    }

    render() {
        return this.state.showAppointmentStep ? (
            <SurveyWrapper>
                <GetInContact
                    aboveTitle="Kreditangebot sichern"
                    meetingData={this.props.meetingData}
                    handleBackBtnClick={this.handleBackToList}
                    chosenOfferData={this.state.chosenOfferData}
                    titleWithAppointment={"Nur noch ein Schritt zu deinem Traumkredit!"}
                    titleWithoutAppointment={"Nur noch ein Schritt zu deinem Traumkredit!"}
                    mainText={
                        "Buche jetzt einen Termin bei unseren Finanzexperten um das gewählte Angebot für dich zu sichern. Gemeinsam werdet ihr alle notwendigen Dokumente für die Bank vorbereiten."
                    }
                />
            </SurveyWrapper>
        ) : (
            <SurveyWrapper>
                {this.renderMenu()}

                {this.renderHeaderBankLogos()}

                {this.renderInformationAccordion()}

                {this.props.isError && this.renderError()}

                {!this.props.isLoading &&
                    !this.props.isError &&
                    Array.isArray(this.props.creditConditions) &&
                    this.props.creditConditions.length > 0 &&
                    this.renderConditionsList()}

                {this.isInitialRequestSent &&
                    !this.props.isLoading &&
                    !this.props.isError &&
                    Array.isArray(this.props.creditConditions) &&
                    this.props.creditConditions.length === 0 &&
                    this.renderNoResults()}

                {this.renderLoadingModal()}
            </SurveyWrapper>
        );
    }
}

const CreditConditionsListWrapper = styled.div`
    margin-bottom: 15px;
`;

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.engine.conditions.isLoading,
        isError: state.engine.conditions.isError,
        errorMessage: state.engine.conditions.errorMessage,
        runtimeOptions: state.engine.conditions.runtimeOptions,
        runtime: state.engine.conditions.runtime,
        interestType: state.engine.conditions.interestType,
        interestTypeOptions: state.engine.conditions.interestTypeOptions,
        //creditConditions: state.engine.conditions.data,
        creditConditions: state.engine.conditions.filtered,
        income: state.engine.conditions.income,
        ownFunding: state.engine.conditions.ownFunding,
        creditAmount: state.engine.conditions.creditAmount,
        meetingData: state.hub.meeting,
    };
};
type MortgageCalculatorPageStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        loadCreditConditions: (financingObject: FinancingObject) =>
            dispatch(getCreditConditionsComparisonWithLoadingTimeOut(financingObject, 1000)),
        setRuntime: (runtime: number) => dispatch(setRuntimeConditions(runtime)),
        setInterestType: (interestType: INTEREST_TYPES_Value) => dispatch(setInterestTypeConditions(interestType)),
        setLoading: (isLoading: boolean) => dispatch(setLoadingCreditConditions(isLoading)),
        setOwnFunding: (ownFunding: number) => dispatch(setOwnFundingConditions(ownFunding)),
        setIncome: (income: number) => dispatch(setIncomeConditions(income)),
        setCreditAmount: (creditAmount: number) => dispatch(setCreditAmountConditions(creditAmount)),
    };
};
type MortgageCalculatorPageDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MortgageCalculatorPage);
