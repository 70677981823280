import parseHtml from "html-react-parser";
import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export type NoteProps = {
    label?: string;
    value?: string;
};

export class Note extends Component<NoteProps> {
    render() {
        return (
            <NoteWrapper>
                <span>{parseHtml(this.props.value || "")}</span>
            </NoteWrapper>
        );
    }
}

const NoteWrapper = styled.div`
    background: ${colors.lime100};
    border: 1px solid ${colors.lime300};
    border-radius: 8px;
    color: ${colors.lime900};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 24px;
    margin: 18px 0 32px;
`;

export default Note;
