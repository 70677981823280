import {
    ConfigActions,
    SET_GOOGLE_OPTIMIZE_ERROR,
    SET_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
    SET_GOOGLE_OPTIMIZE_IS_LOADING,
    SET_GOOGLE_OPTIMIZE_LOADING_ATTEMPTS,
    SET_GOOGLE_OPTIMIZE_VARIANT,
} from "../actions/config.actions";

export type ConfigState = {
    optimize: {
        experimentId: string | null;
        /* Polling count / attempts to fetch the current variant from google-optimize */
        loadingAttempts: number;
        /* Loading indicator to check if google-optimize is being loaded for the react app */
        isLoading: boolean;
        /* The id of google optimize that is interpreted for setting the work-flow to test */
        variant: string | null;
        /* Any error message when loaded the google-optimize variant fails */
        errorMessage: string | null;
        /* Indicates if an error occurred (convenient flag)*/
        isError: boolean;
    };
};

const initialState: ConfigState = {
    optimize: {
        experimentId: null,
        loadingAttempts: 0,
        isLoading: true,
        variant: null,
        errorMessage: null,
        isError: false,
    },
};

const configReducer = (state: ConfigState = initialState, action: ConfigActions): ConfigState => {
    switch (action.type) {
        case SET_GOOGLE_OPTIMIZE_EXPERIMENT_ID: {
            const { experimentId } = action;

            return Object.assign({}, state, {
                optimize: {
                    ...state.optimize,
                    experimentId,
                },
            });
        }
        case SET_GOOGLE_OPTIMIZE_LOADING_ATTEMPTS: {
            const { loadingAttempts } = action;

            return Object.assign({}, state, {
                optimize: {
                    ...state.optimize,
                    loadingAttempts,
                },
            });
        }
        case SET_GOOGLE_OPTIMIZE_VARIANT: {
            const { variant } = action;

            return Object.assign({}, state, {
                optimize: {
                    ...state.optimize,
                    variant,
                },
            });
        }
        case SET_GOOGLE_OPTIMIZE_IS_LOADING: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                optimize: {
                    ...state.optimize,
                    isLoading,
                },
            });
        }
        case SET_GOOGLE_OPTIMIZE_ERROR: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                optimize: {
                    ...state.optimize,
                    isError,
                    errorMessage,
                },
            });
        }
        default:
            return state;
    }
};

export default configReducer;
