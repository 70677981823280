import { Component } from "react";
import CreditConditionsCard from "./creditConditionsCard.component";

export type CreditConditionsListProps = {
    onClickedCreditCondition: (val: string) => void;
    conditions: {
        tag: string;

        periods: {
            interestRateType: string;
            interestRate: number;
            rate: number;
            months: number;
        }[];
        currency?: string;
        runTime?: number;
        payoutAmount?: number;
    }[];
};

export class CreditConditionsList extends Component<CreditConditionsListProps> {
    renderCreditConditionsCardList() {
        if (this.props.conditions && this.props.conditions.length > 0) {
            return this.props.conditions.map((condition, idx) => (
                <CreditConditionsCard key={idx} data={condition} onClick={this.props.onClickedCreditCondition} />
            ));
        }

        return null;
    }

    render() {
        return <>{this.renderCreditConditionsCardList()}</>;
    }
}

export default CreditConditionsList;
