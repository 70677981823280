import { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { invalidateSessionToken } from "../../redux/actions/session.actions";
import { RootState, ThunkBaseDispatch } from "../../redux/store";
import { Callback } from "../../redux/types";
import HubHeaderComponent from "../elements/survey/components/header/hubHeader.component";
import HubPage from "../elements/survey/components/pages/hub.component";
import { SurveyWrapper } from "../styles/base";
import { colors } from "../styles/constants/colors";
import GlobalStyles from "../styles/global-styles.hub";

export type HubContainerProps = unknown;

export class HubContainer extends Component<
    HubContainerProps & RouteComponentProps & HubContainerStateProps & HubContainerDispatchProps
> {
    render() {
        return (
            <>
                <GlobalStyles />
                <PageWrapper>
                    <HubHeaderComponent logout={this.props.logout} />
                    <HubPage
                        firstName={this.props.firstName}
                        meetingMeta={this.props.meetingMeta}
                        meetingLink={this.props.meetingLink}
                        isLoadingMeetingLink={this.props.isLoadingMeetingLink}
                    />
                    <StarsBackground className="starsBg">
                        <img src="/assets/svg/starsBackground.svg" width="85" height="68" alt="" />
                    </StarsBackground>
                </PageWrapper>
            </>
        );
    }
}

const PageWrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 120px 0 60px;
    background-color: ${colors.focusBg};

    .starsBg {
        display: block;
    }
`;

const StarsBackground = styled(SurveyWrapper)`
    display: none;
    margin-top: auto;
    text-align: right;

    img {
        margin-right: 16px;
    }
`;

const mapStateToProps = (state: RootState) => {
    return {
        firstName: state.hub.user.firstName,
        isLoadingMeetingLink: state.hub.meeting.isLoading,
        isLoadingUserData: state.hub.user.isLoading,
        meetingLink: state.hub.meeting.link,
        meetingMeta: state.hub.meeting.meta,
    };
};

type HubContainerStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        logout: (callback: Callback) => dispatch(invalidateSessionToken(callback)),
    };
};

type HubContainerDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HubContainer));
