import { isValidPhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js";
import qs from "qs";
import { Component } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import validator from "validator";
import AppConfig from "../../config/AppConfig";
import { setCookieProperties, setProperty, submitUserContacts } from "../../redux/actions/hubspot.actions";
import { RootState, ThunkBaseDispatch } from "../../redux/store";
import { handleCookies, normalizeName, normalizePhoneNumber } from "../../service/common/helper";
import GtmServiceInstance from "../../service/gtm/gtm.service";
import NavigationalNextButton from "../elements/survey/components/NavigationalNextButton";
import Properties from "../elements/survey/components/new/common/Properties";
import { ErrorMsg, InfoMsg, InputLabel, InputWrapper, SurveyWrapper, TextInput } from "../styles/base";
import { colors } from "../styles/constants/colors";
import GlobalStyles from "../styles/global-styles.hub";

export class RegistrationContainer extends Component<
    RegistrationContainerStateProps & RegistrationContainerDispatchProps & RouteComponentProps
> {
    state = {
        firstTimePrompt: false,
        agreementForMarketing: false,
        agreementForTermsOfUse: false,
        firstName: "",
        email: "",
        phone: "",
        redirect: "",
        isFirstNameInvalid: true,
        isEmailValid: false,
        isPhoneValid: false,
        onFocusPhoneInput: false,
        signUpFailure: false,
    };

    componentDidMount() {
        if (this.props.sessionToken != null) {
            this.props.history.push(AppConfig.urls.hub.index);
            return;
        }

        this.props.setCookieProperties(handleCookies());

        this.prefillQueryProps();
    }

    prefillQueryProps = () => {
        const queryArgs = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        const { email, redirect } = queryArgs;

        if (email || redirect) {
            this.setState({
                email: String(email || ""),
                redirect: String(redirect || ""),
            });
        }
    };

    onHandleSubmit = (e) => {
        e.preventDefault();

        const properties = {};

        const { firstName, lastName } = normalizeName(this.state.firstName);

        properties[Properties.FIRST_NAME] = firstName;

        properties[Properties.LAST_NAME] = lastName;

        properties[Properties.PHONE_UNFORMATTED] = this.state.phone;

        properties[Properties.PHONE] = normalizePhoneNumber(this.state.phone);

        properties[Properties.EMAIL] = this.state.email;

        properties[Properties.MARKETING] = this.state.agreementForMarketing;

        GtmServiceInstance.track({
            event: "signUp",
        });

        this.props.submitUserContacts(
            properties,
            this.props.cookieProperties,
            /* Sent magic link to user */
            true,
            /* Success callback after user contact creation */
            (error) => {
                if (error) {
                    console.error("Failed to create contact: ", error);

                    this.setState(
                        {
                            signUpFailure: true,
                        },
                        () => {
                            GtmServiceInstance.track({
                                event: "signUpFailure",
                            });
                        },
                    );
                } else {
                    // Redirect to hub after successfully finishing up registration
                    this.props.history.push(AppConfig.urls.hub.loginMagicLinkSent);
                }
            },
        );
    };

    handleMarketingAgreementInputChange = (e) => {
        this.setState({
            agreementForMarketing: e.target.checked,
        });
    };

    handleTermsAgreementInputChange = (e) => {
        this.setState({
            agreementForTermsOfUse: e.target.checked,
        });
    };

    handleFirstNameInputChange = (e) => {
        this.setState({
            firstName: e.target.value,
            isFirstNameInvalid: e.target.value.length <= 0,
        });
    };

    handleEmailInputChange = (e) => {
        this.setState({
            email: e.target.value,
            isEmailValid: e.target.value.length > 0 && validator.isEmail(e.target.value),
        });
    };

    handlePhoneInputChange = (e) => {
        let phoneNumber = null;
        let isPhoneValid = false;

        try {
            phoneNumber = parsePhoneNumberWithError(e.target.value, "AT");

            isPhoneValid = phoneNumber ? isValidPhoneNumber(e.target.value, "AT") && !!phoneNumber.number : false;
        } catch (error) {
            console.error(error);
        }

        this.setState({
            phone: e.target.value,
            isPhoneValid,
        });
    };

    render() {
        const showValidationMessage =
            this.state.email.length > 0 && this.state.email.includes("@") && !this.state.isEmailValid;

        return (
            <>
                <GlobalStyles />
                <PageWrapper>
                    <SurveyWrapper>
                        <Title>
                            Registrierung zu <BoldBlue>miracl hub</BoldBlue>.
                        </Title>

                        <form onSubmit={this.onHandleSubmit}>
                            {this.state.signUpFailure && (
                                <ErrorMsg>
                                    {"Registrierung fehlgeschlagen! Bitte überprüfe deine Telefonnummer und E-Mail."}
                                </ErrorMsg>
                            )}

                            {this.state.isFirstNameInvalid && this.state.firstTimePrompt && (
                                <ErrorMsg>{"Bitte trage deinen Namen ein"}</ErrorMsg>
                            )}

                            <InputWrapper>
                                <InputLabel>{"Name"}</InputLabel>
                                <TextInput
                                    autoFocus={true}
                                    placeholder="z. B. Max Mustermann"
                                    required={true}
                                    value={this.state.firstName}
                                    type={"text"}
                                    autoComplete={"given-name"}
                                    onChange={this.handleFirstNameInputChange}
                                />
                            </InputWrapper>

                            {showValidationMessage && (
                                <ErrorMsg>{"Überprüfe ob die angegebene E-Mail korrekt ist"}</ErrorMsg>
                            )}
                            <InputWrapper>
                                <InputLabel>{"E-Mail-Adresse"}</InputLabel>
                                <TextInput
                                    placeholder="z. B. max@miracl.at"
                                    type={"email"}
                                    autoComplete={"email"}
                                    required={true}
                                    onChange={this.handleEmailInputChange}
                                    value={this.state.email}
                                    disabled={false}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <InputLabel>{"Telefonnummer"}</InputLabel>
                                <TextInput
                                    placeholder="z. B. 0699 124 00 12"
                                    type={"tel"}
                                    autoComplete={"tel"}
                                    onFocus={() => {
                                        this.setState({
                                            onFocusPhoneInput: true,
                                        });
                                    }}
                                    onChange={this.handlePhoneInputChange}
                                    required={true}
                                    value={this.state.phone}
                                    disabled={false}
                                />
                                {this.state.phone.length >= 4 && !this.state.isPhoneValid && (
                                    <InfoMsg>{"Überprüfe deine Telefonnummer"}</InfoMsg>
                                )}
                            </InputWrapper>

                            <CheckBox>
                                <input
                                    type={"checkbox"}
                                    onChange={this.handleMarketingAgreementInputChange}
                                    required={true}
                                    value={String(this.state.agreementForMarketing || false)}
                                    hidden
                                    id="marketingCheckbox"
                                    checked={this.state.agreementForMarketing}
                                />

                                <label htmlFor="marketingCheckbox">
                                    Ich stimme zu, dass miracl mir die aktuellsten Informationen und Angebote per E-Mail
                                    und SMS zusenden kann (Abmeldung jederzeit möglich).
                                </label>
                            </CheckBox>
                            <br />
                            <CheckBox>
                                <input
                                    type={"checkbox"}
                                    onChange={this.handleTermsAgreementInputChange}
                                    required={true}
                                    value={String(this.state.agreementForTermsOfUse || false)}
                                    hidden
                                    id="termsCheckbox"
                                    checked={this.state.agreementForTermsOfUse}
                                />
                                <label htmlFor="termsCheckbox">
                                    Mit dem Absenden dieser Anfrage habe ich die{" "}
                                    <a
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                        href={"https://miracl.at/datenschutzerklaerung/"}
                                    >
                                        Datenschutzerklärung
                                    </a>
                                    {", "}
                                    <a target={"_blank"} rel={"noopener noreferrer"} href={"https://miracl.at/agb/"}>
                                        AGB
                                    </a>
                                    {" und das "}
                                    <a
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                        href={"https://miracl.at/standardinfoblatt/"}
                                    >
                                        Standardinformationsblatt
                                    </a>{" "}
                                    gelesen und stimme ihnen zu.
                                </label>
                            </CheckBox>

                            <NavigationalNextButton
                                text={"Registrieren"}
                                loadingText={"Lade ..."}
                                onClick={this.onHandleSubmit}
                                isLoading={this.props.isLoading}
                                disabled={
                                    !(
                                        this.state.isEmailValid &&
                                        this.state.isPhoneValid &&
                                        this.state.agreementForTermsOfUse &&
                                        !this.state.isFirstNameInvalid
                                    )
                                }
                            />
                        </form>

                        <Divider />

                        <Main>
                            <Title>
                                Du hast schon ein Konto? - <Link to={AppConfig.urls.hub.login}>Hier gehts weiter</Link>
                            </Title>
                        </Main>
                    </SurveyWrapper>
                </PageWrapper>
            </>
        );
    }
}

const Main = styled.div`
    align-items: center;
    justify-content: center;
`;

const BoldBlue = styled.b`
    color: ${colors.primary};
`;

const Divider = styled.div`
    margin-top: 36px;
    margin-bottom: 36px;
    height: 1px;
    margin-right: 16px;
    margin-left: 16px;
    background: ${colors.gray};
`;

const Title = styled.p`
    color: #000;
    display: block;
    font-size: 22px;
    font-weight: lighter;
    letter-spacing: 0px;
    margin-top: 0px;
    line-height: normal;
`;

const PageWrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 120px 0 60px;
    background-color: ${colors.focusBg};
`;

const CheckBox = styled.div`
    box-sizing: border-box;
    font-size: 11px;
    margin: 0 auto;
    padding: 3px 0 0 40px;
    position: relative;

    label {
        :before {
            border: 2px solid #8f9eab;
            border-radius: 2px;
            content: "";
            cursor: pointer;
            height: 21px;
            left: 0;
            position: absolute;
            top: 0;
            width: 21px;
        }
    }

    input:checked + label {
        :before {
            border-color: #4a4aff;
            align-items: center;
            color: #4a4aff;
            display: flex;
            font-size: 20px;
            justify-content: center;
            content: "✔";
            height: 21px;
            width: 21px;
        }
    }
`;

const mapStateToProps = (state: RootState) => {
    return {
        properties: state.hubspot.properties,
        cookieProperties: state.hubspot.cookies,
        isLoading: state.hubspot.isLoading,
        sessionToken: state.session.token,
    };
};

type RegistrationContainerStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        setProperty: (_property, _value) => dispatch(setProperty(_property, _value)),
        submitUserContacts: (_properties, _cookies, _magicLink, _cb) =>
            dispatch(submitUserContacts(_properties, _cookies, _magicLink, _cb)),
        setCookieProperties: (_props) => dispatch(setCookieProperties(_props)),
    };
};

type RegistrationContainerDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationContainer));
