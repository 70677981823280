import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { version } from "../../package.json";
import AppConfig from "./AppConfig";

if (AppConfig.sentry) {
    Sentry.init({
        dsn: "https://b6faa25ab6e54eeabb59330050a44b2d@o1040867.ingest.sentry.io/6075295",
        release: `miracl-react-survey@${version}`,
        integrations: [new Integrations.BrowserTracing()],
        environment: AppConfig.ApplicationServer.stage,
        normalizeDepth: 10,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    // userId is set in providers.js
}
