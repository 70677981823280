import { Component, Fragment } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { getDealRequiredFiles } from "../../../../../service/api";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import Skeleton from "../skeleton";
import { SUB_CATEGORY } from "./constants";
import DocumentUploadItem from "./documentUploadItem.component";
import DocumentUploadModal from "./documentUploadModal.component";

const loader = <Skeleton style={{ marginBottom: ".75rem", height: "32px" }} />;

export type DocumentUploadListProps = {
    dealId: string;
    token: string;
};

export class DocumentUploadList extends Component<DocumentUploadListProps> {
    state = {
        modal: undefined,
        error: undefined,
        isLoading: true,
        documents: undefined,
        properties: undefined,
        required: undefined,
    };

    async componentDidMount() {
        this.setState((prev) => ({ ...prev, error: undefined, isLoading: true }));
        const { dealId, token } = this.props;
        const response = await getDealRequiredFiles({ token, id: dealId });
        if (!response || !response.data || !response.data.data) {
            this.setState((prev) => ({ ...prev, error: true, isLoading: false }));
            return;
        }
        const { documents, properties, required } = response.data.data;
        this.setState((prev) => ({ ...prev, documents, properties, required, isLoading: false }));
    }

    render() {
        const handleModalOpen = (title, description) => {
            this.setState((prev) => ({
                ...prev,
                modal: {
                    isOpen: true,
                    title,
                    description,
                },
            }));

            GtmServiceInstance.track({
                event: "financingTimeline.documentInfoOpen",
                documentTitle: title,
            });
        };

        const handleModalClose = () => {
            this.setState((prev) => ({
                ...prev,
                modal: undefined,
            }));
        };

        const { documents = {}, properties, required, isLoading, error, modal = {} } = this.state;
        let ui = null;
        const otherUi = (
            <>
                <MainTitle>Sonstiges</MainTitle>
                <DocumentUploadItem
                    dealId={this.props.dealId}
                    item={documents["other"]}
                    property={
                        properties && properties[2]["sonstiges"]
                            ? properties[2]["sonstiges"][0]
                            : { name: "Sonstiges", value: "sonstiges" }
                    }
                    token={this.props.token}
                    handleModalOpen={handleModalOpen}
                />
            </>
        );
        if (isLoading) {
            ui = loader;
        } else if (error || !required || required.length === 0 || !properties) {
            ui = otherUi;
        } else {
            ui = Object.keys(documents).map((subId) => {
                if (!documents[subId]) return null;
                const subProperty = properties[0][subId];
                return (
                    <Fragment key={subId}>
                        {subProperty ? <MainTitle>{SUB_CATEGORY[subProperty.value]}</MainTitle> : null}
                        {Object.keys(documents[subId]).map((typeId) => {
                            return (
                                <Fragment key={typeId}>
                                    <DocumentUploadItem
                                        dealId={this.props.dealId}
                                        item={documents[subId][typeId]}
                                        property={properties[0][typeId]}
                                        subProperty={subProperty}
                                        token={this.props.token}
                                        handleModalOpen={handleModalOpen}
                                    />
                                </Fragment>
                            );
                        })}
                    </Fragment>
                );
            });
        }

        return (
            <Wrapper>
                {ui}
                <CSSTransition in={!!modal.isOpen} timeout={1300} unmountOnExit>
                    <DocumentUploadModal handleModalClose={handleModalClose} title={modal.title || ""}>
                        {modal.description || ""}
                    </DocumentUploadModal>
                </CSSTransition>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 15px 0 0 0;
    padding: 0;
`;
const MainTitle = styled.h4`
    font-size: 1rem;
    margin: 0;
    padding: 0.5rem 0 0.25rem;
`;

export default DocumentUploadList;
