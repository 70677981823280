const InputTypes = {
    singleSelectionGroupButton: "singleSelectionGroupButton",
    textDropDown: "textDropDown",
    slider: "slider",
    numericInput: "numericInput",
    doubleNumericInput: "doubleNumericInput",
    phoneInput: "phoneInput",
    textInput: "textInput",
    textarea: "textarea",
    dateInput: "dateInput",
    checkBox: "checkBox",
    percentageNumberInput: "percentageNumberInput",
    emailInput: "emailInput",
    loanAmountCardSummary: "loanAmountCardSummary",
    multipleSelection: "multipleSelection",
    note: "note",
    gamification: "gamification",
    dayPicker: "dayPicker",
    timePicker: "timePicker",
    quickRate: "quickRate",
    offer: "offer",
    title: "title",
} as const;

export type InputTypesKeys = keyof typeof InputTypes;
export type InputTypesValues = (typeof InputTypes)[InputTypesKeys];

export default InputTypes;
