import { isValidPhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js";
import { Component } from "react";
import "react-dropdown/style.css";
import styled from "styled-components";
import validator from "validator";
import { InputCss } from "../../../../styles/newStyle";
import { PageStructure } from "../pages/new/types";
import QuestionItem from "./QuestionItem";

const ENTER_KEY = "Enter";

export type PhoneInputProps = {
    label?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    value: number | null;
    errors: React.ReactNode[];
    allowLeadingZeros?: boolean;
    onChange: (value: { value: string | null }) => void;
    pageStructure: PageStructure;
    pageName: string;
    tooltip?: string;
    tooltipAlignBottom?: boolean;
    helperText: string;
    required?: boolean;
    property: string;
    disabled?: boolean;
    autocomplete?: boolean;
};

type PhoneInputState = {
    input?: string;
    phone: string;
    isPhoneValid: boolean;
};

export class PhoneInput extends Component<PhoneInputProps, PhoneInputState> {
    state = {
        input: undefined,
        phone: "",
        isPhoneValid: false,
    };

    handlePhoneInputChange = (e) => {
        let phoneNumber = null;
        let isPhoneValid = false;

        try {
            phoneNumber = parsePhoneNumberWithError(e.target.value, "AT");

            isPhoneValid = phoneNumber ? isValidPhoneNumber(e.target.value, "AT") && !!phoneNumber.number : false;
        } catch (error) {
            console.error(error);
        }

        this.setState({
            phone: e.target.value,
            isPhoneValid,
        });
    };

    render() {
        return (
            <QuestionItem
                pageName={this.props.pageName}
                tooltip={this.props.tooltip}
                tooltipAlignBottom={this.props.tooltipAlignBottom}
                wrapperWidth="100%"
                errors={this.props.errors}
                label={this.props.label}
                helperText={this.props.helperText}
            >
                <label>
                    <PhoneNumberInput
                        placeholder={this.props.placeholder}
                        type={"tel"}
                        autoComplete={"tel"}
                        inputmode={"tel"}
                        autoFocus={this.props.autoFocus}
                        onChange={this.handlePhoneInputChange}
                        required={this.props.required}
                        name={this.props.property}
                        value={this.state.phone}
                        disabled={this.props.disabled || false}
                        onBlur={() => {
                            this.props.onChange({
                                value: this.state.phone,
                            });
                        }}
                        onKeyDown={(e) => {
                            if (validator.equals(e.key, ENTER_KEY)) {
                                this.props.onChange({
                                    value: this.state.phone,
                                });
                            }
                        }}
                    />
                    <span className="label">
                        {this.props.label}
                        {this.props.required && <span className="required">&nbsp;*</span>}
                    </span>
                </label>
            </QuestionItem>
        );
    }
    static defaultProps = {
        label: null,
        autoFocus: false,
        placeholder: "z. B. 0699 124 00 12",
        value: null,
        disabled: false,
    };
}

const PhoneNumberInput = styled.input`
    ${InputCss};
`;

export default PhoneInput;
