import { Component } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export type TooltipProps = {
    color?: string;
    arrowPosLeft?: string | number;
    value?: string | null;
};

export class Tooltip extends Component<TooltipProps> {
    render() {
        return (
            <Wrapper color={this.props.color} arrowPosLeft={this.props.arrowPosLeft ?? 22}>
                {this.props.value || ""}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    background: #fff;
    border: 1px solid ${colors.gray300};
    border-radius: 16px;
    box-sizing: border-box;
    color: ${colors.gray600};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    margin: 14px 0 32px;
    position: relative;

    &:before {
        background-color: #fff;
        border: 1px solid ${colors.gray300};
        content: "";
        height: 10px;
        left: ${({ arrowPosLeft }) => arrowPosLeft};
        margin-left: -8px;
        position: absolute;
        top: -6px;
        transform: rotate(45deg);
        width: 10px;
    }

    &:after {
        background-color: #fff;
        content: "";
        display: block;
        height: 8px;
        left: 14px;
        position: absolute;
        top: 0px;
        width: 92%;
    }

    ${({ color }) =>
        color === "green" &&
        css`
            background-color: ${colors.lime100};
            border-color: ${colors.lime300};
            color: ${colors.lime900};

            &:before {
                background-color: ${colors.lime100};
                border-color: ${colors.lime300};
            }
            &:after {
                background-color: ${colors.lime100};
            }
        `};
`;

export default Tooltip;
