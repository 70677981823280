import { Component } from "react";
import Collapsible from "react-collapsible";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { colors } from "../../../../styles/constants/colors";
import FinancingExample from "../financingExample.component";

export type InformationProps = {
    title?: string;
    description?: string[];
};

export class Information extends Component<InformationProps> {
    state = {
        showFinancingModal: false,
    };

    render() {
        const handleModalOpen = () => {
            document.body.classList.add("modal-open");
            this.setState({
                showFinancingModal: true,
            });
        };

        const handleModalClose = () => {
            document.body.classList.remove("modal-open");
            this.setState({
                showFinancingModal: false,
            });
        };

        return (
            <Wrapper>
                <Collapsible
                    trigger={this.props.title || ""}
                    easing="ease-out"
                    transitionTime={200}
                    onOpen={() => {
                        GtmServiceInstance.track({
                            event: "creditCheck.moreInformation",
                        });
                    }}
                >
                    <ul>
                        {(this.props.description || []).map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                        <li>
                            {"Hier findest du ein "}
                            <span className="link" onClick={handleModalOpen}>
                                {"Finanzierungsbeispiel"}
                            </span>
                            {"."}
                        </li>
                    </ul>
                </Collapsible>
                <CSSTransition in={this.state.showFinancingModal} timeout={1300} unmountOnExit>
                    <FinancingExample handleModalClose={handleModalClose} />
                </CSSTransition>
            </Wrapper>
        );
    }

    static defaultProps = {
        title: "Informationen zur Berechnungsgrundlage",
        description: [
            "Die folgenden Finanzierungen wurden anhand deiner bereits angegebenen Daten berechnet.",
            "Die angezeigten Finanzierungen sind keine verbindlichen Angebote und basieren auf Annahmen zu deinem Alter, Wert der Immobilie, sowie Art deines Einkommens.",
            "Um ein genaues Angebot zu erstellen werden unsere Finanzierungsspezialist:innen mit dir die nötigen Daten erheben.",
            "Grundsätzlich gilt: für jede 500€ Monatsmiete sind in etwa 150.000€ Kredit möglich.",
            "Weiters sind 10-20% Eigenmittel empfehlenswert, jedoch nicht zwingend nötig.",
            "Außerdem kann die Anzahl an Kreditnehmern deine Konditionen beeinflussen.",
        ],
    };
}

const Wrapper = styled.div`
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 5px;
    //border: 1px solid #4a49fb;
    //border-radius: 5px;
    color: #17120f;
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 16px;
    margin: 20px 0 15px;
    padding: 3px 15px;

    .Collapsible__trigger {
        color: ${colors.primary};
        cursor: pointer;
        display: block;
        line-height: 30px;
        position: relative;

        &:after {
            border-top: 1px solid ${colors.primary};
            border-right: 1px solid ${colors.primary};
            content: "";
            height: 8px;
            position: absolute;
            right: 0;
            top: 8px;
            transform: rotate(135deg);
            width: 8px;
        }
        &.is-open:after {
            margin-top: 5px;
            transform: rotate(-45deg);
        }
    }
    .Collapsible__contentInner {
        line-height: 18px;
        margin: 10px 0 12px;
    }
    ul {
        margin: 0;
        list-style: none;
        padding: 0 0 0 15px;
    }
    li {
        margin-top: 5px;

        &:first-child {
            margin-top: 0;
        }
        :before {
            content: "•";
            color: ${colors.primary};
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
`;

export default Information;
