import defaultWorkFlow from "./workflows/default.workflow";

import v32 from "./workflows/v32.workflow";

import v33 from "./workflows/v33.workflow";

/* Here you can import more survey workflows for GO experiments */
export const SURVEY_WORK_FLOWS = {
    "2": v32,
    "1": v33,
    "0": defaultWorkFlow,
    defaultWorkFlow,
};
