import { Component } from "react";

import {
    SURVEY_PAGE_PROPERTIES,
    SURVEY_WORKFLOW_PAGE_INDICES,
} from "../../../../../../../redux/reducers/survey/config/survey.config";

import StructuredPageComponent from "../../structuredPage.component";

import LoadingModal from "../../../creditConditions/loadingModal.component";

import validator from "validator";

import type { PageProps } from "../types";

import { Answer } from "../../../new/common/Answers";

export type EmailPageProps = PageProps;

export class EmailPage extends Component<EmailPageProps> {
    componentDidMount() {
        if (this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.topOffer]) {
            this.requestTopOffer();
        }
    }

    requestTopOffer = () => {
        const { INTENTION, PROGRESS } = this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure;

        const {
            AGENT_TAX,
            AVAILABLE_CAPITAL,
            FIXED_RUNTIME_IN_YEARS,
            FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS,
            TAX,
            NOTARY,
            CALCULATION_INTENTION,
            // All other instances that are not "Kauf einer Immobilie" (Sonstiges, Bauvorhaben etc)
            LOAN_AMOUNT_BUYING,
            // Research Phase
            EXPECTED_PRICE,
            // Buying phase + knows credit amount
            LOAN_AMOUNT,
            // Wants to calc. the credit / financing
            PRICE,
            /* Wants to do Umschuldung */
            REFINANCING_DEBT_LEFT_AMOUNT,
        } = this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.loanAmount].pageStructure;

        const { PRIMARY_PERSON_INCOME, SECONDARY_PERSON_INCOME } =
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.personalFinances].pageStructure;

        const overallSalary =
            (PRIMARY_PERSON_INCOME.attributes.value || 0) + (SECONDARY_PERSON_INCOME.attributes.value || 0);

        const isRefinancing = validator.equals(INTENTION.attributes.value, Answer.INTENTION.RE_FINANCING);

        const isOfferNeededForLoanAmount =
            PROGRESS.attributes.value != null &&
            (validator.equals(PROGRESS.attributes.value, Answer.PROGRESS.ABOUT_TO_SEND_OFFER) ||
                validator.equals(PROGRESS.attributes.value, Answer.PROGRESS.OFFER_SENT));

        const isBuyingIntention =
            INTENTION.attributes.value != null &&
            validator.equals(INTENTION.attributes.value, Answer.INTENTION.BUYING_PROPERTY);

        const isLoanAmountNotKnown =
            isBuyingIntention &&
            isOfferNeededForLoanAmount &&
            CALCULATION_INTENTION.attributes.value != null &&
            /* validator needs a value != null */
            validator.equals(CALCULATION_INTENTION.attributes.value, Answer.Conditional.NO);

        /* We increase the new estimated value (defined by consti) in order to make sure that the "Refinancing" is possible for most offers */
        const newEstimatedValueForRefinancing = REFINANCING_DEBT_LEFT_AMOUNT.attributes.value / 0.8;

        const netPurchasePrice = isRefinancing
            ? newEstimatedValueForRefinancing
            : // Depending on what the user chooses in the segmentation the buying price is taken over
              LOAN_AMOUNT_BUYING.attributes.value ||
              LOAN_AMOUNT.attributes.value ||
              PRICE.attributes.value ||
              EXPECTED_PRICE.attributes.value;

        const offerRequest = {
            overallSalary: Number(overallSalary),
            equity: Number(AVAILABLE_CAPITAL.attributes.value != null ? AVAILABLE_CAPITAL.attributes.value : 0),
            /* "refinancing" or "purchase" */
            financingPurpose: isRefinancing ? "refinancing" : "purchase",
            /* New estimated value of the estate when refinancing or purchase value when buying */
            netPurchasePrice: Number(netPurchasePrice),
            /* refinancing only: balance of credit (Restschuld vom Kredit) on refinancing */
            creditBalance: Number(isRefinancing ? REFINANCING_DEBT_LEFT_AMOUNT.attributes.value : 0),
            // fees
            realEstateTransferTax: Number(!isLoanAmountNotKnown || isRefinancing ? 0 : TAX.attributes.value),
            propertyRegistrationFee: Number(!isLoanAmountNotKnown ? 0 : 1.1),
            purchaseContractCreationFee: Number(!isLoanAmountNotKnown || isRefinancing ? 0 : NOTARY.attributes.value),
            estateAgentFee: Number(!isLoanAmountNotKnown || isRefinancing ? 0 : AGENT_TAX.attributes.value),
            runtimeYears: Number(
                FIXED_RUNTIME_IN_YEARS.attributes.value && validator.isNumeric(FIXED_RUNTIME_IN_YEARS.attributes.value)
                    ? FIXED_RUNTIME_IN_YEARS.attributes.value
                    : 30,
            ),
            fixedYears: Number(
                FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS.attributes.value &&
                    validator.isNumeric(FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS.attributes.value)
                    ? FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS.attributes.value
                    : 20,
            ),
        };

        this.props.getTopOffer(offerRequest);
    };

    renderLoading() {
        return <LoadingModal subTitle="Berechnung läuft" />;
    }

    render() {
        const { isLoading, isError } = this.props.topOffer;

        return (
            <div>
                {isLoading && !isError && this.renderLoading()}

                <StructuredPageComponent
                    /* Hubspot state loading */
                    isLoadingHubspotSubmit={this.props.isLoadingHubspotSubmit}
                    /* Change of components values */
                    setPageComponentValue={this.props.setPageComponentValue}
                    /* Change of components errors */
                    setPageComponentError={this.props.setPageComponentError}
                    /* Access to the survey config */
                    workflow={this.props.workflow}
                    /* Access to the survey version */
                    surveyVersion={this.props.surveyVersion}
                    /* Page config name for analytics */
                    pageName={SURVEY_PAGE_PROPERTIES.email}
                    /* Page Structure defining the elements to be rendered*/
                    pageStructure={this.props.page.pageStructure}
                    /* Convenient Current page data */
                    page={this.props.page}
                    /* Action for adding properties for user-contact creation  */
                    setProperty={this.props.setProperty}
                    /* Property list of collected user-data during the survey */
                    properties={this.props.properties}
                    /* Handler for moving to the next page */
                    onNextSurveyPage={this.props.onNextSurveyPage}
                    /* Handler for moving to the prev page */
                    onPreviousSurveyPage={this.props.onPreviousSurveyPage}
                    /* Show error after creating contact failed */
                    createContactError={this.props.createContactError}
                />
            </div>
        );
    }
}

export default EmailPage;
