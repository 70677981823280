import { History, Location } from "history";
import qs from "qs";
import { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import validator from "validator";
import AppConfig from "../../config/AppConfig";
import { RedirectProps } from "../../models/Location";
import { Callback } from "../../redux/types";
import GtmServiceInstance from "../../service/gtm/gtm.service";
import LoadingModal from "../elements/survey/components/creditConditions/loadingModal.component";
import NavigationalNextButton from "../elements/survey/components/NavigationalNextButton";
import { ErrorMsg, InputLabel, InputWrapper, SurveyWrapper, TextInput } from "../styles/base";
import { colors } from "../styles/constants/colors";
import GlobalStyles from "../styles/global-styles.hub";

export type LoginContainerProps = {
    sessionToken: string | null;
    isLoading?: boolean;
    history: History;
    location: Location<RedirectProps>;
    validateMagicToken: (token: string, callback: Callback) => void;
    trackPageView: () => void;
    getMagicLink: (data: { email: string; redirect?: string }, callback: Callback) => void;
};

export class LoginContainer extends Component<LoginContainerProps> {
    state = {
        email: "",
        isEmailValid: false,
        magicLinkFailure: false,
        magicLinkSuccess: false,
        loadingMagicLink: false,
    };

    componentDidMount() {
        if (this.props.sessionToken != null) {
            this.props.history.push(AppConfig.urls.hub.index);
            return;
        }

        this.loginViaMagicTokenLink(this.parseMagicLinkToken());
    }

    loginViaMagicTokenLink = ({ token, redirect }) => {
        if (token) {
            this.setState(
                {
                    loadingMagicLink: true,
                    // If the token is invalid, just send the user to the e-mail magic link, we backend will keep track of sentry exceptions
                },
                () =>
                    this.props.validateMagicToken(token, () => {
                        this.props.trackPageView();

                        this.props.history.push(redirect ? redirect : AppConfig.urls.hub.index);
                    }),
            );
        }
    };

    parseMagicLinkToken = () => {
        const queryArgs = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        const { token, redirect } = queryArgs;

        return {
            token,
            redirect,
        };
    };

    onHandleSubmit = (e) => {
        e.preventDefault();

        const { email } = this.state;
        const { state = {} } = this.props.location || {};
        const { pathname, search } = state.referrer || {};
        const redirect = pathname ? `${pathname}${search}` : undefined;

        this.props.getMagicLink({ email, redirect }, (err) => {
            this.setState(
                {
                    magicLinkFailure: !!err,
                    magicLinkSuccess: !err,
                },
                () => {
                    GtmServiceInstance.track({
                        event: err ? "magicLinkError" : "magicLinkSuccess",
                    });

                    if (err) {
                        this.props.history.push(
                            `${AppConfig.urls.hub.register}?email=${this.state.email}${
                                redirect ? `&redirect=${redirect}` : ""
                            }`,
                        );
                    }
                },
            );
        });
    };

    handleEmailInputChange = (e) => {
        this.setState({
            email: e.target.value,
            isEmailValid: e.target.value.length > 0 && validator.isEmail(e.target.value),
        });
    };

    renderMagicLinkLoading() {
        return (
            <>
                <GlobalStyles />
                <PageWrapper>
                    <SurveyWrapper>
                        <LoadingModal
                            title={"Dein Magic Link wird gerade überprüft."}
                            subTitle={"Gleich geht's weiter zum Hub"}
                        />
                    </SurveyWrapper>
                </PageWrapper>
            </>
        );
    }

    renderMagicLinkWasSent() {
        return (
            <>
                <GlobalStyles />
                <PageWrapper>
                    <SurveyWrapper>
                        <h3>Du hast Post! Überprüfe deine E-Mails.</h3>
                        <p>Wir haben dir einen Magic Link geschickt! Klicke darauf um die Anmeldung abzuschließen.</p>
                    </SurveyWrapper>
                </PageWrapper>
            </>
        );
    }

    render() {
        const showValidationMessage =
            this.state.email.length > 0 && this.state.email.includes("@") && !this.state.isEmailValid;

        if (this.state.loadingMagicLink) {
            return this.renderMagicLinkLoading();
        }

        if (!this.state.magicLinkFailure && this.state.magicLinkSuccess) {
            this.props.history.push(AppConfig.urls.hub.loginMagicLinkSent);
        }

        return (
            <>
                <GlobalStyles />
                <PageWrapper>
                    <SurveyWrapper>
                        <Title>
                            Wir senden dir einen Magic Link für die Anmeldung zum <BoldBlue>miracl hub</BoldBlue>.
                        </Title>

                        <form onSubmit={this.onHandleSubmit}>
                            {this.state.magicLinkFailure && (
                                <ErrorMsg>{"Anmeldung fehlgeschlagen! Bitte überprüfe deine E-Mail Adresse."}</ErrorMsg>
                            )}
                            {showValidationMessage && (
                                <ErrorMsg>{"Überprüfe ob die angegebene E-Mail korrekt ist"}</ErrorMsg>
                            )}

                            <InputWrapper>
                                <InputLabel>{"E-Mail-Adresse"}</InputLabel>
                                <TextInput
                                    autoFocus={true}
                                    placeholder="z. B. max@miracl.at"
                                    type={"email"}
                                    autoComplete={"email"}
                                    required={true}
                                    onChange={this.handleEmailInputChange}
                                    value={this.state.email}
                                    disabled={this.state.loadingMagicLink}
                                />
                            </InputWrapper>

                            <NavigationalNextButton
                                text={"Abschicken"}
                                loadingText={"Lade ..."}
                                onClick={this.onHandleSubmit}
                                isLoading={this.props.isLoading}
                                disabled={!this.state.isEmailValid}
                            />
                        </form>

                        <Divider />

                        <Main>
                            <Title>
                                Noch nicht bei miracl dabei? -{" "}
                                <Link to={AppConfig.urls.hub.register}>Hier gehts weiter</Link>
                            </Title>
                        </Main>
                    </SurveyWrapper>
                </PageWrapper>
            </>
        );
    }
}

const Main = styled.div`
    align-items: center;
    justify-content: center;
`;

const BoldBlue = styled.b`
    color: ${colors.primary};
`;

const Divider = styled.div`
    margin-top: 36px;
    margin-bottom: 36px;
    height: 1px;
    margin-right: 16px;
    margin-left: 16px;
    background: ${colors.gray};
`;

const Title = styled.p`
    color: #000;
    display: block;
    font-size: 22px;
    font-weight: lighter;
    letter-spacing: 0px;
    margin-top: 0px;
    line-height: normal;
`;

const PageWrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 120px 0 60px;
    background-color: ${colors.focusBg};
`;

export default LoginContainer;
