import { Component } from "react";
import styled from "styled-components";
import { bpSurvey } from "../../../../styles/constants/breakpoints";

export class BankLogos extends Component {
    render() {
        return (
            <Wrapper>
                <Title>Teilnehmende Banken</Title>
                <Banks>
                    <li>
                        <img src="/assets/banks/bank.bausparkasse.svg" height="28" alt="Bausparkasse" />
                    </li>
                    <li className="bawag">
                        <img src="/assets/banks/bank.bawag.svg" height="36" alt="Bawag" />
                    </li>
                    <li>
                        <img src="/assets/banks/bank.wuestenrot.svg" height="28" alt="Wüstenrot" />
                    </li>
                    <li>
                        <img src="/assets/banks/bank.austria.svg" height="29" alt="Bank Austria" />
                    </li>
                </Banks>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    overflow: hidden;
    margin: 18px 0 15px;
    position: relative;

    &:after,
    &:before {
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        width: 30px;
        z-index: 1;
    }
    &:after {
        background: linear-gradient(90deg, rgba(243, 244, 245, 0) 0%, rgba(243, 244, 245, 1) 100%);
        right: 0;
    }
    &:before {
        background: linear-gradient(270deg, rgba(243, 244, 245, 0) 0%, rgba(243, 244, 245, 1) 100%);
        left: 0;
    }
`;

const Title = styled.div`
    color: #999;
    font-size: 12px;
    font-style: italic;
    margin: 0 0 8px;
    text-align: center;

    @media (min-width: ${bpSurvey.mediumMin}) {
        font-size: 13px;
    }
`;

const Banks = styled.ul`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    list-style: none;
    flex-wrap: nowrap;
    height: 30px;
    margin: 0 0 0 -10px;
    padding: 0;
    width: 100%;

    li {
        margin: 0 6px;

        &.bawag img {
            margin-top: -10px;
        }
    }

    img {
        display: block;
        opacity: 0.6;
    }
`;

export default BankLogos;
