import * as Sentry from "@sentry/react";
import { Component } from "react";
import styled from "styled-components";
import { DEFAULT_FOLLOW_UP_LINK } from "../../../../../redux/actions/hub.actions";
import { CenterBlock } from "../../../../styles/base";
import { Oval } from "react-loader-spinner";
import ErrorLabel from "./ErrorLabel";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { colors } from "../../../../styles/constants/colors";
import Spacer from "../../../../styles/custom/layout/Space";
import { Button, ButtonCss } from "../../../../styles/newStyle";
import Modal from "./Modal";
import NumberInput from "./NumberInput";
import ConfirmationSection from "./ConfirmationSection";

export type CallSchedulerProps = {
    isGoodRate?: boolean;
    data: any;
    user?: any;
    email: string;
    isOther: boolean;
    question: string;
    showConfirmation: boolean;
    verboseConfirmation?: boolean;
    notifyAboutUser: (callNow: boolean, newPhoneNumber: string | null) => void;
};

export class CallScheduler extends Component<CallSchedulerProps> {
    state = {
        day: "today",
        loadingCallNow: false,
        callNowSuccess: this.props.showConfirmation,
        callNowError: false,
        showModal: false,
        newPhone: null,
        isHolidays: false,
    };

    DAYS = [
        {
            value: "now",
            text: "Jetzt",
        },
        {
            value: "today",
            text: "Heute",
        },
        {
            value: "tomorrow",
            text: "Morgen",
        },
        {
            value: "dayAfter",
            text: "Tag danach",
        },
    ];

    getLocalTimeVienna = () => {
        const date = new Date();
        const utc = date.getTime() + date.getTimezoneOffset() * 60000;
        const vienna = new Date(utc + 3600000 * 2);
        return vienna;
    };

    /**
     * @returns {boolean} returns true if the current local time in vienna is between 9.00 and 18.00 and its a
     * working day (not holidays) else false
     */
    isWorkingDayAndTime = () => {
        const now = this.getLocalTimeVienna(); //new Date();
        const day = now.getDay();
        const hour = now.getHours();

        const isWorkingDay = day >= 1 && day <= 5;
        const isWorkingTime = hour >= 9 && hour <= 18;

        return isWorkingDay && isWorkingTime && !this.state.isHolidays;
    };

    renderPhoneNumberChangeInput() {
        const { phone } = this.props.user;

        return (
            <Modal>
                <h3>
                    <span>Telefonnummer ändern</span>
                </h3>

                <p>Überprüfe und ändere deine Telefonnummer, wir rufen dich in ca. 15 Minuten zurück.</p>

                <NumberInput
                    label="Telefonnummer"
                    value={this.state.newPhone || phone || ""}
                    placeholder="z. B. 00436781240015"
                    pageStructure={{}}
                    onChange={(arg) => {
                        this.setState({
                            newPhone: arg.value,
                        });
                    }}
                />

                <Button blue onClick={() => this.setState({ showModal: false })}>
                    Übernehmen
                </Button>
            </Modal>
        );
    }

    renderHeaderMessage = () => {
        if (this.props.verboseConfirmation) {
            return <h3>{this.props.question || "Wann bist du am besten erreichbar?"}</h3>;
        }

        const message = this.props.isGoodRate
            ? "Geschafft! Wir melden uns telefonisch zur Prüfung deiner Bonität für die Bestkonditionen."
            : "Wir prüfen dein Anliegen gerne im Detail!";

        const subLabel = this.props.isGoodRate
            ? "Wann können wir dich telefonisch am besten erreichen?"
            : "Wann können wir dich diesbezüglich am besten erreichen?";

        return (
            <>
                <p>{message}</p>

                <h3>{subLabel}</h3>
                <br />
            </>
        );
    };

    render() {
        const { phone } = this.props.user;

        if (this.state.callNowSuccess && this.props.verboseConfirmation) {
            return <ConfirmationSection />;
        }

        return (
            <Wrapper className="CallScheduler">
                {!this.state.callNowSuccess && this.renderHeaderMessage()}

                {this.state.callNowSuccess && !this.props.verboseConfirmation && (
                    <>
                        <p>Vielen Dank! Dein Berater wird dich in ca. 15 Minuten anrufen. ✅</p>
                    </>
                )}

                {!this.state.callNowSuccess && this.isWorkingDayAndTime() && !this.props.isOther && (
                    <ButtonNavWrapper>
                        <Button
                            disabled={this.state.loadingCallNow}
                            onClick={() => {
                                this.setState(
                                    {
                                        loadingCallNow: true,
                                        callNowSuccess: false,
                                        callNowError: false,
                                    },
                                    async () => {
                                        try {
                                            GtmServiceInstance.track({
                                                event: "topOffer.callNow",
                                            });

                                            if (this.props.notifyAboutUser) {
                                                this.props.notifyAboutUser(true, this.state.newPhone);
                                            }

                                            this.setState({
                                                loadingCallNow: false,
                                                callNowSuccess: true,
                                                callNowError: false,
                                            });
                                        } catch (e) {
                                            Sentry.captureException(e);

                                            this.setState({
                                                loadingCallNow: false,
                                                callNowSuccess: false,
                                                callNowError: true,
                                            });
                                        }
                                    },
                                );
                            }}
                            blue
                        >
                            {this.state.loadingCallNow && (
                                <>
                                    Lädt
                                    <Oval
                                        wrapperClass="loader"
                                        color="#aaa"
                                        secondaryColor="#aaa"
                                        height={76}
                                        width={76}
                                    />
                                </>
                            )}

                            {!this.state.loadingCallNow && <>Sofort verfügbar</>}
                        </Button>
                    </ButtonNavWrapper>
                )}

                {this.state.showModal && this.renderPhoneNumberChangeInput()}

                {this.state.callNowError && !this.state.loadingCallNow && (
                    <ErrorLabel
                        error={"Leider konnten wir deine Anfrage nicht bearbeiten, versuche es in einer Minute wieder."}
                    />
                )}

                {!this.state.callNowSuccess && this.isWorkingDayAndTime() && !this.props.isOther && (
                    <CenterBlock>
                        <p>oder</p>
                    </CenterBlock>
                )}

                {!this.state.callNowSuccess && (
                    <>
                        <AppointmentButton
                            onClick={() => {
                                GtmServiceInstance.track({
                                    event: "topOffer.bookAppointment",
                                });

                                const { firstname, lastname, phone } = this.props.user;

                                const queryParams = [
                                    this.props.email && `email=${this.props.email}`,
                                    firstname && `firstname=${firstname}`,
                                    lastname && `lastname=${lastname}`,
                                    phone && `phone=${phone}`,
                                ]
                                    .filter(Boolean)
                                    .join("&");

                                const redirect = `${DEFAULT_FOLLOW_UP_LINK}?${queryParams}`;

                                // redirect to the HS calendar (ignoring react router)
                                window.location.href = redirect;
                            }}
                        >
                            Termin wählen
                        </AppointmentButton>

                        <Spacer />

                        <SubLabel>
                            Wir rufen dich unter {this.state.newPhone || phone} an.{" "}
                            <a onClick={() => this.setState({ showModal: !this.state.showModal })}>
                                <u>Telefonnummer ändern</u>
                            </a>
                        </SubLabel>
                    </>
                )}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 26px;

    p {
        color: ${colors.gray500};
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 24px;
    }

    strong {
        font-weight: 700;
    }
`;

const SubLabel = styled.p`
    font-size: 12px;
    color: ${colors.gray500};
    padding-top: 8px;
`;

const AppointmentButton = styled.button`
    ${ButtonCss};
    background: transparent;
    border: 2px solid;
    color: ${colors.blue600};
    font-weight: 600;
    margin-right: 15px;
    outline: 0;
    padding: 0;
    width: 100%;
`;

const ButtonNavWrapper = styled.div`
    display: flex;
    margin: 26px 0 10px;
`;

export default CallScheduler;
