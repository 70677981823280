import { Component } from "react";
import styled, { css } from "styled-components";
import { DEFAULT_FOLLOW_UP_LINK } from "../../../../../redux/actions/hub.actions";
import { colors } from "../../../../styles/constants/colors";
import { ButtonLink } from "../../../../styles/newStyle";

export type ContactCardProps = {
    isModal?: boolean;
    hasAvatar?: boolean;
    hasCalendarButton?: boolean;
};

export class ContactCard extends Component<ContactCardProps> {
    render() {
        return (
            <ContactBox isModal={this.props.isModal}>
                <TelIcon hasAvatar={this.props.hasAvatar} href="tel:+436602069421">
                    <img src="/assets/telephoneSimple.svg" width={20} height={20} alt="David" />
                </TelIcon>
                {this.props.hasAvatar && <Avatar src="/assets/avatar/david.jpeg" height="96" width="96" />}
                <Name>David</Name>
                <Position>Kostenlose Beratung</Position>
                <Telephone href="tel:+436703085171">+43 670 3085171</Telephone>
                <Hours>Mo. - Fr. 9.00 - 18.00 Uhr</Hours>
                {this.props.hasCalendarButton && (
                    <CalendarButton blue href={DEFAULT_FOLLOW_UP_LINK} target="_blank">
                        Termin vereinbaren
                    </CalendarButton>
                )}
            </ContactBox>
        );
    }
}

const Avatar = styled.img`
    border-radius: 50%;
    margin-bottom: 40px;
`;

const ContactBox = styled.div`
    background: #fff;
    box-shadow: 0px 10px 60px -2px rgba(0, 0, 0, 0.2), 0px 2px 12px -4px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    padding: 24px;
    position: relative;
    text-align: right;

    ${({ isModal }) =>
        isModal &&
        css`
            position: absolute;
            right: 20px;
            top: 62px;
            width: 279px;
            z-index: 20;
        `};
`;

const TelIcon = styled.a`
    align-items: center;
    background: ${colors.blue50};
    border-radius: 50%;
    display: flex;
    height: 56px;
    justify-content: center;
    position: absolute;
    top: ${({ hasAvatar }) => (hasAvatar ? "44px" : "22px")};
    width: 56px;
`;

const Name = styled.div`
    color: ${colors.gray700};
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 6px;
`;

const Position = styled.div`
    color: ${colors.gray500};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const Telephone = styled.a`
    color: ${colors.gray700};
    display: inline-block;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-top: 18px;
`;

const Hours = styled.div`
    color: ${colors.gray500};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const CalendarButton = styled(ButtonLink)`
    margin-top: 20px;
`;

export default ContactCard;
