import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import type { PageStructure } from "../pages/new/types";

export type LoanAmountSummaryProps = {
    pageStructure: PageStructure;
};

// TODO Improvement: Discuss behaviour of input element and calc. update of the elements.
export class LoanAmountSummary extends Component<LoanAmountSummaryProps> {
    render() {
        const { PRICE, AGENT_TAX, AVAILABLE_CAPITAL, TAX, NOTARY, REGISTER_ENTRY } = this.props.pageStructure;

        const kaufpreis = PRICE.attributes.value ? PRICE.attributes.value : 0,
            eigenkapital = AVAILABLE_CAPITAL.attributes.value ? AVAILABLE_CAPITAL.attributes.value : 0,
            maklerprovision = AGENT_TAX.attributes.value ? AGENT_TAX.attributes.value : 0,
            grunderwerbsteuer = TAX.attributes.value ? TAX.attributes.value : 0,
            notary = NOTARY.attributes.value ? NOTARY.attributes.value : 0,
            registerEntry = REGISTER_ENTRY.attributes.value ? REGISTER_ENTRY.attributes.value : 0;

        const percentage = Number(kaufpreis) / 100;

        const finanzierungsBedarf =
            Number(kaufpreis) -
            Number(eigenkapital) +
            parseFloat(String(maklerprovision)) * percentage +
            parseFloat(String(grunderwerbsteuer)) * percentage +
            parseFloat(String(notary)) * percentage +
            parseFloat(String(registerEntry)) * percentage;

        return (
            <Wrapper>
                <Label>Kaufpreis:</Label>
                <Value>
                    <span>{kaufpreis ? kaufpreis.toLocaleString("DE") : 0}</span> €
                </Value>

                <Label>Eigenmittel:</Label>
                <Value>
                    <span>{eigenkapital ? eigenkapital.toLocaleString("DE") : 0}</span> €
                </Value>

                <Label isImportant>Dein Finanzierungsbedarf:</Label>
                <Value isImportant>
                    <span>{finanzierungsBedarf ? Math.floor(finanzierungsBedarf).toLocaleString("DE") : 0}</span> €
                </Value>

                <p>Du kannst den Kreditbetrag mit deinem Finanzexperten später noch genauer besprechen.</p>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    background: #fff;
    box-shadow: 0px 10px 60px -2px rgba(0, 0, 0, 0.2), 0px 2px 12px -4px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 300;
    padding: 24px 30px 24px;

    p {
        color: ${colors.gray800};
        margin: 0;
    }
`;

const Label = styled.div`
    color: ${({ isImportant }) => (isImportant ? colors.gray700 : colors.gray500)};
    font-weight: ${({ isImportant }) => (isImportant ? 400 : 300)};
`;

const Value = styled.div`
    color: ${({ isImportant }) => (isImportant ? colors.blue600 : colors.gray400)};
    font-size: 24px;
    margin-bottom: 20px;

    span {
        color: ${({ isImportant }) => (isImportant ? colors.blue600 : colors.gray700)};
        font-size: ${({ isImportant }) => (isImportant ? "32px" : "24px")};
        font-weight: ${({ isImportant }) => (isImportant ? 800 : 400)};
    }
`;

export default LoanAmountSummary;
