import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Component } from "react";
import { ThreeDots } from "react-loader-spinner";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    getGuessedCreditAffordability,
    setIncomeAffordability,
    setMonthlyExpenseAffordability,
    setOwnFundingAffordability,
} from "../../../../../redux/actions/engine.actions";
import { RootState, ThunkBaseDispatch } from "../../../../../redux/store";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { SurveyWrapper } from "../../../../styles/base";
import { colors } from "../../../../styles/constants/colors";
import BankLogos from "../creditConditions/bankLogos.component";
import ErrorMessage from "../creditConditions/errorMessage.component";
import GetInContact from "../creditConditions/getInContact.component";
import NumberItem from "../creditConditions/menu/numberItem.component";
import NoResults from "../creditConditions/noResults.component";

export type AffordabilityCalculatorPageProps = {
    //
};

export class AffordabilityCalculatorPage extends Component<
    AffordabilityCalculatorPageProps & AffordabilityCalculatorPageStateProps & AffordabilityCalculatorPageDispatchProps
> {
    isInitialRequestSent: boolean;

    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchAffordability(true);
    }

    fetchAffordability = (initialFetch?: boolean, _tag = "") => {
        this.props.guessAffordability({
            salary: this.props.income,
            capital: this.props.ownFunding,
            monthlyExpense: this.props.monthlyExpense,
        });

        if (initialFetch) {
            this.isInitialRequestSent = true;
        } else {
            GtmServiceInstance.track({
                event: "affordability.modified",
                tag: _tag || "unknown",
            });
        }
    };

    onOwnFundingChange = (value) => {
        this.props.setOwnFunding(parseInt(value));

        setTimeout(() => this.fetchAffordability(false, "ownFunding"), 100);
    };

    onIncomeChange = (value) => {
        const newIncomeValue = parseInt(value);

        this.props.setIncome(newIncomeValue);

        if (newIncomeValue < this.props.monthlyExpense) {
            this.onMonthlyExpenseChange(newIncomeValue);
        } else {
            setTimeout(() => this.fetchAffordability(false, "salary"), 100);
        }
    };

    onMonthlyExpenseChange = (value) => {
        this.props.setMonthlyExpense(parseInt(value));

        setTimeout(() => this.fetchAffordability(false, "maxMonthlyRate"), 100);
    };

    renderError() {
        GtmServiceInstance.track({
            event: "affordability.error",
        });

        return <ErrorMessage onRepeatClick={() => this.fetchAffordability()} />;
    }

    renderNoResults() {
        GtmServiceInstance.track({
            event: "affordability.noResults",
        });

        return (
            <NoResults
                onNextClick={() => {
                    // TODO: go to booking appointment
                }}
            />
        );
    }

    render() {
        return (
            <SurveyWrapper>
                <Row>
                    <NumberItem
                        type={"number"}
                        label={"Eigenkapital"}
                        onChange={this.onOwnFundingChange}
                        value={this.props.ownFunding}
                        width={48}
                        minValue={1}
                    />
                    <NumberItem
                        type={"number"}
                        label={"Nettoeinkommen / Monat"}
                        onChange={this.onIncomeChange}
                        value={this.props.income}
                        width={48}
                        minValue={500}
                    />
                </Row>
                <SliderLabel>
                    Max. Monatsrate: <strong>{this.props.monthlyExpense.toLocaleString("DE")} €</strong>
                </SliderLabel>
                <SliderTrackWrapper>
                    <Slider
                        min={300}
                        max={this.props.income}
                        defaultValue={this.props.monthlyExpense}
                        onAfterChange={this.onMonthlyExpenseChange}
                        handleStyle={{
                            height: 20,
                            width: 20,
                            marginLeft: 0,
                            marginTop: -7,
                            backgroundColor: colors.primary,
                            opacity: 1,
                        }}
                        railStyle={{
                            backgroundColor: "#ddd",
                            height: 6,
                            borderRadius: 0,
                        }}
                        trackStyle={{
                            backgroundColor: colors.primary,
                            height: 6,
                            borderRadius: 0,
                            opacity: 0.8,
                        }}
                    />
                </SliderTrackWrapper>
                <SliderRange>
                    300 €<span>{this.props.income.toLocaleString("DE")} €</span>
                </SliderRange>
                <BankLogos />

                <Box>
                    <BoxHeader>
                        Mögliche Finanzierung&nbsp;<strong>*</strong>
                    </BoxHeader>
                    <Result>
                        <img
                            src="/assets/svg/Icons_2farben/Miracl_icons_immobilien_finanzierung.svg"
                            alt=""
                            height="50"
                        />
                        {this.props.guessedCreditAmount &&
                            `${Number(this.props.guessedCreditAmount).toLocaleString("DE")} €`}
                        {this.props.isLoading && (
                            <ThreeDots wrapperClass="ThreeDots" color="#999" height={20} width={20} />
                        )}
                    </Result>
                </Box>
                <GetInContact
                    meetingData={this.props.meetingData}
                    titleWithAppointment={"Lass dich kostenlos beraten!"}
                    titleWithoutAppointment={"Lass dich kostenlos beraten!"}
                    mainText={
                        "Unsere Finanzexperten helfen dir deine Kreditwürdigkeit besser zu verstehen und bringen dich zu deiner Traum-Immobilienfinanzierung."
                    }
                />
                <p>
                    <small>(*) Alle Angaben ohne Gewähr</small>
                </p>
            </SurveyWrapper>
        );
    }
}

const SecondaryColor = "#96dbfa";

const Result = styled.div`
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 500;

    svg {
        margin-top: 4px;
        margin-left: 7px;
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const Box = styled.div`
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 25px auto 0;
    padding: 5px 10px 10px;
    position: relative;
`;

const BoxHeader = styled.div`
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    display: flex;
    margin: 0 0 7px;
    padding: 6px 0;
`;

const SliderLabel = styled.div`
    color: #000;
    font-size: 13px;
    margin: 0 0 2px 2px;

    strong  {
        font-size: 14px;
    }
`;

const SliderRange = styled.div`
    color: #000;
    display: flex;
    font-size: 11px;

    span {
        margin-left: auto;
    }
`;

const SliderTrackWrapper = styled.div`
    padding: 0 10px 3px;

    .rc-slider-handle {
        transition: box-shadow ease-in-out 0.14s;
    }
    .rc-slider-handle,
    .rc-slider-handle:focus-visible {
        box-shadow: 0 0 0 0 ${SecondaryColor};
    }
    .rc-slider-handle-dragging {
        box-shadow: 0 0 0 5px ${SecondaryColor} !important;
    }
`;

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.engine.affordability.isLoading,
        isError: state.engine.affordability.isError,
        errorMessage: state.engine.affordability.errorMessage,
        income: state.engine.affordability.income,
        monthlyExpense: state.engine.affordability.monthlyExpense,
        guessedCreditAmount: state.engine.affordability.guessedCreditAmount,
        ownFunding: state.engine.affordability.ownFunding,
        meetingData: state.hub.meeting,
    };
};

type AffordabilityCalculatorPageStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        guessAffordability: (_financingObject) => dispatch(getGuessedCreditAffordability(_financingObject)),
        setOwnFunding: (_ownFunding) => dispatch(setOwnFundingAffordability(_ownFunding)),
        setIncome: (_income) => dispatch(setIncomeAffordability(_income)),
        setMonthlyExpense: (_expense) => dispatch(setMonthlyExpenseAffordability(_expense)),
    };
};

type AffordabilityCalculatorPageDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AffordabilityCalculatorPage);
