import { Component } from "react";
import ErrorLabel from "./ErrorLabel";

export type ErrorStackedLabelsProps = {
    errors: React.ReactNode[];
};

export class ErrorStackedLabels extends Component<ErrorStackedLabelsProps> {
    render() {
        if (this.props.errors && this.props.errors.length) {
            return this.props.errors.map((error, idx) => <ErrorLabel error={error} key={idx} />);
        }

        return null;
    }
}

export default ErrorStackedLabels;
