import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import InputTypes from "../../../new/common/ComponentTypes";
import { isValidPhone } from "../../../new/common/PageComponentConditionals";
import CommonProperties from "../../../new/common/Properties";
import type { PageStructure } from "../types";

export const pageStructure: PageStructure = {
    PHONE: {
        type: InputTypes.phoneInput,
        label: "Telefonnummer",
        property: CommonProperties.PHONE,
        attributes: {
            value: null,
            placeholder: "z. B. 00436781221017",
            required: true,
            autocomplete: "tel",
            allowLeadingZeros: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib deine Telefonnummer an.",
            invalid: "Überprüfe ob deine Telefonnummer korrekt ist.",
        },
        helperText: null,
        tooltip:
            "Die telefonische Beratung erlaubt es uns deinen Finanzierungswunsch im Detail zu verstehen und somit einen genauen Kreditvergleich für dich erstellen zu können.",
        gamification: null,
        visibility: null,
        conditional: [
            {
                expression: isValidPhone,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.personalData,
                        component: "PHONE",
                    },
                ],
            },
        ],
        disabledConditional: null,
    },
};
