import { Component } from "react";
import styled, { css } from "styled-components";
import { breakpointsNew } from "../../../../styles/constants/breakpoints";
import { colors } from "../../../../styles/constants/colors";
import { Answer } from "./common/Answers";

export type DayPickerProps = {
    value?: string;
    label: React.ReactNode;
    onChange: (value: { value: string | null }) => void;
};

export class DayPicker extends Component<DayPickerProps> {
    state = {
        day: this.props.value,
    };

    DAYS = [];
    DAYS_LENGTH = 4;
    NOW_BTN = Answer.DAY_PICKER.NOW;

    handleDayClick = (event) => {
        const newValue = event.target.value;

        this.setState(() => ({
            day: newValue,
        }));

        this.props.onChange({
            value: newValue,
        });
    };

    getNextWorkingDay(addDays) {
        const today = new Date();
        const pickedDate = new Date();

        pickedDate.setDate(today.getDate() + addDays);
        const dayNumber = pickedDate.getDay();

        if (dayNumber === 0) return;
        else if (dayNumber === 6) return;

        return `${pickedDate.getUTCDate()}.${pickedDate.getUTCMonth() + 1}.`;
    }

    fillDaysArray = () => {
        this.DAYS.push(this.NOW_BTN);
        for (let day = 0; this.DAYS.length <= this.DAYS_LENGTH; day++) {
            const nextWorkingDay = this.getNextWorkingDay(day);

            if (nextWorkingDay) {
                this.DAYS.push(nextWorkingDay);
            }
        }
    };

    render() {
        if (!this.DAYS.length) {
            this.fillDaysArray();
        }

        return (
            <Wrapper>
                <Label>{this.props.label}</Label>
                <DaysList>
                    {this.DAYS.map((item, index) => {
                        return (
                            <DayItem
                                onClick={this.handleDayClick}
                                value={item}
                                type="button"
                                isActive={item === this.state.day}
                                key={index}
                            >
                                {item}
                            </DayItem>
                        );
                    })}
                </DaysList>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 18px 0 6px;
`;

const Label = styled.div`
    color: ${colors.gray600};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
`;

const DaysList = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DayItem = styled.button`
    background-color: #fff;
    border: 2px solid ${colors.gray200};
    border-radius: 24px;
    color: ${colors.gray500};
    cursor: pointer;
    font-weight: 600;
    font-size: 11px;
    line-height: 24px;
    padding: 6px 14px;
    text-transform: uppercase;
    transition: background-color ease-out 0.16s;
    white-space: nowrap;

    @media (max-width: 374px) {
        box-sizing: border-box;
        margin-right: 2px;
        padding-left: 8px;
        padding-right: 8px;
        width: 100%;

        &:last-child {
            margin-right: 0;
        }
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${colors.blue500};
            border-color: ${colors.blue500};
            color: #fff;
        `};

    @media (min-width: ${breakpointsNew.largeMin}) {
        padding-left: 21px;
        padding-right: 21px;
    }
`;

export default DayPicker;
