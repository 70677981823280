export const SUB_CATEGORY = {
    kreditnehmer1: "Kreditnehmer 1",
    kreditnehmer2: "Kreditnehmer 2",
    object1: "Finanzierungsobjekt",
    object2: "Bestandsobjekt",
    undefined: "Sonstiges",
};

export const DOCUMENT_LIST = {
    ausweis: {
        title: "Ausweis",
        description:
            "Bitte lade hier deinen Reisepass oder Personalausweis hoch. Bei Nationalitäten außerhalb des Euroraums, bitte auch um Übermittlung des Aufenthaltstitels.",
    },
    meldezettel: {
        title: "Meldezettel",
        description: "Bitte lade hier deinen Meldezettel hoch.",
    },
    "einkommen-angestellt": {
        title: "Einkommensnachweis für Angestellte",
        description: `Bitte lade hier die letzten 6 Lohnzettel hoch. Sollte auf deinem Lohnzettel das Eintrittsdatum nicht ersichtlich sein, dann lade bitte zusätzlich deinen Dienstvertrag hoch. Solltest du deinen Job gewechselt haben, dann schicke uns bitte einen aktuellen Gebietskassenauszug. Bei stark schwankenden Monatseinkommen ist es sinnvoll, deinen Jahreslohnzettel hochzuladen. Falls zutreffend lade auch deinen Karenzbescheid & Wiedereinstellungszusage hoch.`,
    },
    "einkommen-selbständig": {
        title: "Einkommensnachweis für Selbständige",
        description: `Du bist selbständig? Dann benötigen wir folgende Unterlagen von dir: Firmenbuchauszug, Wirtschaftliche Unterlagen der letzten 3 Jahre (Bilanzen, Steuererklärungen, Steuerbescheide, Saldenlisten), Auszug Sozialversicherungskonto und Auszug Steuerkonto. Je aktueller deine Unterlagen sind desto besser!`,
    },
    kontoauszüge: {
        title: "Kontoauszüge",
        description: `Bitte lade hier die Kontoauszüge/Kontoumsätze des/der Gehaltskontos der letzten 3 Monate hoch. Du kannst diese Umsätze aus der Desktopversion deines E-Bankings (Smartphone nicht möglich) als PDF Dokument herunterladen.`,
    },
    expose: {
        title: "Exposé",
        description: `Exposé deines/eures Objekts, falls du/ihr keines habt, Fotos und Pläne.`,
    },
    "bestehende-kredit": {
        title: "Bestehende Kredite",
        description: `Du hast bestehende Kredite? Dann bitte den aktuellen Saldo und die Kreditverträge hochladen. Bitte achte darauf, dass immer das aktuelle Datum, der/die Kontoinhaber:in und der IBAN ersichtlich sind. (keine Screenshots)`,
    },
    grundbuchauszug: {
        title: "Grundbuchauszug",
        description: `Falls bereits vorhanden.`,
    },
    kaufvertrag: {
        title: "Kaufvertrag",
        description: `Falls bereits vorhanden, ansonsten gerne auch ein unterschriebenes Kaufanbot.`,
    },
    eigenmittelnachweis: {
        title: "Eigenmittelnachweis",
        description: `Kontoauszüge, Sparbücher, Wertpapierdepot, bestehendes Immobilienvermögen. Bitte achte darauf, dass immer das aktuelle Datum, der/die Kontoinhaber:in und der IBAN ersichtlich sind. (keine Screenshots)`,
    },
    sonstiges: {
        title: "Sonstiges",
        description: `Falls du noch andere Dokumente hochladen oder nachreichen möchtest, kannst du dies hier machen.`,
    },
};
