import { Component, MouseEventHandler } from "react";
import styled from "styled-components";
import { Button, H3 } from "../../../../styles/base";

export type NoResultsProps = {
    onNextClick: MouseEventHandler<HTMLButtonElement>;
};

export class NoResults extends Component<NoResultsProps> {
    render() {
        return (
            <Wrapper>
                <H3 center>Keine Ergebnisse</H3>
                <p>
                    Lass dich jetzt unverbindlich von unseren Finanzierungsspezialist:innen beraten um zu erfahren wie
                    du doch noch ein Angebot bekommen könntest.
                </p>

                <Button onClick={this.props.onNextClick}>Zur Beratung</Button>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 30px 0;
    text-align: center;
`;
export default NoResults;
