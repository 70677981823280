import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export type InfoLabelProps = {
    text: React.ReactNode;
};

export class InfoLabel extends Component<InfoLabelProps> {
    render() {
        return <HelperText>{this.props.text || ""}</HelperText>;
    }
}

const HelperText = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    margin: 3px 0;
    color: ${colors.gray400};

    &:before {
        background: ${colors.lime500};
        border-radius: 50%;
        color: #fff;
        content: "i";
        display: inline-block;
        font-family: "Inter";
        font-size: 11px;
        font-weight: 500;
        height: 14px;
        line-height: 1.4;
        margin-right: 5px;
        text-align: center;
        width: 14px;
    }
`;

export default InfoLabel;
