import { Component } from "react";
import { ThreeDots } from "react-loader-spinner";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import { DEFAULT_MEETINGS_LINK } from "../../../../../redux/actions/hub.actions";
import { Callback } from "../../../../../redux/types";
import { openObjectUrl, openUrlInNewTab } from "../../../../../service/common/helper";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { bpSurvey } from "../../../../styles/constants/breakpoints";
import { colors } from "../../../../styles/constants/colors";

export type FinancingStepProps = {
    isActive?: boolean;
    title: React.ReactNode;
    titleDate: React.ReactNode;
    index: number;
    isDone?: boolean;
    isOpenable?: boolean;
    icon: React.ReactNode;
    headButtonText?: React.ReactNode;
    headButtonLink?: string;
    calendarLink?: string;
    videoLink?: string;
    creditOfferId: string;
    getCreditOfferDocument: (creditOfferId: string, callback?: Callback<any>) => void;
    isLoadingDocument?: boolean;
};

type FinancingStepState = {
    isOpen: boolean;
};

export class FinancingStep extends Component<FinancingStepProps, FinancingStepState> {
    state = {
        isOpen: !!this.props.isActive,
    };

    render() {
        const {
            title,
            titleDate,
            index,
            isDone,
            isOpenable,
            icon,
            headButtonText,
            headButtonLink,
            calendarLink,
            videoLink,
            isActive,
            creditOfferId,
            getCreditOfferDocument,
            isLoadingDocument,
        } = this.props;

        const handleToggle = () => {
            this.setState((prevState) => ({
                isOpen: !prevState.isOpen,
            }));

            GtmServiceInstance.track({
                event: "financingTimeline.openStep",
                stepTitle: title,
            });
        };

        return (
            <Wrapper isDone={isDone} index={index}>
                <Bullet isDone={isDone}>
                    {isDone ? <img src="/assets/svg/doubleCheck.svg" height="8" alt="" /> : index}
                </Bullet>
                <Box isOpen={this.state.isOpen}>
                    <Head>
                        <IconWrapper>
                            <Icon src={icon} />
                        </IconWrapper>
                        <Title>
                            {title}
                            {calendarLink && (
                                <a
                                    // TODO: TS - should replace with button if no href
                                    onClick={(e) => {
                                        e.preventDefault();

                                        openUrlInNewTab(calendarLink);
                                    }}
                                >
                                    {titleDate}
                                </a>
                            )}
                            {videoLink && (
                                <a
                                    // TODO: TS - should replace with button if no href
                                    onClick={(e) => {
                                        e.preventDefault();

                                        openUrlInNewTab(calendarLink);
                                    }}
                                >
                                    {"Zum Video-Telefonat"}
                                </a>
                            )}
                        </Title>
                        {headButtonText && !creditOfferId && (
                            <HeadButton
                                href={headButtonLink}
                                target="_blank"
                                onClick={() => {
                                    if (headButtonLink === DEFAULT_MEETINGS_LINK) {
                                        GtmServiceInstance.track({
                                            event: "surveyFinishedAppointmentStart",
                                        });
                                    }
                                }}
                            >
                                {headButtonText}
                            </HeadButton>
                        )}
                        {isOpenable && !isActive && (
                            <HeadButton isOpenable isOpen={this.state.isOpen} onClick={handleToggle}>
                                Mehr
                            </HeadButton>
                        )}
                        {headButtonText && creditOfferId && (
                            <HeadButton
                                disabled={isLoadingDocument}
                                onClick={() => {
                                    if (!isLoadingDocument) {
                                        const newWindow = window.open();
                                        getCreditOfferDocument(creditOfferId, (document) => {
                                            openObjectUrl(document, newWindow);
                                        });
                                    }
                                }}
                            >
                                {headButtonText}
                                {isLoadingDocument && (
                                    <ThreeDots wrapperClass="loader" color="#777" height={12} width={12} />
                                )}
                            </HeadButton>
                        )}
                    </Head>
                    <CSSTransition<undefined>
                        in={this.state.isOpen}
                        timeout={300}
                        unmountOnExit
                        onEnter={(element) => {
                            element.style.maxHeight = `${element.scrollHeight}px`;
                        }}
                    >
                        <ExtendedContent isActive={isActive}>{this.props.children}</ExtendedContent>
                    </CSSTransition>
                </Box>
            </Wrapper>
        );
    }
}

const mainBgColor = "#F3F4F5";

const Wrapper = styled.div`
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: ${({ isDone }) => (isDone ? colors.primary : "#D1D5DB")};
    margin: -14px 0 0 12px;
    padding: 0 0 30px 0;
    position: relative;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        border-left: none;
        margin-left: 15px;
    }

    @media (min-width: ${bpSurvey.largeMin}) {
        margin-top: -20px;
        padding-bottom: 40px;
    }
`;

const Bullet = styled.div`
    align-items: center;
    background: ${mainBgColor};
    border: 3px solid #d1d5db;
    border-radius: 50%;
    color: #262626;
    display: flex;
    height: 21px;
    font-size: 12px;
    left: -16px;
    justify-content: center;
    outline: 4px solid ${mainBgColor};
    padding: 1px 0 0 0px;
    position: absolute;
    top: 18px;
    width: 22px;

    ${({ isDone }) =>
        isDone &&
        css`
            background: ${colors.primary};
            border-color: ${colors.primary};

            &:before {
                background: ${mainBgColor};
                height: 25px;
                position: absolute;
                top: -30px;
                width: 12px;
                content: "";
            }
        `};

    @media (min-width: ${bpSurvey.largeMin}) {
        top: 25px;
    }
`;

const Box = styled.div`
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 1px 6px 30px 8px rgba(17, 24, 39, 0);
    margin: 0 0 0 7%;
    padding: 15px 4%;
    position: relative;
    transition: all 0.16s ease-in-out;

    ${({ isOpen }) =>
        isOpen &&
        css`
            background-color: #fff;
            box-shadow: 1px 6px 30px 8px rgba(17, 24, 39, 0.08);
        `};

    @media (min-width: ${bpSurvey.largeMin}) {
        border-radius: 10px;
        margin-left: 9%;
    }
`;

const Head = styled.div`
    display: flex;
`;

const Title = styled.div`
    align-items: center;
    color: #6b7280;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 18px;
    width: 100%;

    span {
        color: ${colors.primary};
        display: block;
        font-size: 13px;
        font-weight: 500;
        margin-top: 3px;
    }

    @media (min-width: ${bpSurvey.largeMin}) {
        font-size: 16px;
        line-height: 22px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    height: 36px;
    margin-right: 2%;
    width: 36px;

    img {
        display: block;
        height: 36px;
        width: 36px;
    }

    @media (min-width: ${bpSurvey.largeMin}) {
        height: 50px;
        margin-right: 4%;
        width: 50px;

        img {
            height: 50px;
            width: 50px;
        }
    }
`;

const Icon = styled.img`
    display: block;
`;

const HeadButton = styled.a`
    align-items: center;
    border: 2px solid ${colors.primary};
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;
    white-space: nowrap;

    .loader {
        display: inline-flex;
        margin: 0 -3px 0 -1px;
        transform: rotate(90deg);
    }

    @media (min-width: ${bpSurvey.largeMin}) {
        margin-top: 12px;
    }

    ${({ isOpenable }) =>
        isOpenable &&
        css`
            &:after {
                border-color: ${colors.primary};
                border-top-width: 1px;
                border-right-width: 1px;
                border-top-style: solid;
                border-right-style: solid;
                content: "";
                height: 5px;
                margin-left: 5px;
                margin-right: 1px;
                width: 5px;

                margin-top: ${({ isOpen }) => (isOpen ? "3px" : "-3px")};
                transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(135deg)")};
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            border-color: #bbb;
            color: #aaa;
        `};
`;

const ExtendedContent = styled.div`
    position: relative;
    max-height: ${({ isActive }) => (isActive ? "auto" : 0)};
    overflow: hidden;
    transition: max-height ease-in-out 300ms;

    &.exit-active {
        max-height: 0 !important;
    }
    &.enter-done {
        max-height: none !important;
    }
`;

export default FinancingStep;
