import { Component, MouseEventHandler } from "react";
import styled from "styled-components";
import { Button, CenterBlock, H3 } from "../../../../styles/base";

export type ErrorMessageProps = {
    onRepeatClick: MouseEventHandler<HTMLButtonElement>;
    onNextClick?: MouseEventHandler<HTMLAnchorElement>;
};

export class ErrorMessage extends Component<ErrorMessageProps> {
    render() {
        return (
            <Wrapper>
                <H3 center>Ups, leider ist ein Fehler passiert.</H3>
                <CenterBlock>
                    <p>
                        <Button onClick={this.props.onRepeatClick}>Noch einmal versuchen</Button>
                    </p>
                    {this.props.onNextClick && (
                        <a onClick={this.props.onNextClick} href="/#">
                            Weiter
                        </a>
                    )}
                </CenterBlock>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 30px 0;
`;

export default ErrorMessage;
