import { Location } from "history";
import qs from "qs";

/**
 * Checks if the query params for the current location object
 * contain the action param with the value complete
 * @param location - props location object for the survey
 */
export const isAppointmentBookingComplete = (location: Location<unknown> | undefined) => {
    if (!location) return;

    const queryArgs = qs.parse(location.search, { ignoreQueryPrefix: true });

    const { action } = queryArgs;

    return action && action === "complete";
};
