import * as Sentry from "@sentry/react";
import { getMagicLinkRequest, validateMagicLinkRequest, validateToken } from "../../service/api";
import { ThunkBaseAction, ThunkBaseDispatch } from "../store";
import { Callback } from "../types";
import { getUserData, setMagicLinkIsError, setMagicLinkIsLoading, setUser } from "./hub.actions";

export const SET_SESSION_TOKEN = "SET_SESSION_TOKEN";

export const SET_SESSION_TOKEN_IS_LOADING = "SET_SESSION_TOKEN_IS_LOADING";

export const SET_SESSION_TOKEN_ERROR = "SET_SESSION_TOKEN_ERROR";

export const TOKEN_STORAGE_KEY = "__miracl_token";

export const invalidateSessionToken = (callback?: (err?: unknown) => void) => (dispatch: ThunkBaseDispatch) => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);

    dispatch(setUser(null, null, null, null, null));

    dispatch(setToken(null));

    if (callback) {
        callback();
    }
};

export const setSessionToken = (_token) => (dispatch) => {
    dispatch(setToken(_token));

    localStorage.setItem(TOKEN_STORAGE_KEY, _token);
};

function setToken(_token) {
    return {
        type: SET_SESSION_TOKEN,
        token: _token,
    } as const;
}
export type SetToken = ReturnType<typeof setToken>;

function setSessionTokenIsLoading(_isLoading) {
    return {
        type: SET_SESSION_TOKEN_IS_LOADING,
        isLoading: _isLoading,
    } as const;
}
export type SetSessionTokenIsLoading = ReturnType<typeof setSessionTokenIsLoading>;

function setSessionTokenError(_isError, _errorMessage) {
    return {
        type: SET_SESSION_TOKEN_ERROR,
        isError: _isError,
        errorMessage: _errorMessage,
    } as const;
}
export type SetSessionTokenError = ReturnType<typeof setSessionTokenError>;

export type SessionActions = SetToken | SetSessionTokenIsLoading | SetSessionTokenError;

/**
 * Use this action to init. the app and calling all needed requests
 * if an user has an active session..
 */
export const initApp =
    (callback?: Callback): ThunkBaseAction =>
    (dispatch) => {
        dispatch(setSessionTokenIsLoading(true));

        const localToken = localStorage.getItem(TOKEN_STORAGE_KEY);

        if (localToken) {
            validateToken({ token: localToken })
                .then(() => {
                    dispatch(setSessionToken(localToken));

                    dispatch(getUserData(callback));

                    dispatch(setSessionTokenIsLoading(false));
                })
                .catch((error) => {
                    Sentry.captureException(`Failed to validate token. ${error.message}`);

                    dispatch(invalidateSessionToken());

                    dispatch(setSessionTokenIsLoading(false));
                });
        } else {
            // Sanity check to invalidate the session redux token
            dispatch(invalidateSessionToken());

            dispatch(setSessionTokenIsLoading(false));
        }
    };

export const validateMagicToken =
    (token: string, callback?: Callback): ThunkBaseAction =>
    (dispatch) => {
        dispatch(setSessionTokenIsLoading(true));

        dispatch(invalidateSessionToken());

        validateMagicLinkRequest({
            token,
        })
            .then((response) => {
                const { token } = response.data;

                dispatch(setSessionToken(token));

                dispatch(setSessionTokenIsLoading(false));

                dispatch(getUserData(callback));
            })
            .catch((error) => {
                Sentry.captureException(`Exception raised during validating magic token. ${error.message}`);

                dispatch(invalidateSessionToken());

                dispatch(setSessionTokenError(true, error.message));

                dispatch(setSessionTokenIsLoading(false));

                if (callback) {
                    callback(error);
                }
            });
    };

// TODO: TS - properly define types
export const getMagicLink =
    (data: any, callback?: Callback): ThunkBaseAction =>
    (dispatch) => {
        dispatch(setMagicLinkIsLoading(true));

        dispatch(invalidateSessionToken());

        getMagicLinkRequest(data)
            .then((_response) => {
                dispatch(setMagicLinkIsError(false, null));

                dispatch(setMagicLinkIsLoading(false));

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                Sentry.captureException(`Exception raised during magic link request. ${error.message}`);

                dispatch(invalidateSessionToken());

                dispatch(setMagicLinkIsError(true, error.message));

                dispatch(setMagicLinkIsLoading(false));

                if (callback) {
                    callback(error);
                }
            });
    };
