import InputTypes from "../../../new/common/ComponentTypes";

import CommonProperties from "../../../new/common/Properties";

import { PageStructure } from "../types";

import { Answer } from "../../../new/common/Answers";

export const pageStructure: PageStructure = {
    OFFER_BEST_PRICE_GUARANTEE: {
        type: InputTypes.offer,
        label: null,
        property: null,
        attributes: {
            value:
                "Der aktuelle Top-Zinssatz liegt bei" +
                "\n\n" +
                "✅ <b>3,55 % p.a. für 30 Jahre fix und</b>" +
                "\n" +
                "✅ <b>3,1 % p.a. bei variabler Verzinsung.</b>" +
                "\n\n" +
                "Die Konditionen sind von deiner finanziellen Situation abhängig.",
            placeholder: null,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle dein Vorhaben.",
            invalid: null,
        },
        tooltip: null,
        tooltipAlignBottom: true,
        gamification: null,
        visibility: null,
        conditional: null,
    },
    TITLE: {
        type: InputTypes.title,
        label: null,
        property: null,
        attributes: {
            value: "Wie willst du mehr zu diesem Angebot erfahren?",
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: null,
        conditional: null,
    },
    CONTACT_BY: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Wie willst du mehr zu diesem Angebot erfahren?",
        property: CommonProperties.CONTACT_BY,
        attributes: {
            value: null,
            options: [
                Answer.CONTACT_BY.PHONE_CALL,
                Answer.CONTACT_BY.EMAIL,
                Answer.CONTACT_BY.SMS,
                Answer.CONTACT_BY.LOOKING_AROUND,
            ],
            placeholder: null,
            required: true,
            hideLabel: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle wie du mit uns in Kontakt treten möchtest.",
            invalid: null,
        },
        tooltip:
            "Vorteile einer telefonischen Beratung:\n" +
            "• Schnellste Antwortrate\n" +
            "• Kostenlose Beratung von Spezialisten\n" +
            "• Passende Uhrzeit angeben",
        gamification: null,
        visibility: null,
        conditional: null,
    },
};
