import validator from "validator";

import { Answer } from "./Answers";

export const hasAnswer = (value) => {
    return value != null && value;
};

export const hasAnswerAndIsNotUnknown = (value) => {
    return hasAnswer(value) && value !== Answer.Conditional.UNKNOWN;
};

export const expectYes = (value) => {
    return hasAnswer(value) && validator.equals(value, Answer.Conditional.YES);
};

export const expectYesOrInvalidAnswer = (value) => {
    return value == null || !value || validator.equals(value, Answer.Conditional.YES);
};

export const expectNo = (value) => {
    return hasAnswer(value) && validator.equals(value, Answer.Conditional.NO);
};

export const expectOtherThanYes = (value) => {
    return hasAnswer(value) && !validator.equals(value, Answer.Conditional.YES);
};

export const isEquals = (value, to) => {
    return hasAnswer(value) && validator.equals(value, to);
};

export const isNotRefinancing = (value) => {
    return !isEquals(value, Answer.INTENTION.RE_FINANCING);
};

export const isRefinancing = (value) => {
    return isEquals(value, Answer.INTENTION.RE_FINANCING);
};

export const isNotOther = (value) => {
    return !isEquals(value, Answer.INTENTION.OTHER);
};

export const isBuyingProperty = (value) => {
    return isEquals(value, Answer.INTENTION.BUYING_PROPERTY);
};

export const isNotBuyingProperty = (value) => {
    return !isEquals(value, Answer.INTENTION.BUYING_PROPERTY);
};

export const isLookingForProperty = (value) => {
    return isEquals(value, Answer.PROGRESS.LOOKING_FOR_PROPERTY);
};

export const isActiveVisitting = (value) => {
    return isEquals(value, Answer.PROGRESS.ACTIVE_VISITTING);
};

export const isAboutToSendOffer = (value) => {
    return isEquals(value, Answer.PROGRESS.ABOUT_TO_SEND_OFFER);
};

export const isAboutToSendOfferOrOfferSent = (value) => {
    return isEquals(value, Answer.PROGRESS.ABOUT_TO_SEND_OFFER) || isEquals(value, Answer.PROGRESS.OFFER_SENT);
};

export const isOfferSent = (value) => {
    return isEquals(value, Answer.PROGRESS.OFFER_SENT);
};

export const isResearch = (value) => {
    return isEquals(value, Answer.PROGRESS.RESEARCH);
};

export const isOwnConstruction = (value) => {
    return isEquals(value, Answer.INTENTION.OWN_CONSTRUCTION);
};

export const isFoundProperty = (value) => {
    return isEquals(value, Answer.PROGRESS.ABOUT_TO_SEND_OFFER) || isEquals(value, Answer.PROGRESS.OFFER_SENT);
};

export const isStillNotFoundProperty = (value) => {
    return isEquals(value, "" /* Answer.PROGRESS.STILL_NOT_FOUND */); // TODO: does not exist
};

export const isNotGenossenschaftContributionOrOther = (value) => {
    return !(isEquals(value, Answer.INTENTION.GENOSSENSCHAFT_CONTRIBUTION) || isEquals(value, Answer.INTENTION.OTHER));
};

export const isNotOwnConstructionOrOther = (value) => {
    return !isEquals(value, Answer.INTENTION.OWN_CONSTRUCTION);
};

export const contactOnlyViaPhoneCallOrSMS = (value) => {
    return !(hasAnswer(value) && isEquals(value, Answer.CONTACT_BY.EMAIL));
};

export const contactIsNotLookingAround = (value) => {
    return hasAnswer(value) && !isEquals(value, Answer.CONTACT_BY.LOOKING_AROUND);
};

export const contactViaPhoneCall = (value) => {
    return hasAnswer(value) && isEquals(value, Answer.CONTACT_BY.PHONE_CALL);
};

export const contactViaSMS = (value) => {
    return hasAnswer(value) && isEquals(value, Answer.CONTACT_BY.SMS);
};

export const contactViaEmail = (value) => {
    return hasAnswer(value) && isEquals(value, Answer.CONTACT_BY.EMAIL);
};

export const noContact = (value) => {
    return hasAnswer(value) && isEquals(value, Answer.CONTACT_BY.LOOKING_AROUND);
};

export const isIntentionOther = (value) => {
    return isEquals(value, Answer.INTENTION.OTHER);
};

export const isAdIntentionOtherOwnWords = (value) => {
    return isEquals(value, Answer.AD_INTENTION_OTHER.OTHER);
};

export const isLookingForPropertyOrActiveVisitting = (value) => {
    return isEquals(value, Answer.PROGRESS.LOOKING_FOR_PROPERTY) || isEquals(value, Answer.PROGRESS.ACTIVE_VISITTING);
};

export const isNotLookingForPropertyOrActiveVisitting = (value) => {
    return !(
        isEquals(value, Answer.PROGRESS.LOOKING_FOR_PROPERTY) || isEquals(value, Answer.PROGRESS.ACTIVE_VISITTING)
    );
};

export const isGoalTodayOther = (value) => {
    return isEquals(value, Answer.GOAL_TODAY.OTHER);
};

export const isAdProgressOther = (object) => {
    return object && object.other ? object.other.value : false;
};

export const hasSecondBorrower = (value) => {
    return hasAnswer(value) && isEquals(value, Answer.PAX.TWO_PERSONS);
};

export const isBuyer = (value) => {
    return hasAnswer(value) && isEquals(value, Answer.CONTRACT.BUYER);
};

export const isNotCallNow = (value) => {
    return hasAnswer(value) && !validator.equals(value, Answer.DAY_PICKER.NOW);
};
