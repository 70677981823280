import validator from "validator";

import { hasAnswer } from "./PageComponentValidators";

import { isValidPhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js";

export const isNumber = (value: number) => {
    return value && !isNaN(value);
};

export const isABiggerThanB = (a: number, b: number) => {
    return isNumber(a) && isNumber(b) && a > b;
};

export const isValidEmail = (email: string) => {
    return hasAnswer(email) && validator.isEmail(email);
};

export const isPriceMin30K = (price: number) => {
    return hasAnswer(price) && price >= 30000;
};

export const isMin20percPrice = (price: number, availableCapital: number) => {
    return hasAnswer(price) && hasAnswer(availableCapital) && availableCapital >= (price / 100) * 20;
};

export const isValidPhone = (phone: string | number) => {
    let phoneNumber = null;
    let isPhoneValid = false;

    try {
        phoneNumber = parsePhoneNumberWithError(String(phone), "AT");

        isPhoneValid = isValidPhoneNumber(phoneNumber.number);
    } catch (error) {
        console.error(error);
    } finally {
        return isPhoneValid;
    }
};
