import * as Sentry from "@sentry/react";
import { Component } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import { User } from "../../../../../redux/reducers/hub.reducer";
import { WorkflowStep } from "../../../../../redux/reducers/survey/workflows/types";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { bpSurvey } from "../../../../styles/constants/breakpoints";
import { colors } from "../../../../styles/constants/colors";
import LoadingModal from "../creditConditions/loadingModal.component";
import { PageStructure } from "../pages/new/types";
import BankCarousel from "./BankCarousel";
const SHOW_OFFER = true;

const INTEREST_RATE_TYPES = [];

INTEREST_RATE_TYPES["fixed"] = "Fix";
INTEREST_RATE_TYPES["variable"] = "Variabel";

const RATE_GOOD = "good";
const RATE_BAD = "bad";

// TODO: TS - properly define types
export type PersonalOfferProps = {
    page: WorkflowStep;
    data: any;
    user?: User;
    loanAmountStructure: PageStructure;
    isLoading?: boolean;
    isError?: boolean;
    email: string;
    errorMessage?: string;
};

type PersonalOfferState = {
    showDetails: boolean;
    vote: string;
};

export class PersonalOffer extends Component<PersonalOfferProps, PersonalOfferState> {
    state = {
        showDetails: false,
        vote: null,
    };

    renderEffectiveInterestRate = (effectiveInterestRate) => {
        if (!effectiveInterestRate) {
            Sentry.captureException(
                `Failed to calculate effectiveInterestRate for ${JSON.stringify(
                    this.props.data,
                )}. Verify the user input arguments for the mortgage engine.`,
            );

            return "/";
        }

        return this.renderPercentFromData(effectiveInterestRate);
    };

    renderPercentFromData = (number) => {
        return (
            <>
                <strong>
                    {parseFloat(number).toLocaleString("DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                </strong>
                &nbsp;%
            </>
        );
    };

    renderPriceFromData = (number) => {
        return (
            <>
                <strong>{Math.floor(number).toLocaleString("DE")}</strong>&nbsp;{this.props.data.currency}
            </>
        );
    };

    renderResults() {
        const { solution } = this.props.data;

        const { months, equity } = solution;

        const {
            payoutAmount,
            effectiveInterestRate,
            debtAmount,
            totalCosts,
            loanAmount,
            monthsFixed,
            interestFixed,
            interestVariable,
            rate,
        } = solution.mortgage;

        const fullCreditLength = parseInt(months) / 12;

        const isFixedAvailable = monthsFixed > 0;
        const fixedPeriodLength = parseInt(monthsFixed) / 12;

        const periods = [];
        if (isFixedAvailable) {
            periods.push({
                interestRateType: "fixed",
                interestRate: interestFixed,
                rate,
                months: monthsFixed,
            });
        }
        periods.push({
            interestRateType: "variable",
            interestRate: interestVariable,
            rate,
            months: months - monthsFixed,
        });

        const { AVAILABLE_CAPITAL } = this.props.loanAmountStructure;
        const usersDownPayment = AVAILABLE_CAPITAL.attributes.value != null ? AVAILABLE_CAPITAL.attributes.value : 0;
        const downPayment = equity;
        const additionalDownPayment = equity > usersDownPayment;

        const handleToggle = () => {
            this.setState((prevState) => ({
                showDetails: !prevState.showDetails,
            }));

            GtmServiceInstance.track({
                event: "topOffer.moreDetails",
                "topOffer.properties": {
                    payoutAmount,
                    effectiveInterestRate,
                    debtAmount,
                    rate,
                    totalCosts,
                },
            });
        };

        return (
            <>
                {SHOW_OFFER && <h3>Dein persönliches Angebot durch Vergleich von führenden österreichischen Banken</h3>}

                <BankCarousel />
                {SHOW_OFFER && (
                    <>
                        <BoxWrapper className="PersonalOffer">
                            <BoxContent>
                                <ResultLabels>
                                    Zinssatz
                                    <span>Monatliche Rate</span>
                                </ResultLabels>
                                <Result className="Result">
                                    {Number(periods[0].interestRate).toLocaleString("DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    &nbsp;<small>%</small>
                                    <span>
                                        {Number(periods[0].rate).toLocaleString("DE", {
                                            maximumFractionDigits: 0,
                                        })}
                                        &nbsp;<small>€</small>
                                    </span>
                                </Result>
                                <Data>
                                    Kredit:&nbsp;
                                    <strong>{this.renderPriceFromData(loanAmount)}</strong>€, Laufzeit:{" "}
                                    <strong>{fullCreditLength}</strong>&nbsp;Jahre (<strong>{fixedPeriodLength}</strong>
                                    &nbsp;J. Fix)
                                    {`${additionalDownPayment ? "," : ""}`}{" "}
                                    {additionalDownPayment && (
                                        <span className="color-blue-600">
                                            Zusätzliche&nbsp;Eigenmittel:&nbsp;
                                            <strong>
                                                {this.renderPriceFromData(equity - Number(usersDownPayment))}
                                            </strong>
                                            €
                                        </span>
                                    )}
                                </Data>
                            </BoxContent>
                            <BoxFooter isOpen={this.state.showDetails}>
                                <MoreButton isOpen={this.state.showDetails} onClick={handleToggle} type="button">
                                    Mehr Details
                                </MoreButton>
                            </BoxFooter>
                            <CSSTransition<undefined>
                                in={this.state.showDetails}
                                timeout={300}
                                unmountOnExit
                                onEnter={(element) => {
                                    element.style.maxHeight = `${element.scrollHeight}px`;
                                }}
                            >
                                <ExtendedContent>
                                    <Row isFirst>
                                        <span>Effektivzinssatz:</span>
                                        {this.renderEffectiveInterestRate(effectiveInterestRate)}
                                    </Row>
                                    {isFixedAvailable && (
                                        <Row>
                                            <span>Sollzinssatz (Fix):</span>
                                            {this.renderPercentFromData(interestFixed)}
                                        </Row>
                                    )}
                                    <Row>
                                        <span>Eigenkapital:</span>
                                        {this.renderPriceFromData(downPayment)}
                                    </Row>
                                    <Row>
                                        <span>Auszahlungsbetrag:</span>
                                        {this.renderPriceFromData(payoutAmount)}
                                    </Row>
                                    <Row>
                                        <span>Gesamtkosten:</span>
                                        {this.renderPriceFromData(totalCosts)}
                                    </Row>
                                    <Row>
                                        <span>Laufzeit insgesamt:</span>
                                        <strong>{fullCreditLength}</strong>&nbsp;Jahre
                                    </Row>
                                    <Row>
                                        <strong>Perioden</strong>
                                    </Row>
                                    {periods.map((period, index) => (
                                        <section key={index}>
                                            <Row>
                                                <span>Laufzeit:</span>
                                                <strong>{parseInt(period.months) / 12}</strong>&nbsp;Jahre
                                            </Row>
                                            <Row>
                                                <span>Sollzinssatz:</span>
                                                {this.renderPercentFromData(period.interestRate)}
                                            </Row>
                                            <Row>
                                                <span>Sollzinsbindung:</span>
                                                <strong>{INTEREST_RATE_TYPES[period.interestRateType]}</strong>
                                            </Row>
                                        </section>
                                    ))}
                                </ExtendedContent>
                            </CSSTransition>
                        </BoxWrapper>
                    </>
                )}
            </>
        );
    }

    renderThumbsFeedback = () => {
        const handleRate = (event) => {
            const voteResult = event.currentTarget.value;

            this.setState(() => ({
                vote: voteResult,
            }));
        };

        return (
            <RateWrapper>
                <RateHeader>Sag uns wie dir das Angebot gefällt um fortzufahren.</RateHeader>

                {/*ToDo: Maybe add wiggle animation to catch the attention*/}
                <RateButtons>
                    <RateButton
                        isActive={this.state.vote === RATE_GOOD}
                        onClick={handleRate}
                        value={RATE_GOOD}
                        type="button"
                    >
                        Ja <img src="/assets/svg/thumb-up.svg" height={28} alt="Ja" />
                    </RateButton>
                    <RateButton
                        isActive={this.state.vote === RATE_BAD}
                        onClick={handleRate}
                        value={RATE_BAD}
                        type="button"
                    >
                        <img src="/assets/svg/thumb-up.svg" height={28} alt="Nein" /> Nein
                    </RateButton>
                </RateButtons>
            </RateWrapper>
        );
    };

    renderLoading() {
        return <LoadingModal subTitle="Berechnung läuft" />;
    }

    renderNoResults() {
        return (
            <Wrapper>
                <BoxWrapper>
                    {/* TODO: Improve this fallback if no offer can be found */}
                    <BoxContent>
                        <h3>Leider konnten wir kein Angebot für dich finden.</h3>
                        Bitte wende dich an uns unter:
                        <br />
                        <a href="tel:00436602069421">
                            <strong>+43 660 2069421 </strong>
                        </a>
                    </BoxContent>
                </BoxWrapper>
            </Wrapper>
        );
    }

    renderError() {
        return (
            <Wrapper>
                <BoxWrapper>
                    <BoxContent>
                        <h2>Etwas ist schief gelaufen, das tut uns Leid!</h2>
                        <p>Leider konnte dein Angebot nicht geladen werden.</p>
                        Bitte wende dich an uns unter:
                        <br />
                        <a href="tel:00436602069421">
                            <strong>+43 660 2069421 </strong>
                        </a>
                        {/*TODO: Add a refresh-control button here, so that the top-offer should be requested again!*/}
                    </BoxContent>
                </BoxWrapper>
            </Wrapper>
        );
    }

    render() {
        const { isLoading, isError, data } = this.props;

        return (
            <Wrapper>
                {!isLoading && !isError && data != null && this.renderResults()}
                {!isLoading && !isError && data == null && this.renderNoResults() && SHOW_OFFER}
                {isLoading && !isError && this.renderLoading()}
                {!isLoading && isError && this.renderError() && SHOW_OFFER}
            </Wrapper>
        );
    }
}

const RateWrapper = styled.div``;

const RateHeader = styled.h4`
    color: ${colors.gray700};
    margin: 30px 0 16px;
    text-align: center;
`;

const RateButtons = styled.div`
    display: flex;
    justify-content: center;
`;

const RateButton = styled.button`
    align-items: center;
    background: none;
    border: 2px solid ${colors.blue600};
    border-radius: 8px;
    color: ${colors.blue600};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 50px;
    justify-content: center;
    width: 100px;

    ${({ isActive }) =>
        isActive &&
        css`
            background: ${colors.blue600};
            color: #fff;

            img {
                filter: brightness(0) invert(1);
            }
        `};

    img {
        display: inline-block;
        margin-left: 6px;
        margin-top: -8px;
    }

    &:last-child {
        margin-left: 20px;

        img {
            margin-left: 0;
            margin-right: 6px;
            margin-top: 8px;
            transform: rotate(180deg);
        }
    }
`;

const Wrapper = styled.div`
    margin-bottom: 48px;

    h3 {
        margin: 0 0 6px;
    }

    > p {
        color: ${colors.gray500};
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 6px 0 20px;
    }
`;

const BoxWrapper = styled.div`
    background: #ffffff;
    box-shadow: 0px 10px 60px -2px rgba(0, 0, 0, 0.3), 0px 2px 12px -4px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    margin-top: 24px;
`;

const BoxContent = styled.div`
    color: ${colors.gray700};
    font-size: 14px;
    padding: 18px 24px 24px;
`;

const ResultLabels = styled.div`
    color: ${colors.gray500};
    display: flex;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;

    span {
        margin-left: auto;
    }
`;

const Result = styled.div`
    align-items: baseline;
    color: ${colors.blue600};
    display: flex;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;

    small {
        color: ${colors.blue500};
        font-weight: 300;
        font-size: 24px;
        line-height: 32px;
    }

    span {
        display: inline-block;
        font-weight: 800;
        margin-left: auto;
        vertical-align: text-top;
    }
`;

const Data = styled.div`
    color: ${colors.gray600};
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    margin-top: 24px;
`;

const BoxFooter = styled.div`
    background-color: ${({ isOpen }) => (isOpen ? "#fff" : colors.gray50)};
    border-top: 1px solid #e5e7eb;
    border-radius: 0px 0px 10px 10px;
    box-sizing: border-box;
    display: flex;
    height: 48px;
    padding: 5px 14px;
    transition: background-color ease-out 0.16s;
`;

const MoreButton = styled.button`
    background: none;
    border: 0;
    color: ${colors.blue700};
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    height: 100%;
    line-height: 16px;
    position: relative;
    text-transform: uppercase;

    &:before {
        border-bottom: 6px solid ${({ isOpen }) => (isOpen ? colors.blue700 : 0)};
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${({ isOpen }) => (isOpen ? 0 : colors.blue700)};
        content: "";
        display: inline-block;
        height: 0;
        margin: 0 10px 1px 0;
        vertical-align: center;
        width: 0;
    }
`;

const ExtendedContent = styled.div`
    color: ${colors.gray600};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    padding: 0 24px;

    h4 {
        margin: 5px 0;
    }

    strong {
        color: ${colors.gray700};
        font-weight: 800;
    }

    > section {
        &:last-child {
            margin-bottom: 24px;
        }

        & > div:last-child {
            border-color: ${colors.gray600};
        }

        &:last-of-type > div:last-child {
            border-color: ${colors.gray200};
        }
    }

    max-height: 0;
    transition: max-height ease-in-out 300ms;

    &.exit-active {
        max-height: 0 !important;
    }

    &.enter-done {
        max-height: none !important;
    }
`;

const ModalWrapper = styled.div`
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    transition: opacity ease-out 500ms;

    > div {
        opacity: 1;
        margin-bottom: 50px;
        margin-top: 140px;
    }

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
    }
`;

const ModalContent = styled.div`
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    max-width: 720px;
    padding: 20px 10px;
    text-align: center;
    width: 360px;

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 520px;
        padding: 40px 30px;
    }
`;

const ModalHeader = styled.div`
    background: #fff;
    text-align: left;
    width: 260px;
`;

const Row = styled.div`
    border-bottom: 1px solid ${colors.gray200};
    display: flex;
    padding: 7px 0;

    ${({ isFirst }) =>
        isFirst &&
        css`
            margin-top: 14px;
        `};

    span {
        margin-right: auto;
    }
`;

export default PersonalOffer;
