import styled from "styled-components";

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement> & {
    rounded?: boolean;
    fromColor?: string;
    toColor?: string;
};

const Skeleton = (props: SkeletonProps): React.ReactElement | null => (
    <Wrapper {...props}>
        <div></div>
    </Wrapper>
);

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: ${({ rounded }) => (rounded ? "50%" : "3px")};
    background-color: ${({ fromColor }) => fromColor || "rgba(0, 0, 0, 0.06)"};
    height: 32px;

    > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        animation: loading 1.8s 0.5s infinite;
        background: ${({ toColor }) =>
            `linear-gradient(90deg,rgba(0, 0, 0, 0),${toColor || "rgba(0, 0, 0, 0.07)"},rgba(0, 0, 0, 0))`};
    }

    @keyframes loading {
        100% {
            transform: translateX(100%);
        }
    }
`;

export default Skeleton;
