import { Document, DocumentProperty } from "@miraclapp/mortgaging-shared";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Upload from "rc-upload";
import React, { Component, SyntheticEvent } from "react";
import styled, { css } from "styled-components";
import AppConfig from "../../../../../config/AppConfig";
import { createDocument, getDocumentUploadUrl, getDocumentUrl } from "../../../../../service/api";
import { colors } from "../../../../styles/constants/colors";
import { DOCUMENT_LIST } from "./constants";

const baseUrl = `${AppConfig.applicationHost}/api/upload/documents`;

const onDragInListeners = ["dragover", "dragenter"];
const onDragOutListeners = ["dragleave", "dragend", "drop"];

// TODO: TS - properly define types - coming from mortgaging server side
export type DocumentUploadItemProps = {
    isActive?: boolean;
    item?: {
        lastNMonths?: number;
        requiredDocumentAmount?: { amount: number; label: string };
        files: Document[];
    };
    property?: DocumentProperty | undefined;
    subProperty?: DocumentProperty | undefined;
    dealId: string;
    handleModalOpen: (name: string, desc?: string) => void;
    token: string;
};

export type DocumentUploadItemState = {
    isOpen: boolean;
    progress: number;
    state?: "uploaded" | "uploading";
    isUploading: boolean;
    error?: string;
    // TODO: TS - properly define Document type
    documents: Document[] | undefined;
};

export class DocumentUploadItem extends Component<DocumentUploadItemProps, DocumentUploadItemState> {
    elementRef = React.createRef<HTMLDivElement>();

    state = {
        isOpen: !!this.props.isActive,
        progress: 0,
        state: undefined,
        isUploading: false,
        error: undefined,
        documents: [],
    };

    onDrag(el: HTMLDivElement) {
        el.classList.add("dragover");
    }
    onDragLeave(el: HTMLDivElement) {
        el.classList.remove("dragover");
    }
    componentDidMount() {
        const el = this.elementRef.current;
        if (el) {
            onDragInListeners.forEach((name) => el.addEventListener(name, () => this.onDrag(el)));
            onDragOutListeners.forEach((name) => el.addEventListener(name, () => this.onDragLeave(el)));
        }
    }
    componentWillUnmount() {
        const el = this.elementRef.current;
        if (el) {
            onDragInListeners.forEach((name) => el.removeEventListener(name, () => this.onDrag(el)));
            onDragOutListeners.forEach((name) => el.removeEventListener(name, () => this.onDragLeave(el)));
        }
    }

    updateState(newState: Partial<DocumentUploadItemState>) {
        this.setState((prev) => ({ ...prev, ...newState }));
    }

    async onDocumentClick(ev: SyntheticEvent<HTMLDivElement>, id: string) {
        const el = ev.currentTarget;
        el.classList.add("loading");
        const response = await getDocumentUrl({ token: this.props.token, id });
        el.classList.remove("loading");
        if (!response || !response.data.data) return;
        window.open(response.data.data, "_blank");
    }

    render() {
        const object = this;
        const { item, property = {} as DocumentProperty, subProperty, dealId, handleModalOpen, token } = this.props;
        const { files, lastNMonths, requiredDocumentAmount } = item || {};
        const allDocuments = [...object.state.documents, ...(files || [])];
        const uploadData = {
            name: property.value,
            categoryId: property.parentId,
            typeId: property.id,
            ...(subProperty
                ? subProperty.type === 4
                    ? { personId: subProperty.id }
                    : { objectId: subProperty.id }
                : {}),
        };

        const uploaderProps = {
            action: `${baseUrl}/${dealId}/${property.value}`,
            customRequest: async ({ data, file, onError, onProgress, onSuccess }) => {
                // TODO: cleanup
                (async () => {
                    // get upload url
                    const urlResponse = await getDocumentUploadUrl({ token, dealId });
                    if (!urlResponse || !urlResponse.data || !urlResponse.data.data) return onError(urlResponse);

                    const { url, fileId } = urlResponse.data.data;
                    // upload file to url
                    try {
                        const uploadResponse = await axios.put(url, file, {
                            headers: { "Content-Type": "application/octet-stream" },
                            onUploadProgress: ({ total, loaded }) => {
                                onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
                            },
                        });

                        if (!uploadResponse || uploadResponse.status !== 200) return onError(uploadResponse);
                        // save document
                        const response = await createDocument({
                            token,
                            dealId,
                            data: {
                                ...data,
                                fileId,
                                filename: file.name || file.originalFilename,
                                mimetype: file.type || file.mimetype,
                            },
                        });
                        if (!response || !response.data || !response.data.data) return onError(response);
                        onSuccess(response.data, file);
                    } catch (error) {
                        onError(error);
                    }
                })();

                return {
                    abort() {
                        Sentry.captureException("Upload progress is aborted.");
                    },
                };
            },
            data: uploadData,
            multiple: true,
            method: "post",
            headers: { token },
            onStart: () => {
                object.updateState({ progress: 0, state: "uploading", error: undefined });
            },
            onProgress(step: { percent: number }) {
                object.updateState({ progress: step.percent });
            },
            onError(err: unknown) {
                object.updateState({
                    progress: 0,
                    state: undefined,
                    error: "Dokument konnte nicht hochgeladen werden",
                });
                Sentry.setContext("FormSubmit", { form: JSON.stringify(err), dealId, id: property.value });
            },
            onSuccess(response: any) {
                if (!response) {
                    object.updateState({
                        progress: 0,
                        state: undefined,
                        error: "Dokument konnte nicht hochgeladen werden",
                    });
                    Sentry.captureException(
                        `No response returned from document upload of ${property.value} to deal ${dealId}`,
                    );
                    return;
                }
                object.setState((prev) => ({
                    progress: 0,
                    state: "uploaded",
                    documents: [response.data, ...prev.documents],
                }));
            },
        };

        const isUploading = this.state.state === "uploading";
        const isUploaded = this.state.state === "uploaded";
        return (
            <Item ref={this.elementRef}>
                <UploadWrapper component="div" isUploading={isUploading} {...uploaderProps}>
                    <ProgressBar progress={this.state.progress} />
                    {isUploading ? (
                        <Icon src="/assets/svg/loading.svg" width="18" className="rotate" />
                    ) : (
                        <>
                            <IconsWrapper>
                                <UploadButton>
                                    {isUploaded ? (
                                        <CheckIcon>
                                            <Icon src="/assets/svg/doubleCheck.svg" width="14" />
                                        </CheckIcon>
                                    ) : null}
                                    <Icon src="/assets/svg/upload.svg" width="18" />
                                    <input type="file" hidden />
                                </UploadButton>
                            </IconsWrapper>
                        </>
                    )}
                    <TextWrapper>
                        <Title>{property.name}</Title>
                        <div>
                            {lastNMonths ? <Subtext>(letzte {lastNMonths} Monate)</Subtext> : null}
                            {requiredDocumentAmount ? (
                                <Subtext>
                                    ({requiredDocumentAmount.label}: {requiredDocumentAmount.amount}€)
                                </Subtext>
                            ) : null}
                        </div>
                    </TextWrapper>
                    {DOCUMENT_LIST[property.value] ? (
                        <InfoButton
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleModalOpen(property.name, DOCUMENT_LIST[property.value].description);
                            }}
                        >
                            <Icon src="/assets/svg/info.svg" width="18" />
                        </InfoButton>
                    ) : null}
                </UploadWrapper>
                {this.state.error ? <ErrorMessage className="validation-error">{this.state.error}</ErrorMessage> : null}
                {allDocuments.length > 0 ? (
                    <DocumentList>
                        {allDocuments.map((doc) => {
                            if (!doc) return null;
                            return (
                                <DocumentCard
                                    key={doc.id}
                                    onClick={(ev) => this.onDocumentClick(ev, doc.id)}
                                    role="button"
                                >
                                    {doc.name || doc.fileId}
                                    <Icon src="/assets/svg/eye.svg" width="18" />
                                </DocumentCard>
                            );
                        })}
                    </DocumentList>
                ) : null}
            </Item>
        );
    }
}

const Item = styled.div`
    margin-bottom: 5px;
    overflow: hidden;

    &.dragover > div {
        border: 2px dotted #e0e0e0;
        animation: color 1.4s linear infinite;
    }
    @keyframes color {
        0% {
            border-color: #e0e0e0;
        }
        50% {
            border-color: #888;
        }
        100 {
            border-color: #e0e0e0;
        }
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
`;

const UploadWrapper = styled(Upload)`
    align-items: center;
    border-radius: 6px;
    border: 2px solid ${({ isUploading }) => (isUploading ? colors.primary : "transparent")};
    pointer-events: ${({ isUploading }) => (isUploading ? "none" : "auto")};
    cursor: ${({ isUploading }) => (isUploading ? "wait" : "pointer")};
    display: flex;
    min-height: 44px;
    padding: 5px;
    position: relative;

    &:hover {
        border: 2px dotted #e0e0e0;
    }

    ${({ isOpen }) => isOpen && css``};
`;

const ProgressBar = styled.div`
    background: #dff4fe;
    border-radius: 6px;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: ${({ progress }) => (progress ? progress : 0)}%;
    z-index: 0;
`;

const UploadButton = styled.div`
    position: relative;
    cursor: pointer;
    padding: 5px;
`;

const IconsWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 20px;
    z-index: 1;
`;

const CheckIcon = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    margin: 3px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #e6e6ff;
    flex-shrink: 0;
    opacity: 0;
    animation: 4s fade;

    @keyframes fade {
        0% {
            opacity: 1;
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

const Icon = styled.img`
    display: block;

    &.rotate {
        animation: spin 1.4s linear infinite;
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 1.25;
    margin: 0 5px;
    z-index: 1;
`;
const Title = styled.div`
    font-weight: 600;
`;
const Subtext = styled.div`
    font-size: 10px;
    margin-left: 4px;
    color: #999;
`;

const InfoButton = styled.div`
    cursor: pointer;
    margin-left: auto;
    padding: 7px;
    position: relative;
    z-index: 9;

    &:hover {
        filter: invert(50%);
    }
`;

const DocumentList = styled.div``;

const DocumentCard = styled.div`
    display: flex;
    gap: 0.25rem;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 0.3rem 0;
    font-size: 0.75rem;
    color: initial;
    line-height: 1.25;
    padding: 0.3rem 0.5rem;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s ease-in;

    > div {
        word-break: break-all;
    }
    > img {
        opacity: 0.55;
    }

    &:hover {
        border-color: #ccc;
    }
    &.loading {
        > img {
            animation: pulse 1.7s infinite;
        }
    }

    @keyframes pulse {
        0% {
            opacity: 0.55;
        }
        50% {
            opacity: 0.15;
        }
        100% {
            opacity: 0.6;
        }
    }
`;

const ErrorMessage = styled.div`
    margin-top: 3px;
    font-size: 10px;
    font-weight: 600;
    padding: 0.35rem;
    border-radius: 5px;
    border: 1px solid #e8818b;
    background-color: #f8d7da;
`;

export default DocumentUploadItem;
