import React, { Component, MouseEventHandler } from "react";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";

import { bpSurvey } from "../../../../styles/constants/breakpoints";
import { colors } from "../../../../styles/constants/colors";

export type LoadingModalProps = {
    title?: string;
    subTitle?: string;
    handleModalClose?: MouseEventHandler;
};

export class LoadingModal extends Component<LoadingModalProps> {
    render() {
        return (
            <Wrapper onClick={this.props.handleModalClose} className="loading-modal">
                <ModalWrapper onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
                    <Title>{this.props.title}</Title>
                    {this.props.subTitle && <SubTitle>{this.props.subTitle}</SubTitle>}
                    <Oval wrapperClass="loader" color="#4A49FB" secondaryColor="#4A49FB" height={50} width={50} />
                </ModalWrapper>
            </Wrapper>
        );
    }

    static defaultProps = { title: "Lade..." };
}

const Wrapper = styled.div`
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    transition: opacity ease-out 500ms;

    > div {
        opacity: 1;
        margin-bottom: 50px;
        margin-top: 140px;
    }

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
    }
`;

const ModalWrapper = styled.div`
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    max-width: 460px;
    padding: 30px 20px;
    text-align: center;
    width: 288px;

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 520px;
        padding: 40px 30px;
    }
`;

const Title = styled.div`
    color: ${colors.primary};
    font-size: 23px;
    font-weight: 500;
    letter-spacing: -0.78px;
    line-height: 29px;
    margin: 0 0 30px;
`;

const SubTitle = styled.div`
    color: ${colors.basicText};
    font-size: 16px;
    font-weight: 150;
    letter-spacing: -0.78px;
    line-height: 20px;
    margin: 0 0 30px;
`;

export default LoadingModal;
