import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./translations/de";

const resources = {
    de,
};

i18n.use(initReactI18next).init({
    resources,
    /**
     * Language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
     *
     * if you're using a language detector, do not define the lng option
     */
    lng: "de",
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
