import React, { Component } from "react";
import styled from "styled-components";

export type ErrorBoxProps = {
    error: React.ReactNode;
};

export class ErrorBox extends Component<ErrorBoxProps> {
    render() {
        return <Wrapper className="error-msg">{this.props.error}</Wrapper>;
    }
}

const Wrapper = styled.div`
    background: #fee2e2;
    border: 1px solid #fca5a5;
    border-radius: 8px;
    color: #7f1d1d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 24px -14px;
    padding: 24px;
`;

export default ErrorBox;
