import type { PageStructureItem, PageStructureKeys } from "../../app/elements/survey/components/pages/new/types";
import type { SURVEY_WORKFLOW_PAGE_INDICES_Values } from "../reducers/survey/config/survey.config";
import type { Workflow } from "../reducers/survey/workflows/types";

export const SET_SURVEY_PAGE = "SET_SURVEY_PAGE";

export const SET_SURVEY_WORK_FLOW = "SET_SURVEY_WORK_FLOW";

export const SET_PAGE_COMPONENT_ERROR = "SET_PAGE_COMPONENT_ERROR";

export const SET_PAGE_COMPONENT_VALUE = "SET_PAGE_COMPONENT_VALUE";

/**
 * Use this action to set the current active / visible survey page.
 */
export function setSurveyPage(nextPage: string) {
    return {
        type: SET_SURVEY_PAGE,
        currentPage: nextPage,
    } as const;
}
export type SetSurveyPage = ReturnType<typeof setSurveyPage>;

export function setSurveyWorkflow(workflow: Workflow) {
    return {
        type: SET_SURVEY_WORK_FLOW,
        workflow,
    } as const;
}
export type SetSurveyWorkflow = ReturnType<typeof setSurveyWorkflow>;

export function setPageComponentError(
    page: SURVEY_WORKFLOW_PAGE_INDICES_Values,
    component: PageStructureKeys,
    isError?: string[],
) {
    return {
        type: SET_PAGE_COMPONENT_ERROR,
        page,
        component,
        isError,
    } as const;
}
export type SetPageComponentError = ReturnType<typeof setPageComponentError>;

export function setPageComponentValue(
    page: SURVEY_WORKFLOW_PAGE_INDICES_Values,
    component: PageStructureKeys,
    value: PageStructureItem["attributes"]["value"],
) {
    return {
        type: SET_PAGE_COMPONENT_VALUE,
        page,
        component,
        value,
    } as const;
}
export type SetPageComponentValue = ReturnType<typeof setPageComponentValue>;

export type SurveyActions = SetSurveyPage | SetSurveyWorkflow | SetPageComponentError | SetPageComponentValue;
