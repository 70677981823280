import { Component } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import QuestionItem from "./QuestionItem";

export type SingleSelectionGroupButtonProps = {
    options: string[];
    gamification?: string;
    property: string;
    value?: string | null;
    label?: string | null;
    errors: React.ReactNode[];
    onChange: (value: { value: string | null }) => void;
    pageName: string;
    tooltip?: string;
    tooltipAlignBottom?: boolean;
    required?: boolean;
};

export class SingleSelectionGroupButton extends Component<SingleSelectionGroupButtonProps> {
    state = {
        activeButton: this.props.value || null,
    };

    getGamification() {
        if (this.props.gamification && this.state.activeButton) {
            return this.props.gamification[this.state.activeButton];
        }

        return null;
    }

    renderGroupButtons() {
        return this.props.options.map((entry, index) => {
            const isActive = this.state.activeButton === entry;

            return (
                <RadioButton
                    key={index}
                    className="radio-btn"
                    onClick={(e) => {
                        this.setState(
                            {
                                activeButton: entry,
                            },
                            () => {
                                this.props.onChange({
                                    value: entry,
                                });
                            },
                        );
                    }}
                    name={this.props.property}
                    isActive={isActive}
                    type="button"
                >
                    {entry}
                </RadioButton>
            );
        });
    }

    render() {
        const isVertical = this.props.options.length > 2;

        return (
            <QuestionItem
                pageName={this.props.pageName}
                tooltip={this.props.tooltip}
                tooltipAlignBottom={this.props.tooltipAlignBottom}
                labelAbove={this.props.label}
                wrapperWidth="100%"
                infoMarginTop={isVertical ? "11px" : "0"}
                errors={this.props.errors}
                gamification={this.getGamification()}
                label={this.props.label}
                required={this.props.required}
            >
                <label>
                    <RadioWrapper className="radio-btn-group" isVertical={isVertical}>
                        {this.renderGroupButtons()}
                    </RadioWrapper>
                </label>
            </QuestionItem>
        );
    }
    static defaultProps = { options: [], label: null };
}

const RadioWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -12px;

    ${({ isVertical }) =>
        isVertical &&
        css`
            flex-flow: column;
            margin-top: 2px;

            & > span {
                margin-bottom: 28px;

                &:last-child {
                    margin-bottom: 7px;
                }
            }
        `};
`;

const RadioButton = styled.button`
    align-items: flex-start;
    background: transparent;
    border: 0;
    color: ${colors.gray900};
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 700;
    margin: 0 30px 14px 0;
    padding: 2px 0;
    text-align: left;

    &:before {
        border: ${({ isActive }) => (isActive ? 6 : 2)}px solid
            ${({ isActive }) => (isActive ? colors.blue600 : colors.gray500)};
        border-radius: 50%;
        box-sizing: border-box;
        content: "";
        display: inline-block;
        height: 20px;
        margin-right: 15px;
        width: 20px;
        min-width: 20px;
    }

    &:focus-visible {
        outline: none;

        &:before {
            border-color: ${({ isActive }) => (isActive ? colors.blue300 : colors.blue600)};
        }
    }
`;

export default SingleSelectionGroupButton;
