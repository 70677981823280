import parseHtml from "html-react-parser";
import { Component } from "react";
import ClickAwayListener from "react-click-away-listener";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export type InfoTooltipProps = {
    isOpen?: boolean;
    handleClose: () => void;
    alignBottom?: boolean;
};

export class InfoTooltip extends Component<InfoTooltipProps> {
    render() {
        return (
            <>
                <CSSTransition in={this.props.isOpen} timeout={160} unmountOnExit>
                    <ClickAwayListener onClickAway={this.props.handleClose}>
                        <Tooltip alignBottom={this.props.alignBottom}>
                            {parseHtml(String(this.props.children))}
                            <CloseButton onClick={this.props.handleClose}>
                                <img src="/assets/svg/close-x-10.svg" width="10" height="10" alt="Schließen" />
                            </CloseButton>
                        </Tooltip>
                    </ClickAwayListener>
                </CSSTransition>
            </>
        );
    }
}

const Tooltip = styled.div`
    background: ${colors.blue100};
    border-radius: 16px;
    box-sizing: border-box;
    color: ${colors.gray800};
    font-size: 14px;
    line-height: 20px;
    max-width: 327px;
    opacity: 0;
    padding: 24px 32px 24px 16px;
    position: absolute;
    right: -12px;
    transition: all ease-in-out 160ms;
    width: 100%;
    white-space: pre-wrap;
    z-index: 10;

    &:after {
        background: ${colors.blue100};
        content: "";
        height: 14px;
        position: absolute;
        right: 20px;
        transform: rotate(45deg);
        width: 14px;
    }

    &.exit-active {
        margin-bottom: 25px;
        opacity: 0 !important;
    }
    &.enter-done {
        opacity: 1 !important;
    }

    ${({ alignBottom }) =>
        alignBottom &&
        css`
            margin-top: -20px;
            top: 56px;

            &:after {
                top: -7px;
            }
            &.exit-active {
                margin-top: -20px;
            }
            &.enter-done {
                margin-top: 5px;
            }
        `};

    ${({ alignBottom }) =>
        !alignBottom &&
        css`
            bottom: 100%;
            margin-bottom: 25px;

            &:after {
                bottom: -7px;
            }
            &.exit-active {
                margin-bottom: 25px;
            }
            &.enter-done {
                margin-bottom: 5px;
            }
        `};

    ul {
        margin: 0;
        padding: 0 0 0 15px;
    }
    li {
        margin: 0 0 3px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    strong {
        font-weight: 500;
    }
`;

const CloseButton = styled.span`
    cursor: pointer;
    opacity: 0.5;
    padding: 7px;
    position: absolute;
    right: 9px;
    top: 9px;

    &:hover {
        opacity: 1;
    }

    img {
        display: block;
    }
`;

export default InfoTooltip;
