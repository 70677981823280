import { Component } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../../styles/constants/colors";

export type RuntimeSelectItemProps = {
    name?: string;
    label?: string;
    /* Pre-selection of an option */
    selected?: string | number;
    options?: (string | number)[];
    onChange: (val: string | number) => void;
};

export class RuntimeSelectItem extends Component<RuntimeSelectItemProps> {
    render() {
        return (
            <Wrapper>
                <Label>{this.props.label}</Label>
                <OptionsWrapper onChange={this.props.onChange}>
                    {this.props.options.map((item, index) => (
                        <Option key={index} isActive={this.props.selected === item}>
                            <input
                                type="radio"
                                value={item}
                                name="runtime"
                                defaultChecked={this.props.selected === item}
                                hidden
                            />
                            {item}
                        </Option>
                    ))}
                </OptionsWrapper>
            </Wrapper>
        );
    }

    static defaultProps = { selected: null, options: [], onChange: null, label: "", name: "" };
}

const Wrapper = styled.div`
    margin-right: auto;
    width: 80%;
`;

const OptionsWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const Option = styled.label`
    align-items: center;
    background: #fff;
    border: 1px solid ${colors.primary};
    border-radius: 4px;
    box-sizing: border-box;
    color: ${colors.primary};
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    height: 28px;
    line-height: 20px;
    margin-left: 3%;
    justify-content: center;
    width: 34px;

    &:first-child {
        margin-left: 0;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background: ${colors.primary};
            color: #fff;
            font-weight: 500;
        `};
`;

const Label = styled.div`
    color: #000;
    font-size: 11px;
    margin: 0 0 2px 2px;
`;

export default RuntimeSelectItem;
