import { Component, MouseEventHandler } from "react";
import styled from "styled-components";
import { H2 } from "../../../styles/base";
import { bpSurvey } from "../../../styles/constants/breakpoints";
import { colors } from "../../../styles/constants/colors";

export type FinancingExampleProps = {
    handleModalClose: MouseEventHandler;
};

export class FinancingExample extends Component<FinancingExampleProps> {
    renderFinancingExample() {
        return (
            <Wrapper onClick={this.props.handleModalClose}>
                <ModalWrapper onClick={(e) => e.stopPropagation()}>
                    <Header>
                        Finanzierungsbeispiel
                        <CloseBtn onClick={this.props.handleModalClose}>&#x2715;</CloseBtn>
                    </Header>
                    <table>
                        <tbody>
                            <tr>
                                <td>Kreditbetrag</td>
                                <td>200.000 €</td>
                            </tr>
                            <tr>
                                <td>Laufzeit</td>
                                <td>30 Jahre</td>
                            </tr>
                            <tr>
                                <td>Zinssatz (variabel)</td>
                                <td>1,000 %</td>
                            </tr>
                            <tr>
                                <td>Effektiver Zinssatz</td>
                                <td>1,296 %</td>
                            </tr>
                            <tr>
                                <td>Monatliche Raten</td>
                                <td>650 €</td>
                            </tr>
                            <tr>
                                <td>Zu zahlender Gesamtbetrag</td>
                                <td>234.031 €</td>
                            </tr>
                            <tr>
                                <td>Tatsächlicher Auszahlungsbetrag</td>
                                <td>193.808 €</td>
                            </tr>
                            <tr>
                                <td>Einberechnete Kosten</td>
                                <td>8.183 €</td>
                            </tr>
                        </tbody>
                    </table>
                    <BottomInfo>
                        <small>
                            *Repräsentatives Beispiel laut Hypothekar- und Immobiliengesetz (
                            <a href="https://rdb.manz.at/document/ris.n.NOR40176276" rel="noreferrer" target="_blank">
                                HlKrG
                            </a>
                            )
                        </small>
                        <p>
                            Der Kreditvertrag wird mit einem Pfandrecht besichert. Sämtliche Werte in der Berechnung
                            verstehen sich als unverbindliche Richtwerte und sind von Bonität, Kredithöhe, Laufzeit,
                            Verwendungszweck und Besicherung abhängig.
                        </p>
                    </BottomInfo>
                </ModalWrapper>
            </Wrapper>
        );
    }

    render() {
        return this.renderFinancingExample();
    }
}

const modalPadding = "60px";

const Wrapper = styled.div`
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }
    td {
        padding: 7px 10px;
        vertical-align: top;

        :first-child {
            color: ${colors.primary};
        }
        :last-child {
            text-align: right;
            white-space: nowrap;
        }
    }

    transition: opacity ease-out 300ms;

    > div {
        opacity: 1;
        margin-bottom: ${modalPadding};
        margin-top: ${modalPadding};
        transition: margin-top ease-out 300ms;
    }
    &.enter {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
    &.enter-active {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit-active {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
`;

const ModalWrapper = styled.div`
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    max-width: 460px;
    padding: 30px 20px;
    width: 90%;

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 520px;
        padding: 40px 30px;
    }
`;

const BottomInfo = styled.div`
    border-top: 2px solid #dcdcff;
    margin-top: 25px;
    text-align: justify;

    small {
        display: block;
        font-size: 11px;
        padding: 7px 10px;
    }
    p {
        margin: 20px 0 0;
        font-size: 14px;
        line-height: 1.5;
    }

    @media (min-width: ${bpSurvey.largeMin}) {
        padding-top: 12px;

        small {
            font-size: 15px;
            line-height: 1.6;
        }
    }
`;

const Header = styled(H2)`
    align-items: center;
    display: flex;
`;

const CloseBtn = styled.span`
    cursor: pointer;
    font-size: 1.1em;
    margin-left: auto;
`;

export default FinancingExample;
