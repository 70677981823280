import parseHtml from "html-react-parser";
import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export type GamificationTooltipProps = {
    text?: string;
    label?: string;
};

export class GamificationTooltip extends Component<GamificationTooltipProps> {
    render() {
        const { text, label } = this.props;

        return (
            <Wrapper>
                <Box>{parseHtml(text || label)}</Box>
                <Avatar src="/assets/avatar/david.jpeg" width={48} height={48} alt="David" />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin-top: 12px;
    padding: 0 58px 10px 0;
    position: relative;
`;

const Box = styled.div`
    background: ${colors.blue100};
    border-radius: 16px 16px 0px 16px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
    padding: 24px 16px;
    white-space: pre-wrap; /* or pre-line */
    & + span {
        color: ${colors.gray700};
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
    }

    ul {
        margin: 10px 0;
        padding: 0 0 0 20px;
    }
    p {
        margin: 10px 0 0;
    }
`;

const Avatar = styled.img`
    border: 3px solid #fff;
    border-radius: 50%;
    bottom: 0;
    position: absolute;
    right: 0;
`;

export default GamificationTooltip;
