import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export type ErrorLabelProps = {
    error: React.ReactNode;
};

export class ErrorLabel extends Component<ErrorLabelProps> {
    render() {
        return <ErrorText className="error-label">{this.props.error}</ErrorText>;
    }
}

const ErrorText = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.error};
    margin: 0 0 10px;
    padding: 0 6px;
`;

export default ErrorLabel;
