import { Component } from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import validator from "validator";
import { InputCss } from "../../../../styles/newStyle";
import { PageStructure } from "../pages/new/types";
import QuestionItem from "./QuestionItem";

const ENTER_KEY = "Enter";

export type PercentageNumberInputProps = {
    label?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    value: number | null;
    errors: React.ReactNode[];
    allowLeadingZeros?: boolean;
    onChange: (value: { value: string | number | null }) => void;
    pageStructure: PageStructure;
    pageName: string;
    tooltip?: string;
    tooltipAlignBottom?: boolean;
    helperText: string;
    required?: boolean;
    thousandSeparator?: string;
    decimalSeparator?: string;
    suffix?: string;
    decimalScale?: number;
    property: string;
    disabled?: boolean;
    percentageLabel?: boolean;
    autocomplete?: boolean;
};

export class PercentageNumberInput extends Component<PercentageNumberInputProps> {
    state = {
        input: this.props.value || "",
        /* Pre-set default values for error message */
        isEmpty: this.props.value === undefined ? true : false,
        isValidNumber: true,
    };

    handleInputChange = (value) => {
        this.setState({
            input: value.floatValue,
            isEmpty: value.floatValue === undefined ? true : false,
        });
    };

    render() {
        // TODO:
        // const { kaufpreis = 200000 } = this.props.properties;

        const kaufpreis = 2000;
        return (
            <>
                <QuestionItem
                    tooltip={this.props.tooltip}
                    tooltipAlignBottom={this.props.tooltipAlignBottom}
                    wrapperWidth="100%"
                    pageName={this.props.pageName}
                    label={this.props.label}
                >
                    <StyledNumberFormat
                        allowNegative={false}
                        thousandSeparator={this.props.thousandSeparator}
                        decimalSeparator={this.props.decimalSeparator}
                        decimalScale={this.props.decimalScale}
                        pattern="[0-9]*"
                        autoFocus={this.props.autoFocus}
                        onBlur={() => {
                            this.props.onChange({
                                value: this.state.input,
                            });
                        }}
                        onKeyDown={(e) => {
                            if (validator.equals(e.key, ENTER_KEY)) {
                                this.props.onChange({
                                    value: this.state.input,
                                });
                            }
                        }}
                        type={"text"}
                        label={this.props.label}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        name={this.props.property}
                        onValueChange={this.handleInputChange}
                        value={this.state.input}
                        disabled={this.props.disabled}
                        error={this.state.isEmpty || !this.state.isValidNumber ? 1 : 0}
                    />
                    <span className="label" hidden={this.state.isEmpty && this.props.label ? true : false}>
                        {this.props.label}
                    </span>
                </QuestionItem>

                <div>
                    <p>{(kaufpreis / 100) * this.props.value}</p>
                </div>
            </>
        );
    }
    static defaultProps = { label: null, autoFocus: false, placeholder: "", value: null };
}

const StyledNumberFormat = styled(NumberFormat)`
    ${InputCss};
`;

export default PercentageNumberInput;
