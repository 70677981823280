import { Component } from "react";
import NumberFormat from "react-number-format";
import styled, { css } from "styled-components";
import { InputCss } from "../../../../styles/newStyle";
import { PageStructure } from "../pages/new/types";
import QuestionItem from "./QuestionItem";

export type NumberInputProps = {
    label?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    value?: number | null;
    errors?: React.ReactNode[];
    allowLeadingZeros?: boolean;
    onChange: (value: { value: number | null }) => void;
    pageStructure: PageStructure;
    pageName?: string;
    tooltip?: string;
    tooltipAlignBottom?: boolean;
    helperText?: string;
    required?: boolean;
    thousandSeparator?: string;
    decimalSeparator?: string;
    suffix?: string;
    decimalScale?: number;
    property?: string;
    disabled?: boolean;
    percentageLabel?: boolean;
    autocomplete?: boolean;
};

export class NumberInput extends Component<NumberInputProps> {
    state = {
        input: this.props.value || "",
        /* Pre-set default values for error message */
        isEmpty: !this.props.value ? true : false,
    };

    handleInputChange = (value) => {
        const newValue = this.props.allowLeadingZeros ? value.value : value.floatValue;

        this.setState({
            input: newValue,
            isEmpty: newValue === undefined || newValue === "" ? true : false,
        });

        this.props.onChange({
            value: newValue,
        });
    };

    getInputMode = (isDecimal: boolean) => {
        if (isDecimal) return "decimal";
        return "numeric";
    };

    render() {
        const price =
            this.props.pageStructure.PRICE && this.props.pageStructure.PRICE.attributes.value > 0
                ? this.props.pageStructure.PRICE.attributes.value
                : 0;

        return (
            <QuestionItem
                pageName={this.props.pageName}
                tooltip={this.props.tooltip}
                tooltipAlignBottom={this.props.tooltipAlignBottom}
                wrapperWidth="100%"
                errors={this.props.errors}
                label={this.props.label}
                helperText={this.props.helperText}
            >
                <label>
                    <StyledNumberFormat
                        // OnBlur is triggered when focus of the input is left:
                        // 1. When going from input to next input with mouse click or tabs
                        // 2. When going from input to clicking on non-input elements such as the background
                        // 3. NOT when prompting enter and the onFocus is still the same input
                        // 4. When going from input to clicking on non-input elements such as the background
                        allowNegative={false}
                        thousandSeparator={this.props.thousandSeparator}
                        decimalSeparator={this.props.decimalSeparator}
                        decimalScale={this.props.decimalScale}
                        suffix={this.props.suffix}
                        pattern="[0-9]*"
                        autoFocus={this.props.autoFocus}
                        type={"text"}
                        label={this.props.label}
                        placeholder={this.props.placeholder}
                        onValueChange={this.handleInputChange}
                        value={this.state.input}
                        name={this.props.property}
                        disabled={this.props.disabled}
                        ispercentage={this.props.percentageLabel}
                        autoComplete={this.props.autocomplete}
                        allowLeadingZeros={this.props.allowLeadingZeros}
                        inputMode={this.getInputMode(this.props.percentageLabel)}
                    />
                    <span className="label">
                        {this.props.label}
                        {this.props.required && <span className="required">&nbsp;*</span>}
                    </span>
                    {this.props.percentageLabel && (
                        <PercentageWrapper>
                            <span className="label">{this.props.percentageLabel}</span>
                            {Math.floor((Number(price) / 100) * this.props.value || 1).toLocaleString("DE")} €
                        </PercentageWrapper>
                    )}
                </label>
            </QuestionItem>
        );
    }
    static defaultProps = { label: null, autoFocus: false, placeholder: "", value: null, errors: [] };
}

const StyledNumberFormat = styled(NumberFormat)`
    ${InputCss};

    ${({ ispercentage }) =>
        ispercentage &&
        css`
            width: 132px;
        `};
`;

const PercentageWrapper = styled.div`
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 700;
    height: 56px;
    padding: 22px 0 0 15px;
    position: relative;
`;

export default NumberInput;
