import SliderRC from "rc-slider";
import "rc-slider/assets/index.css";
import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import QuestionItem from "./QuestionItem";

export type SliderProps = {
    options: number[];
    label?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    value: number | null;
    errors: React.ReactNode[];
    onChange: (value: { value: string | null }) => void;
    pageName: string;
    tooltip?: string;
    tooltipAlignBottom?: boolean;
    helperText?: string;
    required?: boolean;
    property: string;
    disabled?: boolean;
};

export class Slider extends Component<SliderProps> {
    state = {
        sliderValue: this.props.value || null,
    };

    handleChange = (value: number) => {
        this.setState({
            sliderValue: value,
        });
    };

    handleAfterChange = (value: number) => {
        this.props.onChange({
            value: value.toString(),
        });
    };

    render() {
        return (
            <QuestionItem
                pageName={this.props.pageName}
                tooltip={this.props.tooltip}
                tooltipAlignBottom={this.props.tooltipAlignBottom}
                wrapperWidth="100%"
                errors={this.props.errors}
                helperText={this.props.helperText}
            >
                <SliderLabel>
                    {this.props.label} <strong>{this.state.sliderValue}</strong> {this.props.placeholder}
                </SliderLabel>
                <SliderTrackWrapper data-field={this.props.property}>
                    <SliderRC
                        min={this.props.options[0]}
                        max={this.props.options[this.props.options.length - 1]}
                        step={5}
                        defaultValue={this.state.sliderValue}
                        onChange={this.handleChange}
                        onAfterChange={this.handleAfterChange}
                        handleStyle={{
                            height: 20,
                            width: 20,
                            marginLeft: 0,
                            marginTop: -8,
                            backgroundColor: "transparent",
                            opacity: 1,
                        }}
                        railStyle={{
                            backgroundColor: "#ddd",
                            height: 4,
                            borderRadius: 8,
                        }}
                        trackStyle={{
                            backgroundColor: colors.blue500,
                            height: 4,
                            borderRadius: 8,
                            opacity: 1,
                        }}
                    />
                </SliderTrackWrapper>
            </QuestionItem>
        );
    }

    static defaultProps = { label: null, autoFocus: false, placeholder: "", value: null };
}

const SliderLabel = styled.div`
    color: ${colors.gray500};
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 8px 0;

    strong  {
        color: ${colors.gray700};
        font-weight: 700;
    }
`;

const SliderTrackWrapper = styled.div`
    padding: 0 0;

    .rc-slider-handle {
        background: ${colors.gray50} !important;
        border: 4px solid ${colors.blue500} !important;
        transition: box-shadow ease-in-out 0.14s;
    }
    .rc-slider-handle,
    .rc-slider-handle:focus-visible {
        box-shadow: 0 0 0 0 ${colors.blue500};
    }
    .rc-slider-handle-dragging {
        box-shadow: 0 0 0 4px ${colors.blue500} !important;
    }
`;

export default Slider;
