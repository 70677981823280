import {
    initializeWorkflow,
    SURVEY_PAGE_PROPERTIES,
    SURVEY_PAGE_SUBMIT_TYP,
    SURVEY_PAGE_TYPES,
    SURVEY_WORKFLOW_PAGE_INDICES,
} from "../config/survey.config";

import { Workflow } from "./types";

import { pageStructure as keyData } from "../../../../app/elements/survey/components/pages/new/keyDataPage/constants";

import { pageStructure as personalData } from "../../../../app/elements/survey/components/pages/new/personalDataPage/constants";

import AppConfig from "../../../../config/AppConfig";

import { pageStructure as email } from "../../../../app/elements/survey/components/pages/new/emailPage/constants";

import { pageStructure as loanAmount } from "../../../../app/elements/survey/components/pages/new/loanAmountPage/constants";

import { pageStructure as personalFinances } from "../../../../app/elements/survey/components/pages/new/personalFinancesPage/constants";

import { pageStructure as topOffer } from "../../../../app/elements/survey/components/pages/new/topOfferPage/constants";

const workflow: Workflow = {
    flow: {
        [SURVEY_WORKFLOW_PAGE_INDICES.keyData]: {
            type: SURVEY_PAGE_TYPES.keyDataPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
            property: SURVEY_PAGE_PROPERTIES.keyData,
            progressStep: 0,
            meta: {
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.historyBack,
            },
            pageStructure: keyData,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.loanAmount]: {
            type: SURVEY_PAGE_TYPES.loanAmountPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
            property: SURVEY_PAGE_PROPERTIES.loanAmount,
            progressStep: 1,
            meta: {
                text: {
                    nextButton: "Weiter",
                },
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
            },
            pageStructure: loanAmount,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.personalFinances]: {
            type: SURVEY_PAGE_TYPES.personalFinancesPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
            property: SURVEY_PAGE_PROPERTIES.personalFinances,
            progressStep: 2,
            meta: {
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.email,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
            },
            pageStructure: personalFinances,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.email]: {
            type: SURVEY_PAGE_TYPES.emailPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.email,
            property: SURVEY_PAGE_PROPERTIES.email,
            progressStep: 3,
            meta: {
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.personalData,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
            },
            pageStructure: email,
            submitType: SURVEY_PAGE_SUBMIT_TYP.create,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.personalData]: {
            type: SURVEY_PAGE_TYPES.personalDataPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.personalData,
            property: SURVEY_PAGE_PROPERTIES.personalData,
            progressStep: 4,
            meta: {
                text: {
                    nextButton: AppConfig.CTA.personalData,
                    navigationButtonToolTip:
                        "Unsere Berater kümmern sich persönlich um dein Anliegen - kostenlos und unverbindlich.",
                },
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.topOffer,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.email,
            },
            pageStructure: personalData,
            submitType: SURVEY_PAGE_SUBMIT_TYP.update,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.topOffer]: {
            type: SURVEY_PAGE_TYPES.topOffer,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.topOffer,
            property: SURVEY_PAGE_PROPERTIES.topOffer,
            hidePageTitle: true,
            progressStep: 5,
            meta: {
                text: null,
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                    hasStickyFooter: true,
                },
            },
            context: {
                defaultNextPage: null,
                defaultPrevPage: null,
            },
            pageStructure: topOffer,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
    },
    // Should be equal to the largest "progressStep" value of the flow pages (top-offer not included)
    totalSteps: 5,
    version: "32",
    /* You may change the page of zero for testing purposes */
    currentPage: initializeWorkflow(SURVEY_WORKFLOW_PAGE_INDICES.keyData),
};

export default workflow;
