import React, { Component } from "react";
import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import validator from "validator";
import PersonalOffer from "../../../new/PersonalOffer";
import { PageProps } from "../types";
import CallScheduler from "../../../new/CallScheduler";
import { Answer } from "../../../new/common/Answers";
export type TopOfferPageProps = PageProps;

type DispatchTypes = {
    notifyAboutUser?: (callNow: boolean, newPhoneNumber: string | null) => void;
};

export class TopOfferPage extends Component<TopOfferPageProps & DispatchTypes> {
    render() {
        const { offer, isError, isLoading, errorMessage } = this.props.topOffer;

        const loanAmountStructure = this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.loanAmount].pageStructure;

        const email = this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.email].pageStructure.EMAIL.attributes.value;

        const intention =
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.INTENTION.attributes.value;

        const progress =
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.PROGRESS.attributes.value;

        const isOther =
            validator.equals(intention, Answer.INTENTION.OTHER) ||
            (progress && validator.equals(progress, Answer.PROGRESS.RESEARCH));

        return (
            <div>
                <PersonalOffer
                    /* Convenient Current page data */
                    page={this.props.page}
                    loanAmountStructure={loanAmountStructure}
                    isLoading={isLoading}
                    isError={isError}
                    errorMessage={errorMessage}
                    data={offer}
                    user={this.props.user}
                    email={email}
                />

                <CallScheduler
                    showConfirmation={false}
                    verboseConfirmation={false}
                    question={null}
                    email={email}
                    notifyAboutUser={this.props.notifyAboutUser}
                    data={null}
                    user={this.props.user}
                    isOther={isOther}
                    isGoodRate={true}
                />
            </div>
        );
    }
}

export default TopOfferPage;
