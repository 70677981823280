import pkg from "../../package.json";

const ApplicationServer = {
    stage: process.env.REACT_APP_STAGE as "development" | "production" | "test" | undefined,
    encryption: process.env.REACT_APP_SERVER_ENCRYPTION,
    host: process.env.REACT_APP_SERVER_HOST,
};

const translationI18n = {
    debug: ApplicationServer.stage === "development",
    fallbackLng: "de",
};

const urls = {
    index: "/",
    termsOfUse: "https://miracl.at/de/agb/",
    privacyPolicy: "https://miracl.at/de/datenschutzerklaerung/",
    hub: {
        index: "/hub/",
        creditConditionsComparison: "/hub/conditions/",
        affordabilityCalculator: "/hub/affordability/",
        financingTimeline: "/hub/timeline/",
        login: "/login/",
        loginMagicLinkSent: "/login/magic/sent",
        register: "/register/",
    },
    survey: {
        index: "/survey/",
        confirmation: "/confirmation/",
    },
};

const AppConfig = {
    sentry: ApplicationServer.stage !== "development",
    surveyAppVersion: pkg.version,
    isProductionStage: ApplicationServer.stage === "production",
    // Yes, development config is the localhost config
    isLocalHost: ApplicationServer.stage === "development",
    GTM: {
        gtmId: process.env.REACT_APP_GTM_ID,
    },
    optimize: {
        experimentId: process.env.REACT_APP_OPTIMIZE_EXPERIMENT_ID,
    },
    urls: urls,
    translationI18n,
    ApplicationServer,
    applicationHost: `${ApplicationServer.encryption}://${ApplicationServer.host}`,
    CTA: {
        personalData: "Zum Bestangebot",
    },
};

export default AppConfig;
