import styled from "styled-components";
import AppConfig from "../../../../../config/AppConfig";
import { Callback } from "../../../../../redux/types";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { bpSurvey } from "../../../../styles/constants/breakpoints";

export type HubHeaderComponentProps = {
    showLogo?: boolean;
    logout: (callback: Callback) => void;
};

const HubHeaderComponent = ({ showLogo = true, logout }: HubHeaderComponentProps): React.ReactElement | null => {
    return (
        <Wrapper>
            <Inner>
                <Main>
                    {showLogo && (
                        <Logo href={AppConfig.urls.hub.index} title="dashboard | miracl.at">
                            <img src="/assets/miracl-logo.svg" height="24" width="76" alt="miracl" />
                        </Logo>
                    )}
                    <Empty />
                    <a
                        // TODO: TS - should replace with button if no href
                        onClick={() => {
                            logout(() => {
                                GtmServiceInstance.track({
                                    event: "logoutSuccessful",
                                });
                            });
                        }}
                    >
                        Abmelden
                    </a>
                </Main>
            </Inner>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    align-items: center;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const Inner = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;

    @media (min-width: ${bpSurvey.mediumMin}) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 440px;
        padding-left: 0;
        padding-right: 0;
    }
`;

const Main = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const Empty = styled.div`
    width: 40px;
`;

const Logo = styled.a``;

export default HubHeaderComponent;
