import ReactDOM from "react-dom";
import App from "./app/app";
import "./config/sentry";
import "./i18n";
import Providers from "./providers";
import GtmServiceInstance from "./service/gtm/gtm.service";
import * as serviceWorker from "./serviceWorker";

GtmServiceInstance.appStart();

// Activate GTM after if not loaded
GtmServiceInstance.track({ event: "optimize.activate" });

ReactDOM.render(
    //<React.StrictMode>
    <Providers>
        <App />
    </Providers>,
    //</React.StrictMode>
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
