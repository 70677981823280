import { Component } from "react";
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";
import styled from "styled-components";
import validator from "validator";
import {
    DEFAULT_FOLLOW_UP_LINK,
    DEFAULT_MEETINGS_LINK,
    downloadFileById,
} from "../../../../../redux/actions/hub.actions";
import { RootState, ThunkBaseDispatch } from "../../../../../redux/store";
import { Callback } from "../../../../../redux/types";
import { PageLoading } from "../../../../styles/base";
import { colors } from "../../../../styles/constants/colors";
import AdvisorAvatar from "../hub/financingAdvisorProfile.component";
import DocumentUploadList from "./documentUploadList.component";
import FinancingStep from "./financingStep.component";

const FINANCING_TIMELINE_INDEX = {
    realEstateFound: {
        key: "realEstateFound",
        icon: "/assets/svg/home-search.svg",
        titleOpen: "Finde deine Immobilie",
        titleDone: "Immobilie gefunden",
        headButtonText: null,
        items: null,
    },
    firstAppointment: {
        key: "firstAppointment",
        icon: "/assets/svg/expert.svg",
        titleOpen: "Erstes Beratungsgespräch",
        titleDone: "Erstes Beratungsgespräch abgeschlossen ",
        headButtonText: "Termin",
    },
    offerOverview: {
        key: "offerOverview",
        icon: "/assets/svg/bank-offers.svg",
        titleOpen: "Angebotsübersicht",
        titleDone: "Angebotsübersicht erhalten",
        headButtonText: "Öffnen",
    },
    uploadDocument: {
        key: "uploadDocument",
        icon: "/assets/svg/documents.svg",
        titleOpen: "Lade deine Dokumente hoch",
        titleDone: "Dokumente hochgeladen",
        headButtonText: false,
        items: ["Lohnzettel", "Meldezettel", "Reisepass", "Einkommensnachweis"],
    },
    offerSentToBank: {
        key: "offerSentToBank",
        icon: "/assets/svg/contract.svg",
        titleDone: "Angebot von der Bank wurde eingeholt und mit dir besprochen.",
        titleOpen: "Angebot von der Bank wird eingeholt",
        headButtonText: null,
        items: ["Erste Vorprüfung der Bank", "Dauer max. 7 Tage"],
    },
    offerDiscussion: {
        key: "offerDiscussion",
        icon: "/assets/svg/expert.svg",
        titleOpen: "Angebotseinholung",
        titleDone: "Bankangebot erhalten",
        headButtonText: null,
        items: null,
    },
    bankApproval: {
        key: "bankApproval",
        icon: "/assets/svg/bank.svg",
        titleOpen: "Bestätigung des Angebots durch die Bank",
        titleDone: "Bankbestätigung erhalten",
        headButtonText: null,
        items: null,
    },
    legal: {
        key: "legal",
        icon: "/assets/svg/expert.svg",
        titleOpen: "Vertragsbesprechung mit Spezialist:in",
        titleDone: "Vertragsbesprechung abgeschlossen",
        headButtonText: null,
        items: ["Besprechung der Vertragsdetails", "Erklärung des ESIS"],
    },
    mortgageAgreement: {
        key: "mortgageAgreement",
        icon: "/assets/svg/contract.svg",
        titleOpen: "Kreditvertrag unterzeichnen",
        titleDone: "Kreditvertrag unterzeichnet",
        headButtonText: null,
        items: ["Kreditvertrag unterzeichnet"],
    },
};

export class FinancingTimelineBase extends Component<
    FinancingTimelineBaseStateProps & FinancingTimelineBaseDispatchProps
> {
    renderTimelineSubItems = (items: (string | number)[]) => {
        if (items) {
            return (
                <ul>
                    {items.map((item, idx) => (
                        <li key={idx}>{item}</li>
                    ))}
                </ul>
            );
        }

        return null;
    };

    renderBookingAppoint(props) {
        return (
            <AdvisorAvatar
                meetingLink={props.meetingLink || DEFAULT_FOLLOW_UP_LINK}
                meetingMeta={props.meetingMeta}
                isLoadingMeetingLink={props.isLoadingMeetingLink}
                isAppointmentAvailable={false}
                isAdvisorAssigned={DEFAULT_MEETINGS_LINK !== props.meetingLink}
                titleWithAppointment={"Dein persönlicher Finanzierungsexperte"}
                titleWithoutAppointment={"Du hast noch Fragen an unseren Finanzierungsexperten?"}
                mainText={"Wir beraten dich weiter rund um deine Immobilienfinanzierung!"}
                minimal={props.minimal}
            />
        );
    }

    renderTimeline = () => {
        if (this.props.timeline == null && !this.props.isLoading) {
            return this.renderBookingAppoint(this.props);
        }

        if (Array.isArray(this.props.timeline)) {
            return this.props.timeline.map((entry, idx) => {
                const {
                    isDone,
                    isOpenable,
                    stage,
                    index,
                    titleDate = null,
                    link = null,
                    calendarLink,
                    videoLink,
                    date,
                    isActive,
                    creditOfferId,
                } = entry;

                const headButtonText = validator.equals(stage, FINANCING_TIMELINE_INDEX.firstAppointment.key)
                    ? titleDate
                        ? "Ändern"
                        : "Buchen"
                    : FINANCING_TIMELINE_INDEX[stage].headButtonText;

                return (
                    <FinancingStep
                        isActive={isActive}
                        key={idx}
                        index={index + 1}
                        icon={FINANCING_TIMELINE_INDEX[stage].icon}
                        title={
                            isDone
                                ? FINANCING_TIMELINE_INDEX[stage].titleDone
                                : FINANCING_TIMELINE_INDEX[stage].titleOpen
                        }
                        isDone={isDone}
                        isOpenable={isOpenable && FINANCING_TIMELINE_INDEX[stage].items}
                        calendarLink={calendarLink}
                        titleDate={date}
                        videoLink={videoLink}
                        headButtonText={link || titleDate || creditOfferId ? headButtonText : null}
                        headButtonLink={link}
                        creditOfferId={creditOfferId}
                        getCreditOfferDocument={this.props.getCreditOfferDocument || null}
                        isLoadingDocument={this.props.isLoadingDocument}
                    >
                        <>
                            {stage === "uploadDocument"
                                ? this.renderUpload()
                                : this.renderTimelineSubItems(FINANCING_TIMELINE_INDEX[stage].items)}
                            {stage === "firstAppointment"
                                ? this.renderBookingAppoint({ ...this.props, minimal: true })
                                : null}
                        </>
                    </FinancingStep>
                );
            });
        }

        return null;
    };

    renderUpload() {
        return <DocumentUploadList token={this.props.sessionToken} dealId={this.props.dealId} />;
    }

    render() {
        return (
            <>
                {this.props.isLoading && (
                    <PageLoading>
                        <div>Lädt...</div>
                        <Oval wrapperClass="loader" color="#aaa" secondaryColor="#aaa" height={76} width={76} />
                    </PageLoading>
                )}
                {this.props.error && (
                    <ErrorWrapper>
                        <strong>Ein Fehler ist aufgetreten.</strong>
                        <br />
                        Versuche später noch einmal, oder <a href="https://miracl.at/kontakt/">Kontaktiere</a> uns.
                        <br />
                        {this.props.errorMessage}
                    </ErrorWrapper>
                )}
                <div>{this.renderTimeline()}</div>
            </>
        );
    }
}

const ErrorWrapper = styled.div`
    border: 1px solid ${colors.primary};
    border-radius: 4px;
    font-size: 13px;
    line-height: 1.5;
    margin: 0 0 30px;
    padding: 15px 10px;
`;

const mapStateToProps = (state: RootState) => {
    return {
        timeline: state.hub.financing.timeline,
        error: state.hub.financing.isError,
        errorMessage: state.hub.financing.errorMessage,
        isLoading: state.hub.financing.isLoading,
        isLoadingDocument: state.hub.fileDownload.isLoading,
        isDocumentError: state.hub.fileDownload.isError,
        sessionToken: state.session.token,
        dealId: state.hub.financing.dealId,
    };
};
type FinancingTimelineBaseStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        getCreditOfferDocument: (id: string, _cb?: Callback) => dispatch(downloadFileById(id, _cb)),
    };
};
type FinancingTimelineBaseDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FinancingTimelineBase);
