import parse from "html-react-parser";
import { Component, MouseEventHandler } from "react";
import styled from "styled-components";

import { H2 } from "../../../../styles/base";
import { bpSurvey } from "../../../../styles/constants/breakpoints";

export type DocumentUploadModalProps = {
    title: React.ReactNode;
    handleModalClose: MouseEventHandler;
};

export class DocumentUploadModal extends Component<DocumentUploadModalProps> {
    render() {
        return (
            <Wrapper onClick={this.props.handleModalClose}>
                <ModalWrapper onClick={(e) => e.stopPropagation()}>
                    <Header>
                        {this.props.title}
                        <CloseBtn onClick={this.props.handleModalClose}>&#x2715;</CloseBtn>
                    </Header>
                    {parse(String(this.props.children))}
                </ModalWrapper>
            </Wrapper>
        );
    }
}

const modalPadding = "60px";

const Wrapper = styled.div`
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 110;

    transition: opacity ease-out 300ms;

    > div {
        opacity: 1;
        margin-bottom: ${modalPadding};
        margin-top: ${modalPadding};
        transition: margin-top ease-out 300ms;
    }
    &.enter {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
    &.enter-active {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit-active {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
`;

const ModalWrapper = styled.div`
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.6;
    max-width: 400px;
    padding: 22px 18px;
    width: 90%;

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 440px;
        padding: 30px 22px;
    }
`;

const Header = styled(H2)`
    display: flex;
    font-size: 16px;
    margin-bottom: 15px;
    text-align: left;
`;

const CloseBtn = styled.span`
    color: #9ca3af;
    cursor: pointer;
    font-size: 1em;
    margin-left: auto;
`;

export default DocumentUploadModal;
