import * as Sentry from "@sentry/react";
import { FinancingObject } from "../../models/Engine";
import { getCreditConditions, getGuessedCreditAffordabilityRequest, getTopOfferRequest } from "../../service/api";
import type { EngineState, INTEREST_TYPES_Value } from "../reducers/engine.reducer";
import type { ThunkBaseAction } from "../store";
import { Callback } from "../types";

export const SET_CREDIT_CONDITIONS = "SET_CREDIT_CONDITIONS";

export const SET_FILTERED_CREDIT_CONDITIONS = "SET_FILTERED_CREDIT_CONDITIONS";

export const ERROR_CREDIT_CONDITIONS = "ERROR_CREDIT_CONDITIONS";

export const LOADING_CREDIT_CONDITIONS = "LOADING_CREDIT_CONDITIONS";

export const SET_RUNTIME_CREDIT_CONDITIONS = "SET_RUNTIME_CREDIT_CONDITIONS";

export const SET_INCOME_CREDIT_CONDITIONS = "SET_INCOME_CREDIT_CONDITIONS";

export const SET_OWN_FUNDING_CREDIT_CONDITIONS = "SET_OWN_FUNDING_CREDIT_CONDITIONS";

export const SET_CREDIT_AMOUNT_CREDIT_CONDITIONS = "SET_CREDIT_AMOUNT_CREDIT_CONDITIONS";

export const SET_INTEREST_TYPE_CREDIT_CONDITIONS = "SET_INTEREST_TYPE_CREDIT_CONDITIONS";

export const SET_INCOME_AFFORDABILITY = "SET_INCOME_AFFORDABILITY";

export const SET_MONTHLY_EXPENSE_AFFORDABILITY = "SET_MONTHLY_EXPENSE_AFFORDABILITY";

export const SET_OWN_FUNDING_AFFORDABILITY = "SET_OWN_FUNDING_AFFORDABILITY";

export const SET_GUESSED_CREDIT_AMOUNT_AFFORDABILITY = "SET_GUESSED_CREDIT_AMOUNT_AFFORDABILITY";

export const SET_ERROR_AFFORDABILITY = "SET_ERROR_AFFORDABILITY";

export const SET_LOADING_AFFORDABILITY = "SET_LOADING_AFFORDABILITY";

export const SET_TOP_OFFER = "SET_TOP_OFFER";

export const SET_LOADING_TOP_OFFER = "SET_LOADING_TOP_OFFER";

export const SET_IS_ERROR_TOP_OFFER = "SET_IS_ERROR_TOP_OFFER";

export function setTopOffer(offer: EngineState["topOffer"]) {
    return {
        type: SET_TOP_OFFER,
        offer,
    } as const;
}
export type SetTopOffer = ReturnType<typeof setTopOffer>;

export function setIsLoadingTopOffer(isLoading: boolean) {
    return {
        type: SET_LOADING_TOP_OFFER,
        isLoading,
    } as const;
}
export type SetIsLoadingTopOffer = ReturnType<typeof setIsLoadingTopOffer>;

export function setIsErrorTopOffer(isError: boolean, errorMessage: string) {
    return {
        type: SET_IS_ERROR_TOP_OFFER,
        isError,
        errorMessage,
    } as const;
}
export type SetIsErrorTopOffer = ReturnType<typeof setIsErrorTopOffer>;

export function setMonthlyExpenseAffordability(monthlyExpense: number) {
    return {
        type: SET_MONTHLY_EXPENSE_AFFORDABILITY,
        monthlyExpense,
    } as const;
}
export type SetMonthlyExpenseAffordability = ReturnType<typeof setMonthlyExpenseAffordability>;

// TODO: TS - properly define types
export function setGuessedCreditAmountAffordability(guessedCreditAmount: number) {
    return {
        type: SET_GUESSED_CREDIT_AMOUNT_AFFORDABILITY,
        guessedCreditAmount,
    } as const;
}
export type SetGuessedCreditAmountAffordability = ReturnType<typeof setGuessedCreditAmountAffordability>;

export function setOwnFundingAffordability(ownFunding: number) {
    return {
        type: SET_OWN_FUNDING_AFFORDABILITY,
        ownFunding,
    } as const;
}
export type SetOwnFundingAffordability = ReturnType<typeof setOwnFundingAffordability>;

export function setIncomeAffordability(income: number) {
    return {
        type: SET_INCOME_AFFORDABILITY,
        income,
    } as const;
}
export type SetIncomeAffordability = ReturnType<typeof setIncomeAffordability>;

export function setLoadingAffordability(isLoading: boolean) {
    return {
        type: SET_LOADING_AFFORDABILITY,
        isLoading,
    } as const;
}
export type SetLoadingAffordability = ReturnType<typeof setLoadingAffordability>;

export function setErrorAffordability(isError: boolean, errorMessage: string) {
    return {
        type: SET_ERROR_AFFORDABILITY,
        errorMessage,
        isError,
    } as const;
}
export type SetErrorAffordability = ReturnType<typeof setErrorAffordability>;

export function setLoadingCreditConditions(isLoading: boolean) {
    return {
        type: LOADING_CREDIT_CONDITIONS,
        isLoading,
    } as const;
}
export type SetLoadingCreditConditions = ReturnType<typeof setLoadingCreditConditions>;

export function setErrorCreditConditions(isError: boolean, errorMessage: string) {
    return {
        type: ERROR_CREDIT_CONDITIONS,
        errorMessage,
        isError,
    } as const;
}
export type SetErrorCreditConditions = ReturnType<typeof setErrorCreditConditions>;

export function setCreditConditions(data: EngineState["conditions"]["data"]) {
    return {
        type: SET_CREDIT_CONDITIONS,
        data,
    } as const;
}
export type SetCreditConditions = ReturnType<typeof setCreditConditions>;

export function setFilteredCreditConditions(filtered: EngineState["conditions"]["filtered"]) {
    return {
        type: SET_FILTERED_CREDIT_CONDITIONS,
        filtered,
    } as const;
}
export type SetFilteredCreditConditions = ReturnType<typeof setFilteredCreditConditions>;

export function setRuntimeConditions(runtime: number) {
    return {
        type: SET_RUNTIME_CREDIT_CONDITIONS,
        runtime,
    } as const;
}
export type SetRuntimeConditions = ReturnType<typeof setRuntimeConditions>;

export function setIncomeConditions(income: number) {
    return {
        type: SET_INCOME_CREDIT_CONDITIONS,
        income,
    } as const;
}
export type SetIncomeConditions = ReturnType<typeof setIncomeConditions>;

export function setOwnFundingConditions(ownFunding: number) {
    return {
        type: SET_OWN_FUNDING_CREDIT_CONDITIONS,
        ownFunding,
    } as const;
}
export type SetOwnFundingConditions = ReturnType<typeof setOwnFundingConditions>;

export function setCreditAmountConditions(creditAmount: number) {
    return {
        type: SET_CREDIT_AMOUNT_CREDIT_CONDITIONS,
        creditAmount,
    } as const;
}
export type SetCreditAmountConditions = ReturnType<typeof setCreditAmountConditions>;

export function setInterestTypeConditions(interestType: INTEREST_TYPES_Value) {
    return {
        type: SET_INTEREST_TYPE_CREDIT_CONDITIONS,
        interestType,
    } as const;
}
export type SetInterestTypeConditions = ReturnType<typeof setInterestTypeConditions>;

export type EngineActions =
    | SetTopOffer
    | SetIsErrorTopOffer
    | SetIsLoadingTopOffer
    | SetMonthlyExpenseAffordability
    | SetLoadingAffordability
    | SetIncomeAffordability
    | SetOwnFundingAffordability
    | SetGuessedCreditAmountAffordability
    | SetErrorAffordability
    | SetLoadingCreditConditions
    | SetErrorCreditConditions
    | SetCreditConditions
    | SetFilteredCreditConditions
    | SetRuntimeConditions
    | SetIncomeConditions
    | SetOwnFundingConditions
    | SetCreditAmountConditions
    | SetInterestTypeConditions;

// TODO: TS - properly define types
export const getTopOffer =
    (financingObject: FinancingObject, _callback: Callback): ThunkBaseAction =>
    (dispatch) => {
        dispatch(setIsLoadingTopOffer(true));

        Sentry.setContext("TopOfferRequest", {
            ...financingObject,
        });

        getTopOfferRequest(financingObject)
            .then((response) => {
                let topOffer = null;

                if (response.status === 204) {
                    const error =
                        "We could not generate an offer for the user. See TopOfferRequest section for passed arguments.";

                    Sentry.captureException(error);
                } else {
                    topOffer = response.data;
                }

                dispatch(setTopOffer(topOffer));

                dispatch(setIsErrorTopOffer(false, null));

                dispatch(setIsLoadingTopOffer(false));
            })
            .catch((error) => {
                const errorMessage = `Failed to fetch top offer. ${error.message}.`;

                Sentry.captureException(error);

                dispatch(setTopOffer(null));

                dispatch(setIsErrorTopOffer(true, errorMessage));

                dispatch(setIsLoadingTopOffer(false));
            });
    };

// TODO: TS - properly define types
export const getGuessedCreditAffordability =
    (financingObject: FinancingObject): ThunkBaseAction =>
    (dispatch) => {
        dispatch(setLoadingAffordability(true));

        getGuessedCreditAffordabilityRequest(financingObject)
            .then((response) => {
                const { guessedCreditAmount } = response.data;

                dispatch(setGuessedCreditAmountAffordability(guessedCreditAmount));

                dispatch(setErrorAffordability(false, null));

                dispatch(setLoadingAffordability(false));
            })
            .catch((error) => {
                dispatch(setGuessedCreditAmountAffordability(null));

                const errorMessage = `Failed to fetch credit affordability. ${error.message}.`;

                Sentry.captureException(error);

                dispatch(setErrorAffordability(true, errorMessage));

                dispatch(setLoadingAffordability(false));
            });
    };

export const getCreditConditionsComparisonWithLoadingTimeOut =
    (_financingObject, _minLoadingTimeoutInMs = 1500) =>
    (dispatch) => {
        dispatch(setLoadingCreditConditions(true));

        const startCreditConditionsComparisonRequestTimestamp = Date.now();

        getCreditConditions(_financingObject)
            .then((result) => {
                if (result && result.data) {
                    const { conditions, fixedYearsFilteredSortedByDownPaymentConditions } = result.data;

                    dispatch(setCreditConditions(conditions));

                    dispatch(setFilteredCreditConditions(fixedYearsFilteredSortedByDownPaymentConditions));

                    dispatch(setErrorCreditConditions(false, null));

                    const endCreditConditionsComparisonRequestTimestamp = Date.now();

                    const requestLoadingTime =
                        endCreditConditionsComparisonRequestTimestamp - startCreditConditionsComparisonRequestTimestamp;

                    const loadingTimeLeft = _minLoadingTimeoutInMs - requestLoadingTime;

                    setTimeout(() => dispatch(setLoadingCreditConditions(false)), loadingTimeLeft);
                } else {
                    throw new Error("Failed to parse conditions from data result object.");
                }
            })
            .catch((error) => {
                const errorMessage = `Failed to fetch credit conditions. ${error.message}.`;

                Sentry.captureException(error);

                dispatch(setErrorCreditConditions(true, errorMessage));

                dispatch(setCreditConditions(null));

                dispatch(setLoadingCreditConditions(false));
            });
    };
