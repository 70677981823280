import { Component } from "react";
import styled, { css } from "styled-components";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { colors } from "../../../../styles/constants/colors";
import ErrorStackedLabels from "./ErrorStackedLabels";
import GamificationTooltip from "./GamificationTooltip";
import InfoLabel from "./InfoLabel";
import InfoTooltip from "./InfoTooltip";

export type QuestionItemProps = {
    label?: string | null;
    tooltip?: string;
    pageName?: string;
    labelAbove?: React.ReactNode;
    helperText?: React.ReactNode;
    gamification?: string;
    tooltipAlignBottom?: boolean;
    required?: boolean;
    wrapperWidth?: string | number;
    infoMarginTop?: string | number;
    errors?: React.ReactNode[];
};
export type QuestionItemState = {
    isInfoOpen: boolean;
};

export class QuestionItem extends Component<QuestionItemProps, QuestionItemState> {
    state = {
        isInfoOpen: false,
    };

    render() {
        const handleToggle = () => {
            if (!this.state.isInfoOpen) {
                GtmServiceInstance.track({
                    event: "newSurvey.openInfo",
                    title: this.props.label,
                    step: this.props.pageName || "unknown",
                });
            }

            this.setState((prevState) => ({
                isInfoOpen: !prevState.isInfoOpen,
            }));
        };

        const handleClose = () => {
            this.setState(() => ({
                isInfoOpen: false,
            }));
        };

        return (
            <Wrapper>
                {this.props.tooltip && (
                    <InfoTooltip
                        alignBottom={this.props.tooltipAlignBottom}
                        isOpen={this.state.isInfoOpen}
                        handleClose={handleClose}
                    >
                        {this.props.tooltip}
                    </InfoTooltip>
                )}
                {this.props.labelAbove && (
                    <LabelAbove>
                        {this.props.labelAbove}
                        {this.props.required && <span className="required">&nbsp;*</span>}
                    </LabelAbove>
                )}
                <Row>
                    <InputWrapper width={this.props.wrapperWidth}>{this.props.children}</InputWrapper>
                    <InfoBtnWrapper infoMarginTop={this.props.infoMarginTop}>
                        {this.props.tooltip && (
                            <InfoBtn onClick={handleToggle}>
                                <img src="/assets/svg/infoNew.svg" width="22" height="22" alt="Information" />
                            </InfoBtn>
                        )}
                    </InfoBtnWrapper>
                </Row>
                <ErrorStackedLabels errors={this.props.errors} />
                {this.props.helperText && <InfoLabel text={this.props.helperText}></InfoLabel>}
                {this.props.gamification && <GamificationTooltip text={this.props.gamification} />}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 25px 0;
    position: relative;

    .required {
        color: ${colors.red500};
        font-size: 14px;
        line-height: 0.9;
    }
`;

const Row = styled.div`
    display: flex;
    padding-bottom: 10px;
`;

const LabelAbove = styled.div`
    color: ${colors.gray500};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
`;

const InputWrapper = styled.div`
    position: relative;
    width: ${({ width }) => (width ? width : "auto")};

    label {
        align-items: center;
        display: flex;
    }
    .label {
        color: ${colors.gray500};
        font-size: 10px;
        font-weight: 400;
        left: 15px;

        position: absolute;
        top: 9px;
    }
`;

const InfoBtnWrapper = styled.div`
    align-items: center;
    display: flex;
    padding-left: 10px;
    width: 32px;

    ${({ infoMarginTop }) =>
        infoMarginTop &&
        css`
            align-items: flex-start;
            margin-top: ${infoMarginTop};
        `};
`;

const InfoBtn = styled.div`
    cursor: pointer;
    padding: 2px;

    img {
        display: block;
    }
`;

export default QuestionItem;
