import { Component, MouseEventHandler } from "react";
import styled from "styled-components";
import { DEFAULT_MEETINGS_LINK } from "../../../../../redux/actions/hub.actions";
import { HubState } from "../../../../../redux/reducers/hub.reducer";
import { colors } from "../../../../styles/constants/colors";
import AdvisorAvatar from "../hub/financingAdvisorProfile.component";

// TODO: TS - properly define types
export type GetInContactProps = {
    aboveTitle?: React.ReactNode;
    chosenOfferData?: boolean;
    handleBackBtnClick?: MouseEventHandler<HTMLButtonElement>;
    mainText: React.ReactNode;
    meetingData: HubState["meeting"];
    meetingLink?: string;
    titleWithAppointment: React.ReactNode;
    titleWithoutAppointment: React.ReactNode;
};

export class GetInContact extends Component<GetInContactProps> {
    render() {
        const { meetingData } = this.props;
        return (
            <>
                {this.props.aboveTitle && <Title>{this.props.aboveTitle}</Title>}
                <AdvisorAvatar
                    meetingLink={meetingData.link}
                    meetingMeta={meetingData.meta}
                    isLoadingMeetingLink={false}
                    isAppointmentAvailable={false}
                    isAdvisorAssigned={DEFAULT_MEETINGS_LINK !== this.props.meetingLink}
                    titleWithAppointment={this.props.titleWithAppointment}
                    titleWithoutAppointment={this.props.titleWithoutAppointment}
                    mainText={this.props.mainText}
                ></AdvisorAvatar>

                {this.props.handleBackBtnClick && (
                    <BackBtn onClick={this.props.handleBackBtnClick}>
                        <img src={"/assets/svg/Icons_1farbe/Miracl_icons_arrow_left Kopie.svg"} alt="" height={30} />
                        Zurück zum Kreditvergleich
                    </BackBtn>
                )}
            </>
        );
    }
}

const Title = styled.p`
    color: #000;
    display: block;
    font-size: 22px;
    font-weight: lighter;
    letter-spacing: 0px;
    margin-top: 0px;
    line-height: normal;
`;

const BackBtn = styled.div`
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
    color: ${colors.primary};
    display: flex;
    margin: 25px 0;
`;

export default GetInContact;
