import { Component, MouseEventHandler } from "react";
import { Oval } from "react-loader-spinner";
import { NextButton } from "../../../styles/base";

export type NavigationalNextButtonProps = {
    disabled?: boolean;
    onClick: MouseEventHandler;
    show?: boolean;
    isLoading?: boolean;
    loadingText?: string;
    text?: string;
    margin?: number | string;
};

export class NavigationalNextButton extends Component<NavigationalNextButtonProps> {
    render() {
        if (this.props.show) {
            return (
                <NextButton
                    onClick={this.props.onClick}
                    disabled={this.props.disabled || this.props.isLoading}
                    margin={this.props.margin}
                >
                    {this.props.isLoading ? (
                        <div>
                            {this.props.loadingText}
                            <Oval wrapperClass="loader" color="#fff" secondaryColor="#fff" height={24} width={24} />
                        </div>
                    ) : this.props.text ? (
                        this.props.text
                    ) : (
                        "Weiter"
                    )}
                </NextButton>
            );
        }

        return null;
    }

    static defaultProps = { show: true, isLoading: false, loadingText: "Lädt..." };
}

export default NavigationalNextButton;
