import { Component } from "react";
import styled from "styled-components";
import validator from "validator";
import { ConditionsNumberInput } from "../../../../../styles/base";

const ENTER_KEY = "Enter";

export type NumberItemProps = {
    type?: string;
    label?: string;
    onChange?: (minValue: number) => void;
    value?: number;
    minValue?: number;
    width?: string | number;
};

export class NumberItem extends Component<NumberItemProps> {
    state = {
        inputValue: this.props.value ?? 350000,
        currentValue: this.props.value ?? 35000,
    };

    submitNewValue() {
        const minValue = this.props.minValue ?? 0;

        if (this.state.inputValue !== this.state.currentValue) {
            if (this.state.inputValue >= minValue) {
                this.setState({ currentValue: this.state.inputValue });
                this.props.onChange(this.state.inputValue);
            } else {
                this.setState({ currentValue: minValue, inputValue: minValue });
                this.props.onChange(minValue);
            }
        }
    }

    render() {
        return (
            <InputWrapper width={this.props.width}>
                <Label>{this.props.label || ""}</Label>
                <ConditionsNumberInput
                    allowNegative={false}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    pattern="[0-9]*"
                    suffix={" €"}
                    onBlur={() => this.submitNewValue()}
                    onKeyDown={(e) => {
                        if (validator.equals(e.key, ENTER_KEY) && this.props.value !== undefined) {
                            this.submitNewValue();
                        }
                    }}
                    type="text"
                    placeholder="Kreditsumme"
                    onValueChange={(value) => {
                        this.setState({ inputValue: value.floatValue >= 0 ? value.floatValue : 0 });
                        // clearTimeout(changeTimeout);
                        // changeTimeout = setTimeout(
                        //     () => {this.setState({ inputValue: (value.floatValue >= this.props.minValue ? value.floatValue : this.props.minValue).toString() })},
                        //     1000
                        // );
                    }}
                    value={this.state.inputValue}
                />
            </InputWrapper>
        );
    }

    static defaultProps = { label: "", onChange: null, value: 350000, minValue: 0 };
}

const InputWrapper = styled.label`
    width: ${({ width }) => (width ? `${width}%` : "auto")};
`;

const Label = styled.div`
    color: #000;
    font-size: 11px;
    margin: 0 0 2px 2px;
`;

export default NumberItem;
