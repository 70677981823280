import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import { SurveyNewWrapper } from "../../../../styles/newStyle";

export type BankLogosProps = {
    bg?: string;
};

export class BankLogos extends Component<BankLogosProps> {
    render() {
        return (
            <Wrapper bg={this.props.bg}>
                <StyledSurveyWrapper bg={this.props.bg}>
                    <Banks>
                        <li>
                            <img src="/assets/banks/bank.bausparkasse.svg" height="28" alt="Bausparkasse" />
                        </li>
                        <li className="bawag">
                            <img src="/assets/banks/bank.bawag.svg" height="36" alt="Bawag" />
                        </li>
                        <li>
                            <img src="/assets/banks/bank.wuestenrot.svg" height="28" alt="Wüstenrot" />
                        </li>
                        <li>
                            <img src="/assets/banks/bank.austria.svg" height="29" alt="Bank Austria" />
                        </li>
                    </Banks>
                    <Title>Wir vergleichen für dich über 6500 Kredite und finden die besten Konditionen für dich</Title>
                </StyledSurveyWrapper>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    background: ${({ bg }) => bg || "#eeeef0"};
    padding: 50px 0 30px;
`;

const StyledSurveyWrapper = styled(SurveyNewWrapper)`
    overflow: hidden;
    padding: 10px 0 0;
    position: relative;

    &:after,
    &:before {
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        width: 30px;
        z-index: 1;
    }
    &:after {
        background: linear-gradient(90deg, rgba(238, 238, 240, 0) 0%, ${({ bg }) => bg || "#eeeef0"} 100%);
        right: 0;
    }
    &:before {
        background: linear-gradient(270deg, rgba(238, 238, 240, 0) 0%, ${({ bg }) => bg || "#eeeef0"} 100%);
        left: 0;
    }
`;

const Title = styled.div`
    color: ${colors.gray500};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 42px 24px 0;
    text-align: center;
`;

const Banks = styled.ul`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    list-style: none;
    flex-wrap: nowrap;
    height: 30px;
    margin: ;
    padding: 0;
    width: 100%;

    li {
        margin: 0 6px;

        &.bawag img {
            margin-top: -10px;
        }
    }

    img {
        display: block;
        opacity: 0.72;
    }
`;

export default BankLogos;
