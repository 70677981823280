import { History } from "history";
import { Redirect, useLocation } from "react-router-dom";

export type ProtectedRouteProps = {
    children?: React.ReactNode;
    sessionToken?: string;
    isValidatingSessionToken?: boolean;
    history?: History;
};

const ProtectedRoute = ({
    children,
    sessionToken,
    isValidatingSessionToken,
}: ProtectedRouteProps): React.ReactElement | null => {
    const location = useLocation();
    if (isValidatingSessionToken) {
        return null;
    }

    const isAuthenticated = sessionToken != null;
    if (isAuthenticated) {
        return <>{children}</>;
    }

    // redirect to login
    return (
        <Redirect
            to={{
                pathname: "/login/",
                state: { referrer: location },
            }}
        />
    );
};

export default ProtectedRoute;
