export const breakpoints = {
    mobileMax: "460px",
    tabletMin: "461px",
    mobileBigMax: "720px",
    tabletBigMin: "721px",
    tabletMax: "1150px",
    desktopMin: "1151px",
    desktopBigMin: "1420px",
};

export const bpSurvey = {
    smallMax: "720px",
    mediumMin: "721px",
    mediumMax: "1150px",
    largeMin: "1151px",
};

export const breakpointsNew = {
    smallMax: "720px",
    mediumMin: "721px",
    mediumMax: "1150px",
    largeMin: "1151px",
};
