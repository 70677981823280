import { Component } from "react";
import ClickAwayListener from "react-click-away-listener";
import styled from "styled-components";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { breakpointsNew } from "../../../../styles/constants/breakpoints";
import { colors } from "../../../../styles/constants/colors";
import ContactCard from "./ContactCard";

type HeaderState = {
    isContactOpen?: boolean;
};

export class Header extends Component<unknown, HeaderState> {
    state = {
        isContactOpen: false,
    };

    render() {
        const handleToggle = (e) => {
            e.preventDefault();
            e.stopPropagation();

            this.setState((prevState) => ({
                isContactOpen: !prevState.isContactOpen,
            }));

            GtmServiceInstance.track({
                event: "header.avatarClick",
            });
        };

        return (
            <Wrapper id="Header">
                <Logo href="https://miracl.at/" title="miracl.at">
                    <img id="logo" src="/assets/svg/miraclBlue.svg" height="36" width="82" alt="miracl" />
                </Logo>
                <AlignRight>
                    <ContactData>
                        <a href="tel:+436703085171">+43 670 3085171</a>
                        <span>Fragen? Ruf uns an</span>
                    </ContactData>
                    <AvatarWrapper onClick={handleToggle} onTouchEnd={handleToggle}>
                        <Avatar src="/assets/avatar/david.jpeg" width={42} height={42} alt="David" />
                    </AvatarWrapper>
                    {this.state.isContactOpen && (
                        <ClickAwayListener onClickAway={handleToggle}>
                            <div>
                                <ContactCard isModal={true} hasCalendarButton={true} />
                            </div>
                        </ClickAwayListener>
                    )}
                </AlignRight>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    background: ${colors.gray50};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 80px rgba(0, 0, 0, 0.16);
    display: flex;
    height: 56px;
    padding: 0 24px;
`;

const Logo = styled.a`
    height: 32px;
    margin-top: 10px;
    overflow: hidden;

    img {
        display: block;
    }
`;

const AvatarWrapper = styled.div`
    cursor: pointer;
    height: 44px;
    margin-left: auto;
    position: relative;

    &:after {
        background: #84cc16;
        border: 2px solid #fff;
        border-radius: 50%;
        content: "";
        height: 10px;
        left: 0;
        position: absolute;
        top: 30px;
        width: 10px;
    }
`;

const Avatar = styled.img`
    border-radius: 50%;
`;

const AlignRight = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: auto;
`;

const ContactData = styled.div`
    color: #4b5563;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
    text-align: right;

    a {
        color: #4b5563;
    }
    span {
        display: block;
        color: #6b7280;
        font-size: 10px;
        font-weight: 400;
        line-height: 10px;
    }

    @media (min-width: ${breakpointsNew.mediumMin}) {
        margin-right: 18px;
    }
`;

export default Header;
