import * as Sentry from "@sentry/react";
import { Component } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import { Currency } from "../../../../../../redux/reducers/engine.reducer";
import GtmServiceInstance from "../../../../../../service/gtm/gtm.service";
import { ButtonSqCss } from "../../../../../styles/base";
import { bpSurvey } from "../../../../../styles/constants/breakpoints";

const INTEREST_RATE_TYPES = {
    fixed: "Fix",
    variable: "Variabel",
};

const BEST_CREDIT_OPTIONS_SELECTS = {
    minRate: "Niedrigste Rate",
    minRateAdditionalDownPayment: "Mit zusätzlichen Eigenmittel",
    // "minRate5yearsFixed": "5 Jahre Fixzins",
    // "minRate10yearsFixed": "10 Jahre Fixzins",
    minRate15yearsFixed: "15 Jahre Fixzins",
    minRate20yearsFixed: "20 Jahre Fixzins",
    minRate25yearsFixed: "25 Jahre Fixzins",
    minRate30yearsFixed: "Sicherste Finanzierung",
};

export type CreditConditionsCardProps = {
    data: {
        periods: {
            interestRateType: string;
            interestRate: number;
            rate: number;
            months: number;
        }[];
        currency?: string;
        months?: string | number;
        payoutAmount?: number;
        tag?: string;
        downPayment?: number;
        effectiveInterestRate?: number;
        debtAmount?: number;
        totalCosts?: number;
    };
    onClick: (name: string) => void;
};

type CreditConditionsState = {
    showDetails: boolean;
};

export class CreditConditionsCard extends Component<CreditConditionsCardProps, CreditConditionsState> {
    state = {
        showDetails: false,
    };

    renderEffectiveInterestRate = (effectiveInterestRate) => {
        if (!effectiveInterestRate) {
            Sentry.captureException(
                `Failed to calculate effectiveInterestRate for ${JSON.stringify(
                    this.props.data,
                )}. Verify the user input arguments for the mortgage engine.`,
            );

            return "/";
        }

        return effectiveInterestRate.toLocaleString("DE", { style: "percent", maximumFractionDigits: 2 });
    };

    render() {
        if (this.props.data) {
            const {
                tag,
                periods,
                currency,
                months = 0,
                payoutAmount,
                downPayment,
                effectiveInterestRate,
                debtAmount,
                totalCosts,
            } = this.props.data;

            const { interestRateType, interestRate, rate } = periods[0];

            const tagName = BEST_CREDIT_OPTIONS_SELECTS[tag];

            const handleDetailsToggle = () => {
                this.setState((prevState) => ({
                    showDetails: !prevState.showDetails,
                }));

                GtmServiceInstance.track({
                    event: "creditCheck.moreBtn",
                    "creditCheck.properties": {
                        tag,
                        periods,
                        currency,
                        months,
                        payoutAmount,
                        downPayment,
                        effectiveInterestRate,
                        debtAmount,
                        interestRateType,
                        interestRate,
                        rate,
                        tagName,
                        totalCosts,
                    },
                });
            };

            const fullCreditLength = Number(months) / 12;

            const fixedPeriodLength =
                INTEREST_RATE_TYPES[interestRateType] === INTEREST_RATE_TYPES.variable
                    ? 0
                    : Number(periods[0].months) / 12;

            const variablePeriodLength = fullCreditLength - fixedPeriodLength;

            const TitleText =
                INTEREST_RATE_TYPES[interestRateType] === INTEREST_RATE_TYPES.variable
                    ? `${fullCreditLength} Jahre ${INTEREST_RATE_TYPES.variable}`
                    : `${fixedPeriodLength} Jahre ${INTEREST_RATE_TYPES.fixed.toLowerCase()}`;

            const handleNextBtnClick = () => {
                GtmServiceInstance.track({
                    event: "creditCheck.NextButton",
                });

                this.props.onClick(`&fullCreditLength=${fullCreditLength}&fixedPeriodLength=${fixedPeriodLength}`);
            };

            return (
                <Box>
                    <Header>
                        <Title>{TitleText}</Title>
                        <NextBtn bold onClick={handleNextBtnClick}>
                            Weiter
                        </NextBtn>
                    </Header>
                    <Content>
                        <PeriodsGraph>
                            <Columns>
                                <Column>
                                    <strong>
                                        {fixedPeriodLength
                                            ? periods[0].interestRate.toLocaleString("DE", {
                                                  style: "percent",
                                                  maximumFractionDigits: 2,
                                              })
                                            : "-"}
                                    </strong>
                                </Column>
                                <Column>
                                    <strong>
                                        {periods[periods.length - 1].interestRate.toLocaleString("DE", {
                                            style: "percent",
                                            maximumFractionDigits: 2,
                                        })}
                                    </strong>
                                </Column>
                            </Columns>
                            <Bar>
                                <FixBar length={(fixedPeriodLength / fullCreditLength) * 100} />
                            </Bar>
                            <Columns>
                                <Column>
                                    <strong>{`${fixedPeriodLength} J. fix`}</strong>
                                </Column>
                                <Column>{`${variablePeriodLength}  J. variabel`}</Column>
                            </Columns>
                        </PeriodsGraph>
                        <MonthlyRate>
                            ab{" "}
                            <strong>
                                {Number(rate).toLocaleString("DE")} {Currency.EUR}
                            </strong>
                        </MonthlyRate>
                    </Content>
                    <CSSTransition<undefined>
                        in={this.state.showDetails}
                        timeout={300}
                        unmountOnExit
                        // TODO: TS - element is of type boolean
                        onEnter={(element) => {
                            element.style.maxHeight = `${element.scrollHeight}px`;
                        }}
                    >
                        <ExtendedContent>
                            <Row isFirst>
                                <span>Effektivzinssatz:</span>
                                {this.renderEffectiveInterestRate(effectiveInterestRate)}
                            </Row>
                            <Row>
                                <span>Sollzinssatz:</span>
                                {interestRate.toLocaleString("DE", {
                                    style: "percent",
                                    maximumFractionDigits: 2,
                                })}{" "}
                                {INTEREST_RATE_TYPES[interestRateType]}
                            </Row>
                            <Row>
                                <span>Eigenkapital:</span>
                                {Number(downPayment).toLocaleString("DE")} {currency}
                            </Row>
                            <Row>
                                <span>Auszahlungsbetrag:</span>
                                {Number(payoutAmount).toLocaleString("DE")} {currency}
                            </Row>
                            <Row>
                                <span>Gesamtkosten:</span>
                                {Number(totalCosts).toLocaleString("DE")} {currency}
                            </Row>
                            <Row>
                                <span>Laufzeit insgesamt:</span>
                                {Number(months) / 12} Jahre
                            </Row>
                            <Row>
                                <h4>Perioden</h4>
                            </Row>
                            {periods.map((period, index) => (
                                <section key={index}>
                                    <Row>
                                        <span>Laufzeit:</span>
                                        {Number(period.months) / 12} Jahre
                                    </Row>
                                    <Row>
                                        <span>Sollzinssatz:</span>
                                        {period.interestRate.toLocaleString("DE", {
                                            style: "percent",
                                            maximumFractionDigits: 2,
                                        })}
                                    </Row>
                                    <Row>
                                        <span>Sollzinsbindung:</span>
                                        {INTEREST_RATE_TYPES[period.interestRateType]}
                                    </Row>
                                </section>
                            ))}
                        </ExtendedContent>
                    </CSSTransition>
                    <MoreBtn onClick={handleDetailsToggle} isOpen={this.state.showDetails} cyan>
                        Details
                    </MoreBtn>
                </Box>
            );
        }

        return null;
    }
}

const Box = styled.div`
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 7px auto 0;
    padding: 5px 10px 10px;
    position: relative;
`;

const Header = styled.div`
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    display: flex;
    margin: 0 0 7px;
    padding: 6px 0;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 12px;
`;

const Content = styled.div`
    display: flex;
`;

const PeriodsGraph = styled.div`
    margin: 3px auto 0 0;
    width: 200px;

    > div:first-child {
        font-size: 12px;
    }
`;

const MonthlyRate = styled.div`
    font-size: 16px;

    strong {
        font-weight: 500;
    }
`;

const Bar = styled.div`
    background: #1f60f2;
    height: 5px;
    margin-top: 1px;
`;

const FixBar = styled.div`
    background: #33c111;
    height: 5px;
    width: ${({ length }) => length}%;
`;

const ExtendedContent = styled.div`
    font-size: 12px;
    overflow: hidden;

    h4 {
        margin: 5px 0;
    }

    > section {
        border-top: 1px solid #ccc;

        &:first-of-type {
            border-top: 0;
        }
        &:last-child {
            margin-bottom: 32px;
        }
    }

    max-height: 0;
    transition: max-height ease-in-out 300ms;

    &.exit-active {
        max-height: 0 !important;
    }
    &.enter-done {
        max-height: none !important;
    }

    @media (min-width: ${bpSurvey.mediumMin}) {
        font-size: 14px;
    }
`;

const Row = styled.div`
    background: #f8f8f8;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 5px 5px;

    ${({ isFirst }) =>
        isFirst &&
        css`
            border-top: 1px solid #ccc;
            margin-top: 14px;
        `};

    span {
        margin-right: auto;
    }
`;

const NextBtn = styled.div`
    ${ButtonSqCss};

    &:after {
        border-color: #fff;
        border-top-width: 1px;
        border-right-width: 1px;
        border-top-style: solid;
        border-right-style: solid;
        content: "";
        height: 5px;
        margin-left: 4px;
        margin-top: -1px;
        width: 5px;
        transform: rotate(45deg);
    }
`;

const MoreBtn = styled.span`
    ${ButtonSqCss};
    color: #000;
    bottom: 10px;
    right: 10px;
    position: absolute;

    &:after {
        border-color: #000;
        border-top-width: 1px;
        border-right-width: 1px;
        border-top-style: solid;
        border-right-style: solid;
        content: "";
        height: 5px;
        margin-left: 7px;
        margin-top: -3px;
        width: 5px;
        transform: rotate(135deg);

        ${({ isOpen }) =>
            isOpen &&
            css`
                margin-top: 3px;
                transform: rotate(-45deg);
            `};
    }
`;

const Columns = styled.div`
    display: flex;
    font-size: 11px;
`;

const Column = styled.div`
    margin: 3px 0 0;
    width: 50%;

    &:last-child  {
        text-align: right;
    }
`;

export default CreditConditionsCard;
