import { Component } from "react";
import styled from "styled-components";
import { SurveyWrapper } from "../../../../styles/base";
import { colors } from "../../../../styles/constants/colors";
import FinancingTimelineBase from "../financingTimeline/base.component";
import BankLogos from "../new/BankLogos";
import TrustFooter from "../new/TrustFooter";

// TODO: TS - properly define types
export type HubPageProps = {
    firstName?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meetingMeta?: Record<string, any>;
    meetingLink?: string;
    isLoadingMeetingLink?: boolean;
};

export class HubPage extends Component<HubPageProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <SurveyWrapper>
                <Title>
                    Hallo {this.props.firstName},
                    <br />
                    Willkomen im <BoldBlue>miracl hub</BoldBlue>!
                </Title>
                <FinancingTimelineBase />
                <Divider />
                <BankLogos bg="#F3F4F5" />
                <TrustFooter bg="transparent" />
            </SurveyWrapper>
        );
    }
}

const BoldBlue = styled.b`
    color: ${colors.primary};
`;

const Title = styled.p`
    color: #000;
    display: block;
    font-size: 22px;
    font-weight: lighter;
    letter-spacing: 0px;
    margin: 0 0 52px 0;
    line-height: normal;
`;

const Divider = styled.div`
    margin-top: 18px;
    height: 1px;
    margin-right: 16px;
    margin-left: 16px;
    background: ${colors.gray300};
`;

export default HubPage;
