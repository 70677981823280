import { E164Number, parsePhoneNumber } from "libphonenumber-js";

export const normalizePhoneNumber = (phoneNumber: string): E164Number | undefined => {
    if (!phoneNumber) return undefined;
    return parsePhoneNumber(phoneNumber, "AT").number;
};

export const normalizeName = (fullName: string) => {
    const nameSplit = fullName.split(" ");

    /* Split name if possible */
    const normalizedFirstName = nameSplit[0];

    /* If the last name is split into multiple splits, join them as the lastname  */
    const normalizedLastName = nameSplit.length === 1 ? "" : nameSplit.splice(1, nameSplit.length - 1).join(" ");

    return {
        firstName: normalizedFirstName,
        lastName: normalizedLastName,
    };
};
