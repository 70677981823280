import NumberFormat from "react-number-format";
import styled, { css } from "styled-components";
import { bpSurvey, breakpoints } from "./constants/breakpoints";
import { colors } from "./constants/colors";

export const ContentWrapperCss = css`
    box-sizing: border-box;
    margin: 50px auto;
    max-width: 1400px;
    padding: 0 30px;
    width: 100%;

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin: 100px auto;
        padding-left: 60px;
        padding-right: 60px;
    }
`;

export const ContentWrapper = styled.div`
    ${ContentWrapperCss};
`;

export const ButtonCss = css`
    align-items: center;
    background: ${({ background }) => (background ? background : colors.primary)};
    border-radius: 24px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    justify-content: center;
    padding: 9px 25px;
    text-align: center;

    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `};
`;

export const Button = styled.span`
    ${ButtonCss};
`;

export const NextButtonCss = css`
    align-items: center;
    background: ${({ disabled }) => (disabled ? "#CFD5DA" : colors.primary)};
    border-radius: 28px;
    box-sizing: border-box;
    color: #f6f2f1;
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    display: flex;
    font-size: 22px;
    font-weight: 300;
    height: 56px;
    justify-content: center;
    border-width: 0px;
    letter-spacing: 0.2px;
    margin: ${({ margin }) => (margin ? margin : 80)}px auto 30px;
    position: relative;
    width: 100%;

    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `};

    .loader {
        margin-top: -12px;
        position: absolute;
        right: 20px;
        top: 50%;
    }
`;

export const NextButton = styled.button`
    ${NextButtonCss};
`;

export const CenterBlock = styled.div`
    text-align: center;
`;

export const H1Css = css`
    align-items: flex-end;
    color: #000;
    display: flex;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin: 0 0 70px;
    min-height: 120px;
    justify-content: center;
    text-align: center;
    width: 100%;
`;
export const H1 = styled.h1`
    ${H1Css};
`;

export const H2Css = css`
    color: #000;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 28px;
    margin: 0 0 40px;
    text-align: center;
`;
export const H2 = styled.h2`
    ${H2Css};
`;

export const H3Css = css`
    color: ${({ color }) => (color ? color : colors.primary)};
    font-size: 21px;
    font-weight: 500;
    line-height: 1.4;
    margin: 0 0 14px;
    text-align: ${({ center }) => (center ? "center" : "inherit")};

    @media (min-width: ${breakpoints.tabletMin}) {
        font-size: 25px;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
        font-size: 28px;
    }
`;
export const H3 = styled.h3`
    ${H3Css};
`;

export const SurveyWrapper = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: ${({ smallPadding }) => (smallPadding ? 374 : 375)}px;
    padding: 0 ${({ smallPadding }) => (smallPadding ? 7 : 24)}px;
    width: 100%;

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 440px;
    }
`;

export const AnswerCss = css`
    border-radius: 8px;
    box-sizing: border-box;
    color: #222c31;
    display: flex;
    font-weight: 500;
    min-height: 56px;
`;

export const AnswerBtn = styled.span`
    ${AnswerCss};
    align-items: center;
    background: #fbfbfb;
    cursor: pointer;
    font-family: "Inter", "Poppins", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0 auto 8px;
    padding-left: ${({ hasIcon }) => (hasIcon ? 5 : 16)}px;
    padding-right: 48px;
    padding-bottom: 5px;
    padding-top: 5px;
    position: relative;

    img {
        margin-right: 5px;
        min-width: 76px;
    }

    :before {
        border-radius: 50%;
        content: "";
        height: 20px;
        margin-top: -10px;
        box-shadow: 0 0 0 1px #ccccff;
        position: absolute;
        right: 16px;
        top: 50%;
        width: 20px;
    }
    &.active,
    &:active {
        border: 2px solid ${colors.primary};
        font-weight: 600;
        padding-left: ${({ hasIcon }) => (hasIcon ? 3 : 14)}px;
        padding-right: 46px;
        padding-bottom: 3px;
        padding-top: 3px;

        :before {
            background: ${colors.primary};
            border: 3px solid #fbfbfb;
            box-sizing: border-box;
            box-shadow: 0 0 0 1px ${colors.primary};
            right: 14px;
        }
    }
`;

export const TextInputCss = css`
    ${AnswerCss};
    -webkit-appearance: none;

    border: ${({ error }) => (error ? `2px solid ${colors.error} !important` : `1px solid #A7B6BD`)};
    color: ${({ color }) => (color ? color : "inherit")};
    font-family: "Poppins", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    font-size: 20px;
    line-height: 1.2;
    outline: none;
    padding: 0 15px;
    width: 100%;

    :focus,
    :active {
        border: 2px solid ${colors.primary};
        padding: 0 14px;
    }
    ::placeholder {
        color: #9a9a9a;
        font-weight: 300;
    }
`;

export const TextInput = styled.input`
    ${TextInputCss};
`;

export const SelectInput = styled.select`
    ${TextInputCss};

    background-color: white;
    margin: 0;

    option {
        background-color: white;
    }
`;

export const InputLabel = styled.p`
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: lighter;
`;

export const ErrorMsg = styled.p`
    color: ${colors.error};
    font-size: 12px;
    margin: 5px 10px 15px;
`;

export const InfoMsg = styled.p`
    color: ${colors.primary};
    font-size: 12px;
    margin: 5px 10px 15px;
`;

export const InputWrapper = styled.div`
    margin: 0 auto 27px;
`;

export const PageLoading = styled.div`
    align-items: center;
    color: #888;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 300;
    width: 100%;

    .loader {
        margin-top: -28px;
        position: absolute;
    }

    + div {
        display: none;
    }
`;

export const ConditionsFilterCss = css`
    -webkit-appearance: none;

    background: #fff;
    border: 1px solid ${colors.primary};
    border-radius: 4px;
    box-sizing: border-box;
    color: #4a49fb;
    font-family: "Poppins", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    font-size: 14px;
    font-weight: 300;
    height: 30px;
    line-height: 20px;
    outline: none;
    padding: 0 10px;

    :active {
        background: #dadafe;
    }
`;

export const ConditionsSelect = styled.select`
    ${ConditionsFilterCss};
    background: transparent;
    width: 100%;
    position: relative;
    z-index: 2;

    :active,
    :focus {
        background: transparent;
    }
`;

export const ConditionsNumberInput = styled(NumberFormat)`
    ${ConditionsFilterCss};
    font-size: 16px;
    max-width: 100%;
    width: 100%;

    :focus {
        background: #dadafe;
    }
`;

export const ButtonSqCss = css`
    align-items: center;
    background: ${colors.primary};
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    line-height: 18px;
    justify-content: center;
    padding: 3px 12px;

    ${({ bold }) =>
        bold &&
        css`
            font-weight: 500;
        `};

    ${({ cyan }) =>
        cyan &&
        css`
            background: ${colors.cyan};
            color: #000;
        `};

    ${({ noBg }) =>
        noBg &&
        css`
            background: transparent;
            border: 2px solid ${colors.primary};
            color: ${colors.primary};
        `};
`;

export const ButtonSq = styled.span`
    ${ButtonSqCss};
`;

export const HubTitleCss = css`
    color: #000;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 0px;
    margin: 0 0 20px;
    line-height: normal;
`;

export const HubTitle = styled.h3`
    ${HubTitleCss};
`;
