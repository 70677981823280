import * as Sentry from "@sentry/react";
import { AnyAction, applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { b64EncodeUnicode } from "../service/common/helper";
import { SET_PROPERTY } from "./actions/hubspot.actions";
import {
    configReducer,
    hubReducer,
    hubspotReducer,
    mortgageEngineReducer,
    sessionReducer,
    surveyReducer,
} from "./reducers";
import { SURVEY_PAGE_PROPERTIES } from "./reducers/survey/config/survey.config";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
        if (
            action.type === SET_PROPERTY &&
            (action.property === SURVEY_PAGE_PROPERTIES.userContact ||
                action.property === SURVEY_PAGE_PROPERTIES.userContactEmail ||
                action.property === SURVEY_PAGE_PROPERTIES.userContactPhone)
        ) {
            let encodedUserContactData = {};

            try {
                encodedUserContactData = b64EncodeUnicode(JSON.stringify(action.value));
            } catch (e) {
                Sentry.captureException(e);
            }

            /* Do not send any contact data to sentry */
            return {
                type: action.type,
                value: encodedUserContactData,
            };
        }

        return action;
    },
});

const reducer = combineReducers({
    hubspot: hubspotReducer,
    survey: surveyReducer,
    config: configReducer,
    engine: mortgageEngineReducer,
    session: sessionReducer,
    hub: hubReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer));

// expose store when running Cypress
if (window && window.Cypress) {
    window.store = store;
}
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type ThunkBaseDispatch<S = RootState, P = void, A extends AnyAction = AnyAction> = ThunkDispatch<S, P, A>;
export type ThunkBaseAction<R = void, S = RootState, P = unknown, A extends AnyAction = AnyAction> = ThunkAction<
    R,
    S,
    P,
    A
>;

export default store;
