import InputTypes from "../../app/elements/survey/components/new/common/ComponentTypes";
import type { OptionsObject, PageStructure } from "../../app/elements/survey/components/pages/new/types";
import type { Workflow } from "../../redux/reducers/survey/workflows/types";
import GtmServiceInstance from "./gtm.service";

class SurveyTracker {
    trackQuestionStartEvent(surveyVersion = "unknown", property = "unknown") {
        const eventName = `surveyStep_${property}_Start`;

        GtmServiceInstance.track({
            event: eventName,
            surveyVersion,
        });
    }

    trackQuestionChangeEvent(surveyVersion = "unknown", property = "", question?: string, answer?: string) {
        const eventName = `surveyStep_${property}_Question_Answered`;

        GtmServiceInstance.track({
            event: eventName,
            surveyVersion,
            question: question,
            answer: answer,
        });
    }

    trackQuestionFinishEvent(surveyVersion = "unknown", property = "", pageStructure: PageStructure) {
        const eventName = `surveyStep_${property}_Finished`;

        const data = this.getPageQuestionsAndAnswers(pageStructure);

        GtmServiceInstance.track({
            data,
            event: eventName,
            surveyVersion,
        });
    }

    trackSurveySubmitAllStepsEvent(surveyVersion: string | undefined, workflow: Workflow) {
        let data = {};

        for (const [, pageComponent] of Object.entries(workflow)) {
            const { pageStructure, property } = pageComponent;

            if (pageStructure && property) {
                const pageData = this.getPageQuestionsAndAnswers(pageStructure);

                data = {
                    ...data,
                    ...pageData,
                };
            }
        }

        GtmServiceInstance.track({
            event: "surveyFullFinished",
            surveyVersion: surveyVersion || "unknown",
            data,
        });
    }

    getPageQuestionsAndAnswers(pageStructure: PageStructure) {
        const data = {};

        if (pageStructure) {
            for (const [, entry] of Object.entries(pageStructure)) {
                if (entry.property) {
                    const val = entry.attributes.value;
                    const hasValues = val != null;
                    const isMultipleSelection = entry.type === InputTypes.multipleSelection;
                    data[entry.property] = {};

                    /* Support multi-selection for analytics */
                    data[entry.property].answer =
                        isMultipleSelection && hasValues && typeof val === "object" && !Array.isArray(val)
                            ? this.parseMultipleSelectionValues(val)
                            : val;

                    data[entry.property].question = entry.label;
                }
            }
        }

        return data;
    }

    /**
     * @returns {string} return all multiple selection values as a string in the following format: "value1&value2&value3"
     */
    parseMultipleSelectionValues(values: OptionsObject) {
        return Object.keys(values)
            .filter((key) => values[key].value)
            .map((key) => values[key].label)
            .join("&");
    }

    trackSurveyBackEvent(surveyVersion = "unknown", currentPage: string = null) {
        GtmServiceInstance.track({
            event: "surveyBack",
            step: currentPage,
            surveyVersion: surveyVersion || "unknown",
        });
    }

    trackSurveyStartGeneral(surveyVersion = "unknown") {
        GtmServiceInstance.track({
            event: "surveyStartGeneral",
            surveyVersion,
        });
    }

    trackIsRecurringUser(_isRecurring = false) {
        GtmServiceInstance.track({
            event: "userEngagement",
            isExistingContact: _isRecurring,
        });
    }
}

const SurveyTrackerInstance = new SurveyTracker();

Object.freeze(SurveyTrackerInstance);

export default SurveyTrackerInstance;
