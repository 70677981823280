import { Component } from "react";
import styled from "styled-components";
import { SurveyWrapper } from "../styles/base";
import { colors } from "../styles/constants/colors";
import GlobalStyles from "../styles/global-styles.hub";

export class MagicLinkSentContainer extends Component {
    renderMagicLinkWasSent() {
        return (
            <>
                <GlobalStyles />
                <PageWrapper>
                    <SurveyWrapper>
                        <h3>Du hast Post! Überprüfe deine E-Mails.</h3>
                        <p>Wir haben dir einen Magic Link geschickt! Klicke darauf um die Anmeldung abzuschließen.</p>
                    </SurveyWrapper>
                </PageWrapper>
            </>
        );
    }

    render() {
        return this.renderMagicLinkWasSent();
    }
}

const PageWrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 120px 0 60px;
    background-color: ${colors.focusBg};
`;

export default MagicLinkSentContainer;
