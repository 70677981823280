import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import { SurveyNewWrapper } from "../../../../styles/newStyle";
import GoogleReviewsElement from "./GoogleReviewsElement";

export type TrustFooterProps = {
    bg?: string;
};

export class TrustFooter extends Component<TrustFooterProps> {
    render() {
        return (
            <Wrapper bg={this.props.bg}>
                <StyledSurveyWrapper>
                    <img src="/assets/svg/austriaLogo.svg" width={44} height={29} alt="Austria" />
                    <GoogleReviewsElement />
                    <span>
                        Nr. 1<br />
                        in Kundenzufriedenheit
                    </span>
                </StyledSurveyWrapper>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    background: ${({ bg }) => bg || "#fff"};
    padding: 32px 0;
`;

const StyledSurveyWrapper = styled(SurveyNewWrapper)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 12px;
    justify-content: space-around;
    padding: 0;

    > span {
        color: ${colors.gray500};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }
`;

export default TrustFooter;
