import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import validator from "validator";
import AppConfig from "../../config/AppConfig";

const GOOGLE_OPTIMIZE_ACTIVATE = "optimize.activate";

class GtmService {
    options: Record<string, boolean>;

    constructor() {
        this.options = {};
        if (AppConfig.GTM.gtmId) {
            this.options[GOOGLE_OPTIMIZE_ACTIVATE] = false;

            TagManager.initialize({
                gtmId: AppConfig.GTM.gtmId,
                dataLayer: {
                    surveyAppVersion: AppConfig.surveyAppVersion,
                    originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
                },
            });
        }
    }

    wasEventTriggered(_event) {
        return _event && this.options[_event] !== undefined && this.options[_event] === true;
    }

    trackEventTrigger(_event) {
        const isEventAvailable = this.options && this.options.hasOwnProperty(_event);

        if (isEventAvailable) {
            this.options[_event] = true;
        }
    }

    trackEvents(_gtmObject) {
        const _eventObject = _gtmObject.dataLayer;

        const wasGoogleOptimizeEventActiveTriggered =
            validator.equals(GOOGLE_OPTIMIZE_ACTIVATE, _eventObject.event) &&
            this.wasEventTriggered(_eventObject.event);

        if (wasGoogleOptimizeEventActiveTriggered) {
            const error = `The event object ${_gtmObject} option is specified to be triggered once, but was given called multiple times. Verify your event, options and tracking workflow.`;

            console.warn(error);

            return;
        }

        this.trackEventTrigger(_eventObject.event);

        if (AppConfig.GTM.gtmId) {
            TagManager.dataLayer(_gtmObject);
        }

        console.log("GTM Log: ", _gtmObject);
    }

    track(_object) {
        if (AppConfig.isProductionStage && !AppConfig.GTM.gtmId) {
            const error = new Error("App production-stage started without GTM key init.");

            Sentry.captureException(error);

            console.error(error);

            return;
        }

        if (!_object) {
            const error = new Error(`Tracking object is invalid ${_object}.`);

            Sentry.captureException(error);

            console.error(error);

            return;
        }

        const tagManagerArgs = {
            dataLayer: {
                ..._object,
            },
        };

        /* Track events object */
        if (_object.event) {
            this.trackEvents(tagManagerArgs);
        }
    }

    appStart() {
        console.log("Survey-Application start");
    }

    pushDataLayer(object) {
        if (!object) return;
        const props = {
            dataLayer: object,
        };

        if (AppConfig.GTM.gtmId) {
            TagManager.dataLayer(props);
        }
        console.log("GTM Log: ", props);
    }
}

const GtmServiceInstance = new GtmService();

Object.freeze(GtmServiceInstance);

export default GtmServiceInstance;
