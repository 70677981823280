import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import QuestionItem from "./QuestionItem";

type Option = { value: boolean; label: string };
export type MultipleSelectionProps = {
    tooltip: string;
    tooltipAlignBottom?: boolean;
    errors: React.ReactNode[];
    pageName: string;
    label: string;
    options: Record<string, Option>;
    property: string;
    onChange: (value: { key: string; value: Record<string, Option>; question: string }) => void;
};

export class MultipleSelection extends Component<MultipleSelectionProps> {
    state = {
        input: this.props.options,
    };

    renderSelection = () => {
        const checkBoxSVG = "/assets/svg/checkBox2.svg";
        const checkBoxCheckedSVG = "/assets/svg/checkBoxChecked2.svg";
        const multipleSelectionValuesKey = Object.keys(this.state.input);

        return multipleSelectionValuesKey.map((key, index) => {
            const { value, label } = this.state.input[key];

            return (
                <Label isChecked={value} key={index}>
                    <input
                        hidden
                        type="checkbox"
                        name={this.props.property}
                        onChange={(e) => {
                            const { checked } = e.target;

                            const current = this.state.input;

                            current[key].value = checked;

                            this.setState(
                                {
                                    input: current,
                                },
                                () => {
                                    this.props.onChange({
                                        key,
                                        value: this.state.input,
                                        question: this.state.input[key].label,
                                    });
                                },
                            );
                        }}
                        checked={value}
                        value={String(value)}
                    />
                    <img src={value ? checkBoxCheckedSVG : checkBoxSVG} width={20} height={20} alt="" />
                    <span>{label}</span>
                </Label>
            );
        });
    };

    render() {
        return (
            <QuestionItem
                tooltip={this.props.tooltip}
                tooltipAlignBottom={this.props.tooltipAlignBottom}
                wrapperWidth="100%"
                errors={this.props.errors}
                pageName={this.props.pageName}
                labelAbove={this.props.label}
            >
                {this.renderSelection()}
            </QuestionItem>
        );
    }
}

const Label = styled.label`
    align-items: center;
    border-radius: 6px;
    box-sizing: border-box;
    color: ${colors.gray900};
    cursor: pointer;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 12px 0 14px;
    padding: 3px 0 3px 31px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    img {
        cursor: pointer;
        display: inline-block;
        left: 3px;
        margin-top: -10px;
        position: absolute;
        top: 50%;
    }

    a {
        color: ${colors.blue700};
        text-decoration: underline;
    }
`;

export default MultipleSelection;
