import { Component } from "react";
import { SURVEY_PAGE_PROPERTIES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import StructuredPageComponent from "../../structuredPage.component";
import { PageProps } from "../types";

export type PersonalFinancesPageProps = PageProps;

export class PersonalFinancesPage extends Component<PersonalFinancesPageProps> {
    render() {
        return (
            <StructuredPageComponent
                /* Hubspot state loading */
                isLoadingHubspotSubmit={this.props.isLoadingHubspotSubmit}
                /* Change of components values */
                setPageComponentValue={this.props.setPageComponentValue}
                /* Change of components errors */
                setPageComponentError={this.props.setPageComponentError}
                /* Access to the survey config */
                workflow={this.props.workflow}
                /* Access to the survey version */
                surveyVersion={this.props.surveyVersion}
                /* Page config name for analytics */
                pageName={SURVEY_PAGE_PROPERTIES.personalFinances}
                /* Page Structure defining the elements to be rendered*/
                pageStructure={this.props.page.pageStructure}
                /* Convenient Current page data */
                page={this.props.page}
                /* Action for adding properties for user-contact creation  */
                setProperty={this.props.setProperty}
                /* Property list of collected user-data during the survey */
                properties={this.props.properties}
                /* Handler for moving to the next page */
                onNextSurveyPage={this.props.onNextSurveyPage}
                /* Handler for moving to the prev page */
                onPreviousSurveyPage={this.props.onPreviousSurveyPage}
                /* Show error after creating contact failed */
                createContactError={this.props.createContactError}
            />
        );
    }
}

export default PersonalFinancesPage;
