import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import InputTypes from "../../../new/common/ComponentTypes";
import { isValidEmail } from "../../../new/common/PageComponentConditionals";
import CommonProperties from "../../../new/common/Properties";
import type { PageStructure } from "../types";

export const pageStructure: PageStructure = {
    OFFER_GAMIFICATION: {
        type: InputTypes.gamification,
        label:
            "Fast geschafft! Die aktuellen Top-Zinssätze liegen bei" +
            "\n" +
            "\n" +
            "✅ <b>3,625 % p.a. für 25 Jahre fix und</b>" +
            "\n" +
            "✅ <b>3,75 % p.a. bei var. Verzinsung.</b>" +
            "\n" +
            "\n" +
            "Die Konditionen sind von deiner finanziellen Situation abhängig. Dafür melden wir uns bei dir.",
        property: null,
        attributes: {
            value: null,
            placeholder: null,
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: null,
        conditional: null,
    },
    FIRST_NAME: {
        type: InputTypes.textInput,
        label: "Vor- und Nachname",
        property: CommonProperties.FIRST_NAME,
        attributes: {
            value: null,
            placeholder: "z. B. Max Mustermann",
            required: true,
            autocomplete: "given-name",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib deinen Vor- und Nachnamen ein.",
            invalid: null,
        },
        // helperText: "Für wen sollen wir den Kreditvergleich machen?",
        tooltip: null,
        gamification: null,
        visibility: null,
        conditional: null,
    },
    EMAIL: {
        type: InputTypes.emailInput,
        label: "E-Mail",
        property: CommonProperties.EMAIL,
        attributes: {
            value: null,
            placeholder: "z. B. max@mustermann.com",
            required: true,
            autocomplete: "email",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib deine E-Mail an.",
            invalid: "Überprüfe ob deine E-Mail korrekt ist.",
        },
        // helperText: "An diese E-Mail-Adresse werden wir alle weiteren Informationen zuschicken.",
        tooltip: null,
        tooltipAlignBottom: true,
        gamification: null,
        visibility: null,
        conditional: [
            {
                expression: isValidEmail,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.email,
                        component: "EMAIL",
                    },
                ],
            },
        ],
    },
    AGREEMENT: {
        type: InputTypes.checkBox,
        label: `Mit Klick auf "Weiter" werden die <a href='https://miracl.at/datenschutzerklaerung/' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a>, <a href='https://miracl.at/agb/' target='_blank' rel='noopener noreferrer'> die AGB</a> und das <a href='https://miracl.at/standardinfoblatt/' target='_blank' rel='noopener noreferrer'>Standardinformationsblatt</a> akzeptiert.`,
        property: CommonProperties.AGREEMENT,
        attributes: {
            value: false,
            placeholder: null,
            required: false,
            hidden: true,
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: null,
        conditional: null,
    },
};
