import * as Sentry from "@sentry/react";
import validator from "validator";
import {
    getFileByIdRequest,
    getFinancingTimelineRequest,
    getMeetingLinkRequest,
    getUserContactsRequest,
} from "../../service/api";
import type { Timeline } from "../reducers/hub.reducer";
import { ThunkBaseAction } from "../store";
import { Callback } from "../types";
import { setIsExistingContact } from "./hubspot.actions";

export const SET_USER_CONTACT_HUB = "SET_USER_CONTACT_HUB";

export const SET_USER_CONTACT_IS_LOADING_HUB = "SET_USER_CONTACT_IS_LOADING_HUB";

export const SET_USER_CONTACT_IS_ERROR_HUB = "SET_USER_CONTACT_IS_ERROR_HUB";

export const SET_MEETING_LINK_HUB = "SET_MEETING_LINK_HUB";

export const SET_MEETING_LINK_IS_LOADING_HUB = "SET_MEETING_LINK_IS_LOADING_HUB";

export const SET_MEETING_LINK_IS_ERROR_HUB = "SET_MEETING_LINK_IS_ERROR_HUB";

export const DEFAULT_MEETINGS_LINK = "https://kontakt.miracl.at/meetings/service-meeting/beratungsgespraech-miracl";

export const DEFAULT_FOLLOW_UP_LINK = "https://kontakt.miracl.at/meetings/service-meeting/erstgespraech";

export const SET_FINANCING_TIMELINE = "SET_FINANCING_TIMELINE";

export const SET_FINANCING_TIMELINE_IS_LOADING = "SET_FINANCING_TIMELINE_IS_LOADING";

export const SET_FINANCING_TIMELINE_IS_ERROR = "SET_FINANCING_TIMELINE_IS_ERROR";

export const SET_FILE_DOWNLOAD_IS_LOADING = "SET_FILE_DOWNLOAD_IS_LOADING";

export const SET_FILE_DOWNLOAD_IS_ERROR = "SET_FILE_DOWNLOAD_IS_ERROR";

export const FILE_TYPE = {
    CREDIT_OFFER: "creditOffer",
};

export const SET_MAGIC_LINK_IS_LOADING = "SET_MAGIC_LINK_IS_LOADING";

export const SET_MAGIC_LINK_IS_ERROR = "SET_MAGIC_LINK_IS_ERROR";

export function setFileDownloadIsLoading(isLoading: boolean) {
    return {
        type: SET_FILE_DOWNLOAD_IS_LOADING,
        isLoading,
    } as const;
}
export type SetFileDownloadIsLoading = ReturnType<typeof setFileDownloadIsLoading>;

export function setMagicLinkIsLoading(isLoading: boolean) {
    return {
        type: SET_MAGIC_LINK_IS_LOADING,
        isLoading,
    } as const;
}
export type SetMagicLinkIsLoading = ReturnType<typeof setMagicLinkIsLoading>;

export function setFileDownloadIsError(isError: boolean, errorMessage: string) {
    return {
        type: SET_FILE_DOWNLOAD_IS_ERROR,
        isError,
        errorMessage,
    } as const;
}
export type SetFileDownloadIsError = ReturnType<typeof setFileDownloadIsError>;

export function setMagicLinkIsError(isError: boolean, errorMessage: string) {
    return {
        type: SET_MAGIC_LINK_IS_ERROR,
        isError,
        errorMessage,
    } as const;
}
export type SetMagicLinkIsError = ReturnType<typeof setMagicLinkIsError>;

export function setFinancingTimeline(timeline: Timeline, dealId: string) {
    return {
        type: SET_FINANCING_TIMELINE,
        timeline,
        dealId,
    } as const;
}
export type SetFinancingTimeline = ReturnType<typeof setFinancingTimeline>;

export function setFinancingTimelineIsLoading(isLoading: boolean) {
    return {
        type: SET_FINANCING_TIMELINE_IS_LOADING,
        isLoading,
    } as const;
}
export type SetFinancingTimelineIsLoading = ReturnType<typeof setFinancingTimelineIsLoading>;

export function setFinancingTimelineIsError(isError: boolean, errorMessage: string) {
    return {
        type: SET_FINANCING_TIMELINE_IS_ERROR,
        isError,
        errorMessage,
    } as const;
}
export type SetFinancingTimelineIsError = ReturnType<typeof setFinancingTimelineIsError>;

export function setUser(
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    phone: string | null,
    contactId: string | null,
    created?: string | null,
    isExistingContact?: boolean,
) {
    return {
        type: SET_USER_CONTACT_HUB,
        firstName,
        lastName,
        email,
        phone,
        contactId,
        created,
        isExistingContact,
    } as const;
}
export type SetUser = ReturnType<typeof setUser>;

// TODO: TS - properly define types
export function setMeetingLinkHub(link: string | null, meta: Record<string, any> | null) {
    return {
        type: SET_MEETING_LINK_HUB,
        link,
        meta,
    } as const;
}
export type SetMeetingLinkHub = ReturnType<typeof setMeetingLinkHub>;

export function setUserContactIsLoadingHub(isLoading: boolean) {
    return {
        type: SET_USER_CONTACT_IS_LOADING_HUB,
        isLoading,
    } as const;
}
export type SetUserContactIsLoadingHub = ReturnType<typeof setUserContactIsLoadingHub>;

export function setUserContactErrorHub(isError: boolean, errorMessage: string) {
    return {
        type: SET_USER_CONTACT_IS_ERROR_HUB,
        isError,
        errorMessage,
    } as const;
}
export type SetUserContactErrorHub = ReturnType<typeof setUserContactErrorHub>;

export function setMeetingLinkIsLoadingHub(isLoading: boolean) {
    return {
        type: SET_MEETING_LINK_IS_LOADING_HUB,
        isLoading,
    } as const;
}
export type SetMeetingLinkIsLoadingHub = ReturnType<typeof setMeetingLinkIsLoadingHub>;

export function setMeetingLinkErrorHub(isError: boolean, errorMessage: string) {
    return {
        type: SET_MEETING_LINK_IS_ERROR_HUB,
        isError,
        errorMessage,
    } as const;
}
export type SetMeetingLinkErrorHub = ReturnType<typeof setMeetingLinkErrorHub>;

export type HubActions =
    | SetUser
    | SetFinancingTimelineIsError
    | SetFinancingTimelineIsLoading
    | SetFinancingTimeline
    | SetFileDownloadIsError
    | SetMagicLinkIsError
    | SetMagicLinkIsLoading
    | SetFileDownloadIsLoading
    | SetMeetingLinkHub
    | SetUserContactIsLoadingHub
    | SetUserContactErrorHub
    | SetMeetingLinkIsLoadingHub
    | SetMeetingLinkErrorHub;

export const getUserData =
    (callback?: Callback): ThunkBaseAction =>
    (dispatch, getState) => {
        dispatch(setUserContactIsLoadingHub(true));

        dispatch(setUserContactErrorHub(false, null));

        getUserContactsRequest({
            token: getState().session.token,
        })
            .then((response) => {
                dispatch(setUserContactErrorHub(false, null));

                const { firstName, lastName, email, phone, contactId, created, isExistingContact } = response.data;

                dispatch(setUser(firstName, lastName, email, phone, contactId, created));

                dispatch(setIsExistingContact(isExistingContact));

                dispatch(getMeetingLink());

                dispatch(getFinancingTimeline());

                dispatch(setUserContactIsLoadingHub(false));

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                Sentry.captureException(error);

                dispatch(setUserContactErrorHub(true, error.message));

                dispatch(setUserContactIsLoadingHub(false));

                if (callback) {
                    callback(error);
                }
            });
    };

export const getMeetingLink =
    (callback?: Callback): ThunkBaseAction =>
    (dispatch, getState) => {
        dispatch(setMeetingLinkIsLoadingHub(true));

        dispatch(setMeetingLinkErrorHub(false, null));

        dispatch(setMeetingLinkHub(null, null));

        getMeetingLinkRequest({
            token: getState().session.token,
        })
            .then((response) => {
                const { link, meta } = response.data;

                dispatch(setMeetingLinkHub(link, meta));

                dispatch(setMeetingLinkErrorHub(false, null));

                dispatch(setMeetingLinkIsLoadingHub(false));

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                Sentry.captureException(error);

                dispatch(setMeetingLinkErrorHub(true, error.message));

                dispatch(setMeetingLinkHub(null, null));

                dispatch(setMeetingLinkIsLoadingHub(false));

                if (callback) {
                    callback(error);
                }
            });
    };

export const pollMeetingLink =
    (timeout = 10000): ThunkBaseAction =>
    (dispatch, getState) => {
        /* Poll until we have a valid appointmentLink */
        if (
            getState().hub.meeting.link == null ||
            validator.equals(getState().hub.meeting.link, DEFAULT_MEETINGS_LINK)
        ) {
            dispatch(
                getMeetingLink(() => {
                    setTimeout(() => {
                        dispatch(pollMeetingLink(timeout));
                    }, timeout);
                }),
            );
        }
    };

export const getFinancingTimeline =
    (callback?: Callback): ThunkBaseAction =>
    (dispatch, getState) => {
        dispatch(setFinancingTimelineIsLoading(true));

        dispatch(setFinancingTimeline(null, null));

        dispatch(setFinancingTimelineIsError(false, null));

        const contactId = getState().hub.user.contactId;

        getFinancingTimelineRequest({
            token: getState().session.token,
            contactId,
        })
            .then((response) => {
                const { timeline, dealId } = response.data;

                dispatch(setFinancingTimeline(timeline, dealId));

                dispatch(setFinancingTimelineIsError(false, null));

                dispatch(setFinancingTimelineIsLoading(false));

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                Sentry.captureException(error);

                dispatch(setFinancingTimelineIsError(true, error.message));

                dispatch(setFinancingTimeline(null, null));

                dispatch(setFinancingTimelineIsLoading(false));

                if (callback) {
                    callback(error);
                }
            });
    };

// TODO: Change the default when we improve the authorization security, as for now we only allow the user to
// download the credit offer that was uploaded by the MS
export const downloadFileById =
    (id: string, callback?: Callback): ThunkBaseAction =>
    (dispatch, getState) => {
        dispatch(setFileDownloadIsLoading(true));

        dispatch(setFileDownloadIsError(false, null));

        getFileByIdRequest({
            token: getState().session.token,
            id: id,
        })
            .then((response) => {
                dispatch(setFileDownloadIsError(false, null));

                dispatch(setFileDownloadIsLoading(false));

                if (callback) {
                    const readableStream = response.data;

                    callback(readableStream);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);

                dispatch(setFileDownloadIsError(true, error.message));

                dispatch(setFileDownloadIsLoading(false));
            });
    };
