import { Component } from "react";
import styled, { css } from "styled-components";
import { breakpointsNew } from "../../../../styles/constants/breakpoints";
import { colors } from "../../../../styles/constants/colors";

export type TimePickerProps = {
    onChange: (data: { value: string }) => void;
};
export type TimePickerState = {
    day?: string;
    slot?: string | null;
};

export class TimePicker extends Component<TimePickerProps, TimePickerState> {
    state = {
        day: undefined,
        slot: null,
    };

    HOURS = [9, 10, 11, 12, 13, 14, 15, 16, 17];
    SLOT_LENGTH = 1;

    handleSlotClick = (event) => {
        const newValue = event.currentTarget ? event.currentTarget.value : event.target.value;

        this.setState(() => ({
            slot: newValue,
        }));

        this.props.onChange({
            value: newValue,
        });
    };

    resetSlot = () => {
        this.setState(() => ({
            slot: null,
        }));
    };

    render() {
        return (
            <Wrapper>
                <SlotsList>
                    {this.HOURS.map((slot, index) => {
                        return (
                            <SlotItem
                                onClick={this.handleSlotClick}
                                value={slot}
                                type="button"
                                isActive={slot === parseInt(this.state.slot, 10)}
                                key={index}
                                disabled={this.state.day === "now"}
                            >
                                Zwischen
                                <span>
                                    {slot}-{slot + 1} Uhr
                                </span>
                            </SlotItem>
                        );
                    })}
                </SlotsList>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin-bottom: 32px;

    h2 {
        margin-bottom: 0;
    }
    p {
        color: ${colors.gray500};
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 24px;
    }
`;

const SlotsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
`;

const SlotItem = styled.button`
    background: #fff;
    border: 2px solid ${colors.gray200};
    border-radius: 8px;
    color: ${colors.gray700};
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    margin: 12px 0 0 10px;
    padding: 14px 0px;
    text-transform: uppercase;
    transition: background-color ease-out 0.16s;
    width: 102px;

    span {
        display: block;
        font-weight: 700;
        font-size: 12px;
        text-transform: none;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${colors.blue500};
            border-color: ${colors.blue500};
            color: #fff;
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;
            opacity: 0.5;
        `};

    @media (max-width: 375px) {
        width: 31%;
        margin-left: 2%;
    }
    @media (max-width: ${breakpointsNew.mediumMax}) {
        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }
    @media (min-width: ${breakpointsNew.largeMin}) {
        width: 90px;
        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }
`;

export default TimePicker;
