import { Component, MouseEventHandler } from "react";
import styled from "styled-components";
import { INTEREST_TYPES_Value } from "../../../../../../redux/reducers/engine.reducer";
import { ButtonSq } from "../../../../../styles/base";
import NumberItem from "./numberItem.component";
import RuntimeSelect from "./runtimeSelect.component";

// TODO: TS - properly define types
export type MenuProps = {
    ownFunding?: number;

    onRuntimeChange?: (val: number) => void;
    runtimeOptions?: number[];
    runtime?: number;

    onIncomeChange?: (val: number) => void;
    income?: number;

    // Interest Types
    onInterestTypeChange?: (val: number) => void;
    interestTypeOptions?: INTEREST_TYPES_Value[];
    interestTypeSelected?: string;

    // Credit Amount
    onCreditAmountChange?: (val: number) => void;
    onOwnFundingChange?: (val: number) => void;
    creditAmountSelected?: number;

    onSubmit?: MouseEventHandler;
};

export class Menu extends Component<MenuProps> {
    render() {
        return (
            <Wrapper>
                <FirstRow>
                    <NumberItem
                        type={"number"}
                        label={"Kaufpreis"}
                        onChange={this.props.onCreditAmountChange}
                        value={this.props.creditAmountSelected}
                        width={35}
                    />

                    <NumberItem
                        type={"number"}
                        label={"Eigenkapital"}
                        onChange={this.props.onOwnFundingChange}
                        value={this.props.ownFunding}
                        width={31}
                    />
                    <NumberItem
                        type={"number"}
                        label={"Einkommen"}
                        onChange={this.props.onIncomeChange}
                        value={this.props.income}
                        width={27}
                    />
                </FirstRow>
                <SecondRow>
                    <RuntimeSelect
                        label={"Laufzeit: "}
                        onChange={this.props.onRuntimeChange}
                        selected={this.props.runtime}
                        options={this.props.runtimeOptions}
                    />
                    <SubmitBtn bold onClick={this.props.onSubmit}>
                        Vergleichen
                    </SubmitBtn>
                </SecondRow>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin-bottom: 10px;
`;

const FirstRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SecondRow = styled.div`
    align-items: flex-end;
    display: flex;
    margin: 10px 0 15px;
`;

const SubmitBtn = styled(ButtonSq)`
    height: 28px;
    line-height: 20px;
`;

export default Menu;
