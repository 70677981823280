import { useMemo } from "react";
import { useCookies } from "react-cookie";

const cookieName = "user_id";
const generateUserId = () => ~~(Math.random() * 1000000000);

// copied from https://github.com/MiraclApp/landing-page/blob/8a7631ed02f7f680fce60d7ca3a620db4a216570/src/hooks/useUserId.js
export const useUserId = () => {
    const [cookies, setCookie] = useCookies([cookieName]);
    const currentValue = cookies[cookieName];

    return useMemo(() => {
        if (currentValue) return currentValue;
        const cookieValue = generateUserId(); // Random user number
        const expirationTime = 15780000000; // One month in seconds
        const date = new Date();
        const dateTimeNow = date.getTime();
        date.setTime(dateTimeNow + expirationTime);
        setCookie(cookieName, cookieValue, {
            expires: date,
            sameSite: "none",
            secure: true,
            path: "/",
            domain: ".miracl.at",
        });
        return cookieValue;
    }, [currentValue, setCookie]);
};
