import { Link } from "react-router-dom";
import styled from "styled-components";
import AppConfig from "../../../../../config/AppConfig";
import { bpSurvey } from "../../../../styles/constants/breakpoints";

export type HubHeaderComponentProps = {
    backLogo?: boolean;
    title: React.ReactNode;
};

const HubSubHeaderComponent = ({ backLogo = true, title }: HubHeaderComponentProps): React.ReactElement | null => {
    return (
        <Wrapper>
            <Inner>
                <Main>
                    {backLogo && (
                        <Link to={AppConfig.urls.hub.index}>
                            <img
                                src="/assets/svg/Icons_1farbe/Miracl_icons_arrow_left Kopie.svg"
                                height="32"
                                width="76"
                                alt="back"
                            />
                        </Link>
                    )}
                    <Title>{title}</Title>
                    <Empty />
                </Main>
            </Inner>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    align-items: center;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 1px -1px rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const Inner = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;

    @media (min-width: ${bpSurvey.mediumMin}) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 440px;
        padding-left: 0;
        padding-right: 0;
    }
`;

const Main = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const Empty = styled.div`
    width: 40px;
`;

const Title = styled.p`
    color: #000;
    display: block;
    font-size: 22px;
    font-weight: lighter;
    letter-spacing: 0px;
    line-height: normal;
`;

export default HubSubHeaderComponent;
