import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import { Answer } from "../../../new/common/Answers";
import InputTypes from "../../../new/common/ComponentTypes";
import { isPriceMin30K } from "../../../new/common/PageComponentConditionals";
import {
    expectOtherThanYes,
    expectYesOrInvalidAnswer,
    hasAnswerAndIsNotUnknown,
    isActiveVisitting,
    isBuyingProperty,
    isFoundProperty,
    isLookingForPropertyOrActiveVisitting,
    isNotBuyingProperty,
    isNotLookingForPropertyOrActiveVisitting,
    isNotRefinancing,
    isRefinancing,
} from "../../../new/common/PageComponentValidators";
import CommonProperties from "../../../new/common/Properties";
import type { PageStructure } from "../types";

export const pageStructure: PageStructure = {
    CALCULATION_INTENTION: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Kennst du deinen Kreditbetrag?",
        property: null,
        attributes: {
            value: Answer.Conditional.YES,
            options: [Answer.Conditional.YES, Answer.CALCULATION_INTENTION.NO__CALCULATE_NOW],
            placeholder: null,
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle ob du deinen Kreditbetrag berechnen möchtest.",
            invalid: null,
        },
        tooltip: null,
        gamification: {
            [Answer.CALCULATION_INTENTION.NO__CALCULATE_NOW]:
                "Mit ein paar Infos können wir hier zusammen deinen Kreditbetrag ermitteln.",
        },
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "PROGRESS",
                expression: isFoundProperty,
            },
        ],
        conditional: null,
    },
    REAL_STATE_FOUND: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Wunschimmobilie schon gefunden?",
        property: CommonProperties.REAL_STATE_FOUND,
        attributes: {
            value: null,
            options: [Answer.Conditional.YES, Answer.Conditional.NO],
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle ob du schon eine Immobilie gefunden hast.",
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "PROGRESS",
                expression: isActiveVisitting,
            },
        ],
        conditional: null,
    },
    REFINANCING_DEBT_LEFT_AMOUNT: {
        type: InputTypes.numericInput,
        label: "Restschuld",
        property: CommonProperties.REFINANCING_DEBT_LEFT_AMOUNT,
        attributes: {
            value: null,
            placeholder: "z. B. 50.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe den Betrag deiner Restschuld an.",
            invalid: null,
        },
        // TODO: ADD TOOLTIP if needed
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isRefinancing,
            },
        ],
        conditional: null,
    },
    LOAN_AMOUNT: {
        type: InputTypes.numericInput,
        label: "Kreditbetrag",
        property: CommonProperties.LOAN_AMOUNT,
        attributes: {
            value: null,
            placeholder: "z. B. 300.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Betrag an.",
            invalid: "Betrag muss mind. 30.000 € sein.",
        },
        tooltip:
            "Im Kreditbetrag ist der Kaufpreis sowie alle Nebenkosten enthalten. Dazu zählt unter anderem die Maklerprovision sowie die Notarkosten.",
        gamification: {
            [Answer.Conditional.NO]: "Mit wenigen Infos hier können wir gerne zusammen deinen Kreditbetrag ermitteln.",
        },
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectYesOrInvalidAnswer,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotRefinancing,
            },
        ],
        conditional: [
            {
                expression: isPriceMin30K,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                        component: "LOAN_AMOUNT",
                    },
                ],
            },
        ],
    },
    LOAN_AMOUNT_BUYING: {
        type: InputTypes.numericInput,
        label: "Kreditbetrag",
        property: CommonProperties.LOAN_AMOUNT_BUYING,
        attributes: {
            value: null,
            placeholder: "z. B. 300.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Betrag an.",
            invalid: "Betrag muss mind. 30.000 € sein.",
        },
        tooltip:
            "Im Kreditbetrag ist der Kaufpreis sowie alle Nebenkosten enthalten. Dazu zählt unter anderem die Maklerprovision sowie die Notarkosten.",
        gamification: {
            [Answer.Conditional.NO]: "Mit wenigen Infos hier können wir gerne zusammen deinen Kreditbetrag ermitteln.",
        },
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectYesOrInvalidAnswer,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "PROGRESS",
                expression: isNotLookingForPropertyOrActiveVisitting,
            },
        ],
        conditional: [
            {
                expression: isPriceMin30K,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                        component: "LOAN_AMOUNT_BUYING",
                    },
                ],
            },
        ],
    },
    PRICE: {
        type: InputTypes.numericInput,
        label: "Kaufpreis des Objektes",
        property: CommonProperties.PRICE,
        attributes: {
            value: null,
            placeholder: "z. B. 300.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Betrag an.",
            invalid: "Kaufpreis muss mind. 50.000 € sein.",
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectOtherThanYes,
            },
        ],
        conditional: [
            {
                expression: isPriceMin30K,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                        component: "PRICE",
                    },
                ],
            },
        ],
    },
    EXPECTED_PRICE: {
        type: InputTypes.numericInput,
        label: "Gewünschter Kaufpreis?",
        property: CommonProperties.EXPECTED_PRICE,
        attributes: {
            value: null,
            placeholder: "z. B. 300.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Betrag an.",
            invalid: "Betrag muss mind. 30.000 € sein.",
        },
        tooltip:
            "Im Kreditbetrag ist der Kaufpreis sowie alle Nebenkosten enthalten. Dazu zählt unter anderem die Maklerprovision sowie die Notarkosten.",
        gamification: {
            [Answer.Conditional.NO]: "Mit wenigen Infos hier können wir gerne zusammen deinen Kreditbetrag ermitteln.",
        },
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "PROGRESS",
                expression: isLookingForPropertyOrActiveVisitting,
            },
        ],
        conditional: [
            {
                expression: isPriceMin30K,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                        component: "EXPECTED_PRICE",
                    },
                ],
            },
        ],
    },
    AVAILABLE_CAPITAL: {
        type: InputTypes.numericInput,
        label: "Vorhandenes Eigenkapital",
        property: CommonProperties.AVAILABLE_CAPITAL,
        attributes: {
            value: null,
            placeholder: "z. B. 60.000 €",
            decimalScale: 0,
            required: true,
            thousandSeparator: ".",
            decimalSeparator: ",",
            suffix: " €",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Betrag an.",
            invalid: "Eigenkapital muss mindestens 20% den Kaufpreis entsprechen.",
        },
        tooltip:
            "Wir beraten dich gerne im Detail wieviel deines vorhandenen Eigenkapitals überhaupt notwendig ist und zeigen dir alle Möglichkeiten auf. Die Höhe des Eigenkapitals ist für die Finanzierbarkeit und die Kondition ausschlaggebend.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotRefinancing,
            },
        ],

        conditional: null,
    },
    REAL_STATE: {
        type: InputTypes.singleSelectionGroupButton,
        label: "Immobilieneigentum in der Familie vorhanden?",
        property: CommonProperties.REAL_STATE,
        attributes: {
            value: null,
            options: [Answer.Conditional.YES, Answer.Conditional.NO],
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle ob du schon Immobilien im Eigentum hast.",
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
        ],
        conditional: null,
    },
    FIXED_RUNTIME_IN_YEARS: {
        type: InputTypes.slider,
        label: "Gewünschte Gesamtlaufzeit",
        property: CommonProperties.FIXED_RUNTIME_IN_YEARS,
        attributes: {
            value: "30",
            options: [10, 15, 20, 25, 30, 35],
            placeholder: "Jahre",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Wähle deine gewünschte Laufzeit.",
            invalid: null,
        },
        tooltip: "Durch eine längere Laufzeit kann deine monatliche Rate gesenkt werden.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotBuyingProperty,
            },
        ],
        conditional: null,
    },
    FIXED_RUNTIME_IN_YEARS_BUYING: {
        type: InputTypes.slider,
        label: "Gewünschte Gesamtlaufzeit",
        property: CommonProperties.FIXED_RUNTIME_IN_YEARS,
        attributes: {
            value: "30",
            options: [10, 15, 20, 25, 30, 35],
            placeholder: "Jahre",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Wähle deine gewünschte Laufzeit.",
            invalid: null,
        },
        tooltip: "Durch eine längere Laufzeit kann deine monatliche Rate gesenkt werden.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "PROGRESS",
                expression: isNotLookingForPropertyOrActiveVisitting,
            },
        ],
        conditional: null,
    },
    FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS: {
        type: InputTypes.slider,
        label: "Davon Fixzinsbindung",
        property: CommonProperties.FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS,
        attributes: {
            value: "20",
            options: [0, 5, 10, 15, 20, 25, 30],
            placeholder: "Jahre",
            required: false,
        },
        isError: [],
        errorMessage: {
            required: "Wähle deine gewünschte Fixzinsbindung.",
            invalid: null,
        },
        tooltip:
            "Das richtige Zinsmodell ist von großer Bedeutung und sollte auf deine individuelle Situation abgestimmt sein. Wir helfen dir gerne bei einem kostenlosen und unverbindlichen Beratungsgespräch weiter.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "FIXED_RUNTIME_IN_YEARS",
                expression: hasAnswerAndIsNotUnknown,
            },
        ],
        // TODO: Add check if fixzins is within runtime
        conditional: null,
    },
    FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS_BUYING: {
        type: InputTypes.slider,
        label: "Davon Fixzinsbindung",
        property: CommonProperties.FIXED_INTEREST_TYPE_RUNTIME_IN_YEARS,
        attributes: {
            value: "5",
            options: [0, 5, 10, 15, 20, 25, 30],
            placeholder: "Jahre",
            required: false,
        },
        isError: [],
        errorMessage: {
            required: "Wähle deine gewünschte Fixzinsbindung.",
            invalid: null,
        },
        tooltip:
            "Das richtige Zinsmodell ist von großer Bedeutung und sollte auf deine individuelle Situation abgestimmt sein. Wir helfen dir gerne bei einem kostenlosen und unverbindlichen Beratungsgespräch weiter.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "PROGRESS",
                expression: isNotLookingForPropertyOrActiveVisitting,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "FIXED_RUNTIME_IN_YEARS_BUYING",
                expression: hasAnswerAndIsNotUnknown,
            },
        ],
        // TODO: Add check if fixzins is within runtime
        conditional: null,
    },
    AGENT_TAX: {
        type: InputTypes.numericInput,
        label: "Maklerprovision ",
        property: CommonProperties.AGENT_TAX,
        attributes: {
            value: 3.6,
            placeholder: "Provision",
            decimalScale: 2,
            required: false,
            suffix: " %",
            decimalSeparator: ",",
            percentageLabel: "Maklerprovision",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Provision an.",
            invalid: "Es sieht so aus als wäre deine Eingabe falsch.",
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectOtherThanYes,
            },
        ],
        conditional: null,
    },
    TAX: {
        type: InputTypes.numericInput,
        label: "Grunderwerbsteuer",
        property: CommonProperties.TAX,
        attributes: {
            value: 3.5,
            placeholder: "Grunderwerbsteuer",
            decimalScale: 2,
            required: false,
            suffix: " %",
            decimalSeparator: ",",
            percentageLabel: "Steuer",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Steuersatz an.",
            invalid: "Es sieht so aus als wäre deine Eingabe falsch.",
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectOtherThanYes,
            },
        ],
        conditional: null,
    },
    NOTARY: {
        type: InputTypes.numericInput,
        label: "Notarkosten",
        property: CommonProperties.NOTARY,
        attributes: {
            value: 1.2,
            placeholder: "Notarkosten",
            decimalScale: 2,
            required: false,
            decimalSeparator: ",",
            suffix: " %",
            percentageLabel: "Notarkosten",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe deinen Provision an.",
            invalid: "Es sieht so aus als wäre deine Eingabe falsch.",
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectOtherThanYes,
            },
        ],
        conditional: null,
    },
    REGISTER_ENTRY: {
        type: InputTypes.numericInput,
        label: "Grundbucheintragung",
        property: CommonProperties.REGISTER_ENTRY,
        attributes: {
            value: 1.1,
            placeholder: "Grundbucheintragung",
            decimalScale: 2,
            required: false,
            suffix: " %",
            decimalSeparator: ",",
            percentageLabel: "Eintragungsgebühr",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gebe den Gebührensatz an.",
            invalid: "Es sieht so aus als wäre deine Eingabe falsch.",
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectOtherThanYes,
            },
        ],
        conditional: null,
    },
    LOAN_AMOUNT_SUMMARY: {
        type: InputTypes.loanAmountCardSummary,
        label: "Finanzierungsbedarf",
        property: null,
        attributes: {
            value: null,
            placeholder: "Finanzierungsbedarf",
            required: false,
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                component: "CALCULATION_INTENTION",
                expression: expectOtherThanYes,
            },
        ],
        conditional: null,
    },
};
