import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export type ProgressBarProps = {
    steps: number;
    activeStep: number;
};

export class ProgressBar extends Component<ProgressBarProps> {
    render() {
        const steps = [];

        for (let i = 0; i < this.props.steps; i++) {
            steps.push(this.props.activeStep > i);
        }

        return (
            <Wrapper className="survey-progress">
                <Bar>
                    {steps.map((step, idx) => (
                        <Step key={idx} isActive={step} className={`progress-${idx} ${step ? "active" : ""}`} />
                    ))}
                </Bar>
                <TargetText>Fertig</TargetText>
            </Wrapper>
        );
    }
}

// TODO: Safe space on top of indicator
const Wrapper = styled.div`
    margin-bottom: 12px;
    padding: 4px 0 0;
`;

const Bar = styled.div`
    display: flex;
    flex-flow: nowrap;
    padding: 0 6px;
`;
const Step = styled.div`
    background: ${colors.gray200};
    border-radius: 6px;
    flex: 1;
    height: 6px;
    margin: 0 4px;
    position: relative;

    &:after {
        background: ${colors.blue300};
        border-radius: 6px;
        content: "";
        display: block;
        height: 100%;
        width: ${({ isActive }) => (isActive ? "100%" : "0%")};
        transition: width 0.2s ease-in-out;
    }
`;

const TargetText = styled.div`
    color: ${colors.basicText};
    font-size: 12px;
    font-weight: 400;
    margin: 4px 5px 0;
    text-align: right;
`;

export default ProgressBar;
