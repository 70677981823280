import styled, { css } from "styled-components";
import { breakpointsNew } from "./constants/breakpoints";
import { colors } from "./constants/colors";

export const H1Css = css``;
export const H1 = styled.h1`
    ${H1Css};
`;

export const H2Css = css`
    color: ${colors.gray600};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;
export const H2 = styled.h2`
    ${H2Css};
`;

export const H3Css = css`
    color: ${colors.gray500};
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`;
export const H3 = styled.h3`
    ${H3Css};
`;

export const ButtonCss = css`
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    height: 52px;
    line-height: 26px;

    ${({ blue }) =>
        blue &&
        css`
            background: ${colors.blue600};
            border: 2px solid ${colors.blue600};
            color: #fff;
            font-weight: 700;
            width: 100%;
        `};

    &[disabled] {
        background: ${colors.gray400};
        border: 0;
        cursor: pointer;
    }

    &:focus,
    &:active {
        border: 2px solid ${colors.blue600};
        outline: none;

        ${({ blue }) =>
            blue &&
            css`
                border: 2px solid ${colors.blue300};
            `};
    }

    .loader {
        display: inline-block;
        height: 22px;
        margin-left: 10px;
        vertical-align: text-bottom;
    }
`;

export const Button = styled.button`
    ${ButtonCss};
`;

export const ButtonLink = styled.a`
    ${ButtonCss};
    align-items: center;
    display: inline-flex;
    justify-content: center;
`;

export const ArrowRight = styled.span`
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    display: inline-block;
    height: 6px;
    margin-left: 3px;
    margin-bottom: 2px;
    width: 6px;
    transform: rotate(45deg);
`;

export const SurveyNewWrapper = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 375px;
    padding: 0 24px;
    width: 100%;

    @media (min-width: ${breakpointsNew.largeMin}) {
        max-width: 440px;
    }
`;

export const PageTitle = styled.h2`
    color: ${colors.gray500};
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    margin: 24px 0 42px;
    padding: 0 10px;
`;

export const InputCss = css`
    background: #fff;
    border: 1px solid ${colors.gray300};
    border-radius: 8px;
    box-sizing: border-box;
    color: ${colors.gray700};
    font-family: "Inter", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    font-weight: 700;
    font-size: 18px;
    height: 56px;
    padding: 17px 14px 0;
    width: 100%;
    &:disabled {
        background-color: ${colors.gray300};
    }
    &::placeholder {
        color: ${colors.gray400};
        font-weight: 700;
    }
    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    &:focus,
    &:active {
        border-color: ${colors.blue600};
        outline: none;
    }
`;
