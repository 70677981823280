import {
    DEFAULT_MEETINGS_LINK,
    HubActions,
    SET_FILE_DOWNLOAD_IS_ERROR,
    SET_FILE_DOWNLOAD_IS_LOADING,
    SET_FINANCING_TIMELINE,
    SET_FINANCING_TIMELINE_IS_ERROR,
    SET_FINANCING_TIMELINE_IS_LOADING,
    SET_MAGIC_LINK_IS_ERROR,
    SET_MAGIC_LINK_IS_LOADING,
    SET_MEETING_LINK_HUB,
    SET_MEETING_LINK_IS_ERROR_HUB,
    SET_MEETING_LINK_IS_LOADING_HUB,
    SET_USER_CONTACT_HUB,
    SET_USER_CONTACT_IS_ERROR_HUB,
    SET_USER_CONTACT_IS_LOADING_HUB,
} from "../actions/hub.actions";

export type Timeline = {
    index: number;
    stage: string;
    isActive: boolean;
    isDone: boolean;
    isOpenable: boolean;
    link: string | null;
    titleDate: string | null;
    calendarLink: string | null;
    videoLink: string | null;
    title: string | null;
};

export type User = {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    contactId: number | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
    created?: string | null;
};

export type HubState = {
    user: User;
    meeting: {
        link: string | null;
        meta: Record<string, any> | null;
        isLoading: boolean;
        isError: boolean;
        errorMessage: string | null;
    };
    financing: {
        timeline: Timeline | null;
        dealId: string | null;
        isLoading: boolean;
        isError: boolean;
        errorMessage: string | null;
    };
    fileDownload: {
        isLoading: boolean;
        isError: boolean;
        errorMessage: string | null;
    };
    magicLink: {
        isLoading: boolean;
        isError: boolean;
        errorMessage: string | null;
    };
};

const initialState: HubState = {
    user: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        contactId: null,
        isLoading: false,
        isError: false,
        errorMessage: null,
    },
    meeting: {
        link: DEFAULT_MEETINGS_LINK,
        meta: null,
        isLoading: false,
        isError: false,
        errorMessage: null,
    },
    financing: {
        timeline: null,
        dealId: null,
        isLoading: false,
        isError: false,
        errorMessage: null,
    },
    fileDownload: {
        isLoading: false,
        isError: false,
        errorMessage: null,
    },
    magicLink: {
        isLoading: false,
        isError: false,
        errorMessage: null,
    },
};

const HubReducer = (state: HubState = initialState, action: HubActions) => {
    switch (action.type) {
        case SET_USER_CONTACT_HUB: {
            const { firstName = "", lastName = "", email, phone, contactId, created } = action;

            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    firstName,
                    lastName,
                    email,
                    phone,
                    contactId,
                    created,
                },
                meeting: {
                    ...state.meeting,
                },
            });
        }
        case SET_USER_CONTACT_IS_LOADING_HUB: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    isLoading,
                },
                meeting: {
                    ...state.meeting,
                },
            });
        }
        case SET_USER_CONTACT_IS_ERROR_HUB: {
            const { errorMessage, isError } = action;

            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    errorMessage,
                    isError,
                },
                meeting: {
                    ...state.meeting,
                },
            });
        }
        case SET_MEETING_LINK_HUB: {
            const { link, meta } = action;

            return Object.assign({}, state, {
                user: {
                    ...state.user,
                },
                meeting: {
                    ...state.meeting,
                    link,
                    meta,
                },
            });
        }
        case SET_MEETING_LINK_IS_LOADING_HUB: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                user: {
                    ...state.user,
                },
                meeting: {
                    ...state.meeting,
                    isLoading,
                },
            });
        }
        case SET_MEETING_LINK_IS_ERROR_HUB: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                user: {
                    ...state.user,
                },
                meeting: {
                    ...state.meeting,
                    isError,
                    errorMessage,
                },
            });
        }
        case SET_FINANCING_TIMELINE_IS_ERROR: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                financing: {
                    ...state.financing,
                    isError,
                    errorMessage,
                },
            });
        }
        case SET_FINANCING_TIMELINE_IS_LOADING: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                financing: {
                    ...state.financing,
                    isLoading,
                },
            });
        }
        case SET_FINANCING_TIMELINE: {
            const { timeline, dealId } = action;

            return Object.assign({}, state, {
                financing: {
                    ...state.financing,
                    timeline: timeline,
                    dealId: dealId,
                },
            });
        }
        case SET_FILE_DOWNLOAD_IS_LOADING: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                fileDownload: {
                    ...state.fileDownload,
                    isLoading,
                },
            });
        }
        case SET_FILE_DOWNLOAD_IS_ERROR: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                fileDownload: {
                    ...state.fileDownload,
                    isError,
                    errorMessage,
                },
            });
        }
        case SET_MAGIC_LINK_IS_LOADING: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                magicLink: {
                    ...state.magicLink,
                    isLoading,
                },
            });
        }
        case SET_MAGIC_LINK_IS_ERROR: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                magicLink: {
                    ...state.magicLink,
                    isError,
                    errorMessage,
                },
            });
        }
        default:
            return state;
    }
};

export default HubReducer;
