import { Component } from "react";
import styled from "styled-components";
import { DEFAULT_MEETINGS_LINK } from "../../../../../redux/actions/hub.actions";
import { openUrlInNewTab } from "../../../../../service/common/helper";
import GtmServiceInstance from "../../../../../service/gtm/gtm.service";
import { bpSurvey } from "../../../../styles/constants/breakpoints";
import NavigationalNextButton from "../NavigationalNextButton";

export type AdvisorAvatarProps = {
    titleWithAppointment?: React.ReactNode;
    titleWithoutAppointment?: React.ReactNode;
    mainText?: React.ReactNode;
    meetingMeta?: {
        firstName?: string;
        lastName?: string;
        phone?: string;
        email?: string;
    };
    meetingLink?: string;
    minimal?: boolean;
    isAppointmentAvailable: boolean;
    isAdvisorAssigned: boolean;
    isLoadingMeetingLink?: boolean;
};

export class AdvisorAvatar extends Component<AdvisorAvatarProps> {
    renderAdvisorProfile() {
        if (this.props.isAdvisorAssigned && this.props.meetingMeta) {
            const { firstName = "", lastName = "", phone = null, email = null } = this.props.meetingMeta;

            const iconPath = firstName ? `/assets/avatar/${String(firstName).toLowerCase()}.jpeg` : null;

            return (
                <Main>
                    {iconPath && (
                        <Avatar width={50} height={50} src={iconPath} round={true} name="Finanzierungsspezialist:in" />
                    )}
                    <AvatarWrapper>
                        <AvatarName>
                            {firstName} {lastName}
                        </AvatarName>
                        {phone && (
                            <LinkTopTitleBox>
                                <b>
                                    <a href={`tel:${phone}`}>{phone}</a>
                                </b>
                            </LinkTopTitleBox>
                        )}
                        {email && (
                            <LinkTopTitleBox>
                                <b>
                                    <a href={`mailto:${email}`}>{email}</a>
                                </b>
                            </LinkTopTitleBox>
                        )}
                    </AvatarWrapper>
                </Main>
            );
        }

        return null;
    }

    render() {
        const Component = this.props.minimal ? Div : Box;
        return (
            <Component>
                <HeaderTitleBox>
                    {this.props.isAppointmentAvailable ? (
                        <b>{this.props.titleWithAppointment}</b>
                    ) : (
                        <b>{this.props.titleWithoutAppointment}</b>
                    )}
                </HeaderTitleBox>

                <TopTitleBox>{this.props.mainText}</TopTitleBox>

                {this.renderAdvisorProfile()}
                {this.props.minimal ? null : (
                    <NavigationalNextButton
                        margin={22}
                        text={"Termin buchen"}
                        loadingText={"Lade Termine"}
                        disabled={this.props.isLoadingMeetingLink}
                        isLoading={this.props.isLoadingMeetingLink}
                        onClick={() => {
                            GtmServiceInstance.track({
                                event: "surveyFinishedAppointmentStart",
                            });

                            openUrlInNewTab(this.props.meetingLink || DEFAULT_MEETINGS_LINK);
                        }}
                    />
                )}
            </Component>
        );
    }
}

const Div = styled.div``;

const Box = styled.div`
    min-height: 250px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000029;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 40px auto 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-top: 16px;
    position: relative;

    @media (min-width: ${bpSurvey.mediumMin}) {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media (min-width: ${bpSurvey.largeMin}) {
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const HeaderTitleBox = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 16px;
`;

const TopTitleBox = styled.p`
    color: #5d5b5b;
    display: block;
    font-size: 15px;
    font-weight: lighter;
    margin: 0px;
`;

const AvatarName = styled.p`
    color: #000;
    display: block;
    font-size: 14px;
    font-weight: lighter;
    margin-top: 0px;
    margin-bottom: 6px;
`;

const LinkTopTitleBox = styled.span`
    display: block;
    font-size: 14px;
    font-weight: lighter;
    margin-top: 2px;
`;

const Main = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;
`;

const AvatarWrapper = styled.div`
    margin-left: 16px;
    margin-top: 16px;
`;

const Avatar = styled.img`
    border-radius: 50%;
    margin-top: 16px;
`;

export default AdvisorAvatar;
