import { Component } from "react";
import styled from "styled-components";
import validator from "validator";
import { InputCss } from "../../../../styles/newStyle";
import QuestionItem from "./QuestionItem";

const ENTER_KEY = "Enter";

export type TextareaProps = {
    label?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    value?: string | null;
    errors: React.ReactNode[];
    onChange: (value: { value: string | null }) => void;
    pageName: string;
    tooltip?: string;
    tooltipAlignBottom?: boolean;
    helperText?: string;
    required?: boolean;
    property: string;
    disabled?: boolean;
};

export class Textarea extends Component<TextareaProps> {
    state = {
        input: this.props.value || "",
        /* Pre-set default values for error message */
        isEmpty: !this.props.value ? true : false,
    };

    handleInputChange = (e) => {
        this.setState({
            input: e.target.value,
            isEmpty: !e.target.value ? true : false,
        });

        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
    };

    render() {
        return (
            <QuestionItem
                tooltip={this.props.tooltip}
                wrapperWidth="100%"
                errors={this.props.errors}
                pageName={this.props.pageName}
            >
                <label>
                    <StyledTextarea
                        autoFocus={this.props.autoFocus}
                        onBlur={() => {
                            this.props.onChange({
                                value: this.state.input,
                            });
                        }}
                        onKeyDown={(e) => {
                            if (validator.equals(e.key, ENTER_KEY)) {
                                this.props.onChange({
                                    value: this.state.input,
                                });
                            }
                        }}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        onChange={this.handleInputChange}
                        value={this.state.input}
                        name={this.props.property}
                        label={this.props.label}
                        disabled={this.props.disabled}
                    />
                    <span className="label">
                        {this.props.label}
                        {this.props.required && <span className="required">&nbsp;*</span>}
                    </span>
                </label>
            </QuestionItem>
        );
    }

    static defaultProps = { label: null, autoFocus: false, placeholder: "", value: null };
}

const StyledTextarea = styled.textarea`
    ${InputCss};
    min-height: 82px;
    line-height: 1.2;
    overflow: hidden;
    padding-bottom: 17px;
    padding-top: 25px;
    resize: none;
`;

export default Textarea;
