import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import { Answer } from "../../../new/common/Answers";
import InputTypes from "../../../new/common/ComponentTypes";
import {
    isBuyingProperty,
    isNotGenossenschaftContributionOrOther,
    isOwnConstruction,
    isRefinancing,
} from "../../../new/common/PageComponentValidators";
import CommonProperties from "../../../new/common/Properties";
import { PageStructure } from "../types";

export const pageStructure: PageStructure = {
    INTENTION: {
        type: InputTypes.textDropDown,
        label: "Vorhaben",
        property: CommonProperties.INTENTION,
        attributes: {
            value: null,
            options: [
                Answer.INTENTION.BUYING_PROPERTY,
                Answer.INTENTION.OWN_CONSTRUCTION,
                Answer.INTENTION.RE_FINANCING,
                Answer.INTENTION.OTHER,
            ],
            placeholder: "Wähle dein Vorhaben",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle dein Vorhaben.",
            invalid: null,
        },
        tooltip:
            "<ul>" +
            "<li><strong>Kauf einer Immobilie:</strong> Erwerb einer Wohnung, eines Hauses oder Grundstücks</li>" +
            "<li><strong>Eigenes Bauvorhaben:</strong> Finanzierung eines Neubaus</li>" +
            "<li><strong>Umschuldung:</strong> Umschuldung eines bestehendes Kredites</li>" +
            "<li><strong>Umbau/Renovierung:</strong> Finanzierung von Umbau-, Renovierungs- oder Modernisierungsarbeiten</li>" +
            "<li><strong>Genossenschaftsbeitrag:</strong> Finanzierung des Genossenschaftsbeitrages</li>" +
            "<li><strong>Sonstiges:</strong> Konsumkredite, Privatkredite, gewerbliche Finanzierungen und sonstige Finanzierungen</li>" +
            "</ul>",
        tooltipAlignBottom: true,
        gamification: null,
        visibility: null,
        conditional: null,
    },
    USAGE: {
        type: InputTypes.textDropDown,
        label: "Nutzung der Immobilie",
        property: CommonProperties.USAGE,
        attributes: {
            value: null,
            options: [Answer.USAGE.OWN, Answer.USAGE.RENT],
            placeholder: "Wähle die Nutzung",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte wähle dein deine Immobiliennutzung.",
            invalid: null,
        },
        tooltip: "Die Kreditkonditionen und die benötigten Dokumente können je nach Nutzungsart variieren.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isNotGenossenschaftContributionOrOther,
            },
        ],
        conditional: null,
    },
    PROGRESS: {
        type: InputTypes.textDropDown,
        label: "Projektstatus",
        property: CommonProperties.PROGRESS,
        attributes: {
            value: null,
            options: [
                Answer.PROGRESS.LOOKING_FOR_PROPERTY,
                Answer.PROGRESS.ACTIVE_VISITTING,
                Answer.PROGRESS.ABOUT_TO_SEND_OFFER,
                Answer.PROGRESS.OFFER_SENT,
                Answer.PROGRESS.RESEARCH,
            ],
            placeholder: "Wähle den Status",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte sage uns wie weit du mit dem Immobilienkauf gekommen bist.",
            invalid: null,
        },
        tooltip: "Verrate uns deinen Projektstatus, damit wir dich optimal beraten können.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isBuyingProperty,
            },
        ],
        conditional: null,
    },
    PROGRESS_OWN_CONSTRUCTION: {
        type: InputTypes.textDropDown,
        label: "Projektstatus",
        property: CommonProperties.PROGRESS_OWN_CONSTRUCTION,
        attributes: {
            value: null,
            options: [
                Answer.PROGRESS.OWN_CONSTRUCTION.PROPERTY_LAND_AVAILABLE,
                Answer.PROGRESS.OWN_CONSTRUCTION.LOOKING_FOR_PROPERTY_LAND,
            ],
            placeholder: "Wähle den Status",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte sage uns wie weit du mit deinem Bauvorhaben gekommen bist.",
            invalid: null,
        },
        tooltip: "Verrate uns deinen Projektstatus, damit wir dich optimal beraten können.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isOwnConstruction,
            },
        ],
        conditional: null,
    },
    PROGRESS_REFINANCING: {
        type: InputTypes.textDropDown,
        label: "Projektstatus",
        property: CommonProperties.PROGRESS_REFINANCING,
        attributes: {
            value: null,
            options: [Answer.PROGRESS.REFINANCING.CONCRETE_REFINANCING, Answer.PROGRESS.REFINANCING.RESEARCH],
            placeholder: "Wähle den Status",
            required: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte sage uns wie weit du mit deiner Umschuldung gekommen bist.",
            invalid: null,
        },
        tooltip: "Verrate uns deinen Projektstatus, damit wir dich optimal beraten können.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
                component: "INTENTION",
                expression: isRefinancing,
            },
        ],
        conditional: null,
    },
};
