import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { useUserId } from "./app/hooks/useUserId";
import AppConfig from "./config/AppConfig";
import store from "./redux/store";
import GtmServiceInstance from "./service/gtm/gtm.service";

export type ProvidersProps = {
    children?: React.ReactNode;
};

const Providers = ({ children }: ProvidersProps): React.ReactElement | null => {
    const userId = useUserId();
    useEffect(() => {
        GtmServiceInstance.pushDataLayer({
            user_id: userId,
        });

        if (AppConfig.sentry) Sentry.setUser({ id: userId });
    }, [userId]);
    return (
        <Provider store={store}>
            <BrowserRouter>{children}</BrowserRouter>
        </Provider>
    );
};
export default Providers;
