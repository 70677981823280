import { Component } from "react";

import { PageProps } from "../types";

import CallScheduler from "../../../new/CallScheduler";

import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";

import validator from "validator";

import { Answer } from "../../../new/common/Answers";

export class BookingConfirmationPage extends Component<PageProps> {
    render = () => {
        const email = this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.contact].pageStructure.EMAIL.attributes.value;

        const contactBy =
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.offer].pageStructure.CONTACT_BY.attributes.value;

        const showConfirmation = !(contactBy && validator.equals(contactBy, Answer.CONTACT_BY.PHONE_CALL));

        const intention =
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.INTENTION.attributes.value;

        const progress =
            this.props.workflow[SURVEY_WORKFLOW_PAGE_INDICES.keyData].pageStructure.PROGRESS.attributes.value;

        const isOther =
            validator.equals(intention, Answer.INTENTION.OTHER) ||
            (progress && validator.equals(progress, Answer.PROGRESS.RESEARCH));

        return (
            <CallScheduler
                showConfirmation={showConfirmation}
                verboseConfirmation={true}
                question={"Wann bist du am besten erreichbar?"}
                data={null}
                user={this.props.user}
                email={email}
                isGoodRate={true}
                isOther={isOther}
                notifyAboutUser={null}
            />
        );
    };
}

export default BookingConfirmationPage;
