import { Component } from "react";
import "react-dropdown/style.css";
import validator from "validator";

import QuestionItem from "./QuestionItem";

import styled from "styled-components";

import { InputCss } from "../../../../styles/newStyle";

const ENTER_KEY = "Enter";

export type EmailInputProps = {
    pageName: string;
    tooltipAlignBottom?: boolean;
    label?: string | null;
    tooltip?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    property: string;
    helperText?: string;
    errors: React.ReactNode[];
    onChange: (value: { value: string }) => void;
};

export class EmailInput extends Component<EmailInputProps> {
    state = {
        email: "",
        isEmailValid: false,
    };

    handleEmailInputChange = (e) => {
        this.setState({
            email: e.target.value,
            isEmailValid: e.target.value.length > 0 && validator.isEmail(e.target.value),
        });
    };

    render() {
        return (
            <QuestionItem
                pageName={this.props.pageName}
                tooltip={this.props.tooltip}
                tooltipAlignBottom={this.props.tooltipAlignBottom}
                wrapperWidth="100%"
                errors={this.props.errors}
                label={this.props.label}
                helperText={this.props.helperText}
            >
                <label>
                    <StyledEmailInput
                        autoFocus={this.props.autoFocus}
                        placeholder={this.props.placeholder}
                        onBlur={() => {
                            this.props.onChange({
                                value: this.state.email,
                            });
                        }}
                        type={"email"}
                        autoComplete={"email"}
                        required={this.props.required}
                        onChange={this.handleEmailInputChange}
                        value={this.state.email}
                        disabled={this.props.disabled}
                        name={this.props.property}
                        onKeyDown={(e) => {
                            if (validator.equals(e.key, ENTER_KEY)) {
                                this.props.onChange({
                                    value: this.state.email,
                                });
                            }
                        }}
                    />
                    <span className="label">
                        {this.props.label}
                        {this.props.required && <span className="required">&nbsp;*</span>}
                    </span>
                </label>
            </QuestionItem>
        );
    }
    static defaultProps = { autoFocus: false, placeholder: "z. B. david@miracl.at", disabled: false };
}

const StyledEmailInput = styled.input`
    ${InputCss};
`;

export default EmailInput;
