import { Component } from "react";
import styled from "styled-components";

import ConfirmationSection from "../../elements/survey/components/new/ConfirmationSection";
import GlobalStyles from "../../styles/global-styles.new";
import Header from "../../elements/survey/components/new/Header";
import { SurveyNewWrapper } from "../../styles/newStyle";
import TrustFooter from "../../elements/survey/components/new/TrustFooter";
import ContactCard from "../../elements/survey/components/new/ContactCard";
import { colors } from "../../styles/constants/colors";

export class BookingConfirmationContainer extends Component {
    render() {
        return (
            <>
                <GlobalStyles />
                <Header />

                <SurveyNewWrapper>
                    <Main>
                        <ConfirmationSection />
                    </Main>
                    <br />
                    <Divider></Divider>
                    <br />
                    <ContactCard />
                </SurveyNewWrapper>
                <TrustFooter />
            </>
        );
    }
}

const Main = styled.div`
    padding-top: 36px;
`;

const Divider = styled.div`
    margin-top: 36px;
    margin-bottom: 36px;
    height: 1px;
    margin-right: 16px;
    margin-left: 16px;
    background: ${colors.lightGray};
`;

export default BookingConfirmationContainer;
