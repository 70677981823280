import { Offer } from "../../models/Offer";
import {
    EngineActions,
    ERROR_CREDIT_CONDITIONS,
    LOADING_CREDIT_CONDITIONS,
    SET_CREDIT_AMOUNT_CREDIT_CONDITIONS,
    SET_CREDIT_CONDITIONS,
    SET_ERROR_AFFORDABILITY,
    SET_FILTERED_CREDIT_CONDITIONS,
    SET_GUESSED_CREDIT_AMOUNT_AFFORDABILITY,
    SET_INCOME_AFFORDABILITY,
    SET_INCOME_CREDIT_CONDITIONS,
    SET_INTEREST_TYPE_CREDIT_CONDITIONS,
    SET_IS_ERROR_TOP_OFFER,
    SET_LOADING_AFFORDABILITY,
    SET_LOADING_TOP_OFFER,
    SET_MONTHLY_EXPENSE_AFFORDABILITY,
    SET_OWN_FUNDING_AFFORDABILITY,
    SET_OWN_FUNDING_CREDIT_CONDITIONS,
    SET_RUNTIME_CREDIT_CONDITIONS,
    SET_TOP_OFFER,
} from "../actions/engine.actions";

export const INTEREST_TYPES = {
    fix: "Fixe",
    variable: "Variable",
} as const;
export type INTEREST_TYPES_Value = typeof INTEREST_TYPES[keyof typeof INTEREST_TYPES];

export const Currency = {
    EUR: "€",
};

export const RUNTIME_OPTIONS = [15, 20, 25, 30, 35];
export const DEFAULT_25_YEARS_IDX = 2;

// TODO: TS - properly define types
export type EngineState = {
    conditions: {
        // Einkommen
        income: number;
        // Eigenmittel
        ownFunding: number;
        // Kaufpreis
        creditAmount: number;
        // Lauftzeit
        runtime: number;
        runtimeOptions: number[];
        // Zinsatz-typ
        interestType: INTEREST_TYPES_Value;
        interestTypeOptions: INTEREST_TYPES_Value[];
        /* Optimized data by engine */
        data: any[];
        /* Filtered data by user input */
        filtered: any[];
        isError: boolean;
        isLoading: boolean;
        errorMessage: string | null;
    };
    affordability: {
        income?: number;
        // Eigenmittel
        ownFunding?: number;
        // Kaufpreis that was calculated by the M. E.
        monthlyExpense?: number;
        guessedCreditAmount?: number | null;
        isLoading: boolean;
        isError?: boolean;
        errorMessage: string | null;
    };
    topOffer: {
        offer: Offer | null;
        isError: boolean;
        isLoading: boolean;
        errorMessage: string | null;
    };
};

const initialState: EngineState = {
    conditions: {
        income: 2500,
        ownFunding: 15000,
        creditAmount: 250000,
        runtime: RUNTIME_OPTIONS[DEFAULT_25_YEARS_IDX],
        runtimeOptions: RUNTIME_OPTIONS,
        interestType: INTEREST_TYPES.fix,
        interestTypeOptions: [INTEREST_TYPES.fix, INTEREST_TYPES.variable],
        data: [],
        filtered: [],
        isError: false,
        isLoading: false,
        errorMessage: null,
    },
    affordability: {
        income: 2500,
        ownFunding: 15000,
        monthlyExpense: 800,
        guessedCreditAmount: null,
        isLoading: false,
        errorMessage: null,
    },
    topOffer: {
        offer: null,
        isError: false,
        isLoading: false,
        errorMessage: null,
    },
};

const EngineReducer = (state: EngineState = initialState, action: EngineActions) => {
    switch (action.type) {
        case SET_TOP_OFFER: {
            const { offer } = action;

            return Object.assign({}, state, {
                topOffer: {
                    ...state.topOffer,
                    offer,
                },
            });
        }
        case SET_LOADING_TOP_OFFER: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                topOffer: {
                    ...state.topOffer,
                    isLoading,
                },
            });
        }
        case SET_IS_ERROR_TOP_OFFER: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                topOffer: {
                    ...state.topOffer,
                    isError,
                    errorMessage,
                },
            });
        }
        case SET_CREDIT_CONDITIONS: {
            const { data } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    data,
                },
            });
        }
        case SET_FILTERED_CREDIT_CONDITIONS: {
            const { filtered } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    filtered,
                },
            });
        }
        case ERROR_CREDIT_CONDITIONS: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    isError,
                    errorMessage,
                },
            });
        }
        case LOADING_CREDIT_CONDITIONS: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    isLoading,
                },
            });
        }
        case SET_RUNTIME_CREDIT_CONDITIONS: {
            const { runtime } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    runtime,
                },
            });
        }
        case SET_INCOME_CREDIT_CONDITIONS: {
            const { income } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    income,
                },
            });
        }
        case SET_OWN_FUNDING_CREDIT_CONDITIONS: {
            const { ownFunding } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    ownFunding,
                },
            });
        }
        case SET_CREDIT_AMOUNT_CREDIT_CONDITIONS: {
            const { creditAmount } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    creditAmount,
                },
            });
        }
        case SET_INTEREST_TYPE_CREDIT_CONDITIONS: {
            const { interestType } = action;

            return Object.assign({}, state, {
                conditions: {
                    ...state.conditions,
                    interestType,
                },
            });
        }
        case SET_INCOME_AFFORDABILITY: {
            const { income } = action;

            return Object.assign({}, state, {
                affordability: {
                    ...state.affordability,
                    income,
                },
            });
        }
        case SET_OWN_FUNDING_AFFORDABILITY: {
            const { ownFunding } = action;

            return Object.assign({}, state, {
                affordability: {
                    ...state.affordability,
                    ownFunding,
                },
            });
        }
        case SET_GUESSED_CREDIT_AMOUNT_AFFORDABILITY: {
            const { guessedCreditAmount } = action;

            return Object.assign({}, state, {
                affordability: {
                    ...state.affordability,
                    guessedCreditAmount,
                },
            });
        }
        case SET_ERROR_AFFORDABILITY: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                affordability: {
                    ...state.affordability,
                    isError,
                    errorMessage,
                },
            });
        }
        case SET_LOADING_AFFORDABILITY: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                affordability: {
                    ...state.affordability,
                    isLoading,
                },
            });
        }
        case SET_MONTHLY_EXPENSE_AFFORDABILITY: {
            const { monthlyExpense } = action;

            return Object.assign({}, state, {
                affordability: {
                    ...state.affordability,
                    monthlyExpense,
                },
            });
        }
        default:
            return state;
    }
};

export default EngineReducer;
