import parseHtml from "html-react-parser";
import { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import QuestionItem from "./QuestionItem";

export type CheckBoxProps = {
    label?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    value?: boolean;
    tooltip?: string;
    hidden?: boolean;
    property: string;
    errors: React.ReactNode[];
    pageName: string;
    disabled?: boolean;
    onChange: (value: { value: boolean }) => void;
};

export class CheckBox extends Component<CheckBoxProps> {
    state = {
        input: this.props.value,
    };

    handleInputChange = (e) => {
        this.setState(
            {
                input: e.target.checked,
            },
            () => {
                this.props.onChange({
                    value: this.state.input,
                });
            },
        );
    };

    render() {
        const checkBoxSVG = "/assets/svg/checkBox.svg";
        const checkBoxCheckedSVG = "/assets/svg/checkBoxChecked.svg";

        return (
            <QuestionItem
                tooltip={this.props.tooltip}
                wrapperWidth="100%"
                errors={this.props.errors}
                pageName={this.props.pageName}
            >
                <Label isHidden={this.props.hidden} data-field={this.props.property}>
                    {!this.props.hidden && (
                        <>
                            <input
                                type={"checkbox"}
                                onChange={this.handleInputChange}
                                disabled={this.props.disabled}
                                checked={this.state.input}
                                value={String(this.state.input)}
                                name={this.props.property}
                                hidden
                            />
                            <img
                                src={this.state.input ? checkBoxCheckedSVG : checkBoxSVG}
                                width={18}
                                height={18}
                                alt=""
                            />
                        </>
                    )}
                    <span>{parseHtml(this.props.label)}</span>
                </Label>
            </QuestionItem>
        );
    }

    static defaultProps = { label: null, autoFocus: true, placeholder: "", value: false };
}

const Label = styled.label`
    color: ${colors.gray500};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-left: ${({ isHidden }) => (isHidden ? 0 : "25px")};

    img {
        cursor: pointer;
        display: inline-block;
        left: 0;
        position: absolute;
        top: 0;
    }

    a {
        color: ${colors.blue500};
        text-decoration: underline;
    }
`;

export default CheckBox;
