import { Component } from "react";
import styled from "styled-components";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

export class BankCarousel extends Component {
    render() {
        return (
            <Wrapper>
                <Swiper
                    // TODO: Autoswipe the bank logos
                    autoplay={true}
                    slidesPerView={"auto"}
                    loop={true}
                    spaceBetween={20}
                    speed={400}
                    effect="slide"
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    modules={[Navigation]}
                >
                    <SwiperSlide className="bawag">
                        <img src="/assets/banks/bank.bawag.svg" height="25" alt="Bawag" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/bank.wuestenrot.svg" height="20" alt="Wüstenrot" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/bank.austria.svg" height="18" alt="Bank Austria" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/bank.volksbank.svg" height="15" alt="Volksbank" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/raiffeisen.svg" height="20" alt="Raiffeisen" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/bank.99.svg" height="20" alt="Bank 99" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/oberbank.svg" height="14" alt="Oberbank" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/hypo.noe.svg" height="18" alt="HYPO NOE" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/banks/sbausparkasse.svg" height="21.5" alt="S Bausparkasse" />
                    </SwiperSlide>
                </Swiper>
                <button className="swiper-button swiper-button-prev">
                    <img src="/assets/svg/arrowLeftNarrow.svg" height="16" width="6" alt="" />
                </button>
                <button className="swiper-button swiper-button-next">
                    <img src="/assets/svg/arrowRightNarrow.svg" height="16" width="6" alt="" />
                </button>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    margin: 17px 0 38px;
    padding: 0 20px;
    position: relative;

    .swiper-slide {
        display: inline-block;
        padding: 5px 0;
        width: auto;

        &.bawag {
            margin-top: -3px;
        }
    }
    .swiper-button {
        background: none;
        border: 0;
        cursor: pointer;
        margin-top: -11px;
        padding: 2px 5px;
        position: absolute;
        top: 50%;
    }
    .swiper-button-next {
        right: -5px;
    }
    .swiper-button-prev {
        left: -5px;
    }
    img {
        display: inline-block;
        opacity: 0.72;
        vertical-align: middle;
    }
`;

export default BankCarousel;
