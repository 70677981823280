import { Component } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../styles/constants/colors";
import { InputCss } from "../../../../styles/newStyle";
import QuestionItem from "./QuestionItem";

export type DropDownProps = {
    label?: string | null;
    autoFocus?: boolean;
    placeholder?: string;
    value?: string | null;
    options: string[];
    errors: React.ReactNode[];
    gamification: Record<string, string>;
    tooltipAlignBottom?: boolean;
    tooltip?: string;
    pageName: string;
    helperText?: string;
    onChange: (value: { value: string }) => void;
    property: string;
    required?: boolean;
};

export class DropDown extends Component<DropDownProps> {
    getGamification() {
        if (this.props.gamification && this.props.value) {
            return this.props.gamification[this.props.value];
        }

        return null;
    }

    render() {
        return (
            <QuestionItem
                tooltip={this.props.tooltip}
                tooltipAlignBottom={this.props.tooltipAlignBottom}
                wrapperWidth="100%"
                errors={this.props.errors}
                pageName={this.props.pageName}
                gamification={this.getGamification()}
                label={this.props.label}
                helperText={this.props.helperText}
            >
                <label>
                    <SelectWrapper>
                        <StyledSelect
                            label={this.props.label}
                            onChange={(e) => {
                                this.props.onChange({
                                    value: e.target.value,
                                });
                            }}
                            placeholder={this.props.placeholder}
                            value={this.props.value ? this.props.value : ""}
                            name={this.props.property}
                            required={this.props.required}
                        >
                            {this.props.placeholder && !this.props.value && (
                                <option value="" disabled>
                                    {this.props.placeholder}
                                </option>
                            )}
                            {this.props.options.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </StyledSelect>
                        <span className="label">
                            {this.props.label}
                            {this.props.required && <span className="required">&nbsp;*</span>}
                        </span>
                    </SelectWrapper>
                </label>
            </QuestionItem>
        );
    }
    static defaultProps = { label: null, autoFocus: true, placeholder: "", value: null, options: [], errors: [] };
}

const SelectWrapper = styled.div`
    position: relative;
    width: 100%;

    &:before {
        border-top: 2px solid ${colors.gray500};
        border-right: 2px solid ${colors.gray500};
        content: "";
        height: 6px;
        pointer-events: none;
        position: absolute;
        right: 16px;
        top: 22px;
        transform: rotate(135deg);
        width: 6px;
    }

    .label {
        box-sizing: border-box;
        pointer-events: none;
        overflow: hidden;
        right: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const StyledSelect = styled.select`
    ${InputCss}
    padding-right: 32px;
    text-overflow: ellipsis;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ${({ value, placeholder }) =>
        !value &&
        placeholder &&
        css`
            color: ${colors.gray400};
            font-weight: 700;
        `};
`;

export default DropDown;
