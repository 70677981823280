import * as Sentry from "@sentry/react";

import axios from "axios";

import AppConfig from "../config/AppConfig";

import { FinancingObject } from "../models/Engine";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WithToken<T extends Record<string, any> = unknown> = T & { token: string };

const apiInstance = axios.create({
    baseURL: `${AppConfig.applicationHost}/api`,
    timeout: 30000,
});

// TODO: TS - properly define types for all API requests

export type SubmitUserContactsRequestProps = { properties: any; cookies: any; sendMagicLink: boolean };

export function submitUserContactsRequest({ properties, cookies, sendMagicLink }: SubmitUserContactsRequestProps) {
    return apiInstance.put("/contacts", {
        cookies,
        properties,
        sendMagicLink,
    });
}

export function getUserContactsRequest({ token }: WithToken) {
    return apiInstance.get(`/contacts`, { headers: { token } });
}

export function getCreditConditions({ salary, capital, credit, creditPurpose, fixedYears }: FinancingObject) {
    return apiInstance.post(`/engine/conditions`, {
        salary,
        capital,
        credit,
        creditPurpose,
        fixedYears,
    });
}

export function getGuessedCreditAffordabilityRequest({ salary, capital, monthlyExpense }: FinancingObject) {
    return apiInstance.post(`/engine/affordability`, {
        salary,
        capital,
        monthlyExpense,
    });
}

export function getTopOfferRequest(financingObject: FinancingObject) {
    return apiInstance.post("/engine/topoffer", {
        ...financingObject,
    });
}

export function getMagicLinkRequest(data: any) {
    return apiInstance.post("/user/token/magic/login", data);
}

export function validateMagicLinkRequest({ token }: WithToken) {
    return apiInstance.post("/user/token/magic/validate", {
        token,
    });
}

export function validateToken({ token }: WithToken) {
    return apiInstance.post("/user/token/validate", {
        token,
    });
}

export function getMeetingLinkRequest({ token }: WithToken) {
    return apiInstance.get("/user/meeting", { headers: { token } });
}

export function getFinancingTimelineRequest({ token, contactId }: WithToken<{ contactId: number }>) {
    return apiInstance.get(`/user/timeline/${contactId}`, { headers: { token } });
}

export function getFileByIdRequest({ token, id }: WithToken<{ id: string }>) {
    return apiInstance.get(`/user/timeline/documents/${id}`, {
        headers: { token },
        responseType: "blob",
    });
}

export async function getDealFiles({ token, id }: WithToken<{ id: string }>) {
    try {
        return await apiInstance.get(`/user/deals/${id}/documents`, {
            headers: { token },
        });
    } catch (error) {
        Sentry.captureException(`Failed to get documents for deal ${id}. ${error.message}`);
        return undefined;
    }
}

export async function getDealRequiredFiles({ token, id }: WithToken<{ id: string }>) {
    try {
        return await apiInstance.get(`/user/deals/${id}/documents/required`, {
            headers: { token },
        });
    } catch (error) {
        Sentry.setContext("RequiredDocuments", { form: JSON.stringify(error), id });
        return undefined;
    }
}

export async function getDocumentUploadUrl({ token, dealId }: WithToken<{ dealId: string }>) {
    try {
        const response = await apiInstance.post(`/upload/documents/${dealId}/url`, undefined, {
            headers: { token },
        });
        if (!response || !response.data)
            throw new Error(`${response && response.statusText ? response.statusText : ""}`);
        return response;
    } catch (error) {
        Sentry.captureException(`Failed to get document upload url for deal ${dealId}. ${error.message}`);
        return undefined;
    }
}

export async function createDocument({ token, dealId, data }: WithToken<{ dealId: string; data: any }>) {
    try {
        const response = await apiInstance.post(`/upload/documents/${dealId}`, data, {
            headers: { token },
        });
        if (!response || !response.data)
            throw new Error(`${response && response.statusText ? response.statusText : ""}`);
        return response;
    } catch (error) {
        Sentry.captureException(`Failed to create document for deal ${dealId}. ${error.message}`);
        return undefined;
    }
}

export async function getDocumentUrl({ token, id }: WithToken<{ id: string }>) {
    try {
        const response = await apiInstance.get(`/user/documents/${id}/url`, {
            headers: { token },
        });
        if (!response || !response.data)
            throw new Error(`${response && response.statusText ? response.statusText : ""}`);
        return response;
    } catch (error) {
        Sentry.captureException(`Failed to get document url for ${id}. ${error.message}`);
        return undefined;
    }
}

export async function notifyAboutUserIntention(
    isHighIntent,
    callNow,
    contactId,
    newPhoneNumber,
    email,
    intention,
    progress,
) {
    return apiInstance.post("/contacts/notify", {
        isHighIntent,
        callNow,
        contactId,
        newPhoneNumber,
        email,
        intention,
        progress,
    });
}
