import { Component } from "react";
import { SURVEY_PAGE_PROPERTIES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import StructuredPageComponent from "../../structuredPage.component";
import { PageProps } from "../types";

export class OfferPage extends Component<PageProps> {
    // TODO: Modal: Erklärung zu "Wie miracl Geld verdient" => Kostenlos und unverbindlich
    render() {
        return (
            <StructuredPageComponent
                /* Hubspot state loading */
                isLoadingHubspotSubmit={this.props.isLoadingHubspotSubmit}
                /* Change of components values */
                setPageComponentValue={this.props.setPageComponentValue}
                /* Change of components errors */
                setPageComponentError={this.props.setPageComponentError}
                /* Access to the survey config */
                workflow={this.props.workflow}
                /* Access to the survey version */
                surveyVersion={this.props.surveyVersion}
                /* Page config name for analytics */
                pageName={SURVEY_PAGE_PROPERTIES.offer}
                /* Page Structure defining the elements to be rendered*/
                pageStructure={this.props.page.pageStructure}
                /* Convenient Current page data */
                page={this.props.page}
                /* Handler for moving to the next page */
                onNextSurveyPage={this.props.onNextSurveyPage}
                /* Handler for moving to the prev page */
                onPreviousSurveyPage={this.props.onPreviousSurveyPage}
                /* Show error after creating contact failed */
                createContactError={this.props.createContactError}
            />
        );
    }
}

export default OfferPage;
