import {
    SessionActions,
    SET_SESSION_TOKEN,
    SET_SESSION_TOKEN_ERROR,
    SET_SESSION_TOKEN_IS_LOADING,
} from "../actions/session.actions";

export type SessionState = {
    token: string | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
};

const initialState: SessionState = {
    token: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

const sessionReducer = (state: SessionState = initialState, action: SessionActions): SessionState => {
    switch (action.type) {
        case SET_SESSION_TOKEN: {
            const { token } = action;

            return Object.assign({}, state, {
                token,
            });
        }
        case SET_SESSION_TOKEN_IS_LOADING: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                isLoading,
            });
        }
        case SET_SESSION_TOKEN_ERROR: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                isError,
                errorMessage,
            });
        }
        default:
            return state;
    }
};

export default sessionReducer;
