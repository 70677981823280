import { Component } from "react";
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import AppConfig from "../config/AppConfig";
import { getMagicLink, initApp, validateMagicToken } from "../redux/actions/session.actions";
import { RootState, ThunkBaseDispatch } from "../redux/store";
import { Callback } from "../redux/types";
import GtmServiceInstance from "../service/gtm/gtm.service";
import { AffordabilityCalculatorContainer } from "./container/affordabilityCalculator.container";
import HubContainer from "./container/hub.container";
import { LoginContainer } from "./container/login.container";
import { MagicLinkSentContainer } from "./container/magicLinkSentPage.container";
import MortgageCalculatorContainer from "./container/mortgageCalculator.container";
import SurveyContainer from "./container/new/SurveyContainer";
import RegistrationContainer from "./container/registration.container";
import ProtectedRoute from "./protected.router";
import { PageLoading } from "./styles/base";
import { isAppointmentBookingComplete } from "./util/isAppointmentBookingComplete";
import BookingConfirmationContainer from "./container/new/bookingConfirmation.container";

export interface AppRouterProps extends RouteComponentProps {
    isSessionActive?: boolean;
}

class AppRouter extends Component<AppRouterProps & AppRouterDispatchProps & AppRouterStateProps> {
    componentDidMount() {
        this.props.initAppSession((err) => {
            if (!err && this.props.sessionToken) {
                this.trackPageView();
            }
        });

        this.isFinishedAppointRedirectFromHubspot();
    }

    isFinishedAppointRedirectFromHubspot = () => {
        if (isAppointmentBookingComplete(this.props.location)) {
            GtmServiceInstance.track({
                event: "surveyFinishedAppointmentEnd",
            });
        }
    };

    trackPageView = () => {
        const newDate = new Date();
        newDate.setTime(parseInt(this.props.created) * 1000);

        GtmServiceInstance.track({
            event: "hubPageView",
            id: this.props.contactId,
            created: newDate.toUTCString(),
            createdInTimeStamp: this.props.created,
        });
    };

    renderLoading() {
        return (
            <PageLoading style={{ paddingTop: "225px" }}>
                <div>Lädt...</div>
                <Oval wrapperClass="loader" color="#aaa" secondaryColor="#aaa" height={76} width={76} />
            </PageLoading>
        );
    }

    renderRoute() {
        const routeProps = {
            isSessionActive: this.props.isSessionActive,
            sessionToken: this.props.sessionToken,
            isValidatingSessionToken: this.props.isValidatingSessionToken,
        };

        return (
            <Switch>
                {/*Main Survey Route */}
                <Route exact path={AppConfig.urls.survey.index}>
                    <SurveyContainer />
                </Route>

                {/*Main Hub (Dashboard) Route */}
                <Route exact path={AppConfig.urls.hub.index}>
                    <ProtectedRoute {...routeProps}>
                        <HubContainer />
                    </ProtectedRoute>
                </Route>

                {/* Financing timeline Route - Links to main hub */}
                <Route exact path={AppConfig.urls.hub.financingTimeline}>
                    <Redirect to={AppConfig.urls.hub.index} />
                </Route>

                {/* Nested credit conditions comparison Route */}
                <Route exact path={AppConfig.urls.hub.creditConditionsComparison}>
                    <ProtectedRoute {...routeProps}>
                        <MortgageCalculatorContainer />
                    </ProtectedRoute>
                </Route>

                {/* Nested credit affordability calculator Route */}
                <Route exact path={AppConfig.urls.hub.affordabilityCalculator}>
                    <ProtectedRoute {...routeProps}>
                        <AffordabilityCalculatorContainer />
                    </ProtectedRoute>
                </Route>

                {/* Login Route */}
                <Route exact path={AppConfig.urls.hub.login}>
                    <LoginContainer
                        location={this.props.location}
                        history={this.props.history}
                        getMagicLink={this.props.getMagicLinkForUser}
                        validateMagicToken={this.props.validateMagicTokenForUser}
                        trackPageView={this.trackPageView}
                        sessionToken={this.props.sessionToken}
                        isLoading={this.props.isLoadingMagicLink}
                    />
                </Route>

                {/* Register Route */}
                <Route exact path={AppConfig.urls.hub.register}>
                    <RegistrationContainer />
                </Route>

                {/* Magic Link Sent Confirmation Route */}
                <Route exact path={AppConfig.urls.hub.loginMagicLinkSent}>
                    <MagicLinkSentContainer />
                </Route>

                {/* Successful appt-booking confirmation Screen */}
                <Route exact path={AppConfig.urls.survey.confirmation}>
                    <BookingConfirmationContainer />
                </Route>
            </Switch>
        );
    }

    render() {
        return (
            <>
                <GlobalStyle />
                {(this.props.isLoadingGoogleOptimize ||
                    this.props.isValidatingSessionToken ||
                    this.props.isLoadingUserData) &&
                    this.renderLoading()}
                {!this.props.isLoadingGoogleOptimize && this.renderRoute()}
            </>
        );
    }
}

const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
`;

const mapStateToProps = (state: RootState) => {
    return {
        isLoadingGoogleOptimize: state.config.optimize.isLoading,
        isValidatingSessionToken: state.session.isLoading,
        sessionToken: state.session.token,
        isLoadingMeetingLink: state.hub.meeting.isLoading,
        isLoadingUserData: state.hub.user.isLoading,
        isLoadingMagicLink: state.hub.magicLink.isLoading,
        contactId: state.hub.user.contactId,
        created: state.hub.user.created,
    };
};

type AppRouterStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        initAppSession: (callback: Callback) => dispatch(initApp(callback)),
        // TODO: TS - properly define type for data (type should come from API request method type), getMagicLinkForUser is currently not used
        getMagicLinkForUser: (data: any, callback: Callback) => dispatch(getMagicLink(data, callback)),
        validateMagicTokenForUser: (token: string, callback: Callback) => dispatch(validateMagicToken(token, callback)),
    };
};
type AppRouterDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRouter));
