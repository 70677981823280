import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import HubSubHeaderComponent from "../elements/survey/components/header/hubSubHeader.component";
import MortgageCalculatorPage from "../elements/survey/components/pages/mortgageCalculator.component";
import { SurveyWrapper } from "../styles/base";
import { colors } from "../styles/constants/colors";
import GlobalStyles from "../styles/global-styles.hub";

export type MortgageCalculatorContainerProps = RouteComponentProps;

export class MortgageCalculatorContainer extends Component<MortgageCalculatorContainerProps> {
    render() {
        return (
            <>
                <GlobalStyles />
                <PageWrapper>
                    <HubSubHeaderComponent title={"Kreditvergleich"} />
                    <MortgageCalculatorPage />
                    <StarsBackground className="starsBg">
                        <img src="/assets/svg/starsBackground.svg" width="85" height="68" alt="" />
                    </StarsBackground>
                </PageWrapper>
            </>
        );
    }
}

const PageWrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 120px 0 60px;
    background-color: ${colors.focusBg};

    .starsBg {
        display: block;
    }
`;

const StarsBackground = styled(SurveyWrapper)`
    display: none;
    margin-top: auto;
    text-align: right;

    img {
        margin-right: 16px;
    }
`;
export default withRouter(MortgageCalculatorContainer);
