import React from "react";
import styled from "styled-components";
import { bpSurvey } from "../../../../styles/constants/breakpoints";

export type ModalProps = {
    children: React.ReactNode;
    handleModalClose?: () => void;
};

const Modal = ({ children, handleModalClose }: ModalProps): React.ReactElement | null => {
    return (
        <Wrapper onClick={handleModalClose}>
            <ModalWrapper onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>{children}</ModalWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    transition: opacity ease-out 500ms;

    > div {
        opacity: 1;
        margin-bottom: 50px;
        margin-top: 140px;
    }

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
    }
`;

const ModalWrapper = styled.div`
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    max-width: 460px;
    padding: 30px 20px;
    text-align: center;
    width: 288px;

    @media (min-width: ${bpSurvey.largeMin}) {
        max-width: 520px;
        padding: 40px 30px;
    }
`;

export default Modal;
