import { Component } from "react";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";
import { colors } from "../../../../styles/constants/colors";

export class QuickRate extends Component {
    render() {
        return (
            <Wrapper>
                {false && (
                    <>
                        <Oval
                            wrapperClass="loader"
                            color={colors.gray400}
                            secondaryColor="#fff"
                            /* strokeWidth={3}
                            strokeWidthSecondary={3} */
                            height={24}
                            width={24}
                        />
                        Wird geladen
                    </>
                )}
                {true && (
                    <Result>
                        Ab&nbsp;<Rate>3,25%</Rate>&nbsp;für 15 Jahre Fix
                    </Result>
                )}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    align-items: center;
    color: ${colors.gray400};
    display: flex;
    font-weight: 300;
    font-size: 16px;
    justify-content: center;
    line-height: 20px;
    margin-top: 30px;
    min-height: 26px;

    .loader {
        display: inline-block;
        line-height: 0.9;
        margin-right: 15px;
        vertical-align: middle;
    }
`;

const Rate = styled.span`
    color: ${colors.blue500};
    font-weight: 700;
`;

const Result = styled.div`
    color: ${colors.gray600};
    font-weight: 400;
`;

export default QuickRate;
