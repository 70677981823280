import {
    initializeWorkflow,
    SURVEY_PAGE_PROPERTIES,
    SURVEY_PAGE_SUBMIT_TYP,
    SURVEY_PAGE_TYPES,
    SURVEY_WORKFLOW_PAGE_INDICES,
} from "../config/survey.config";

import { Workflow } from "./types";

import { pageStructure as keyData } from "../../../../app/elements/survey/components/pages/new/keyDataPage/constants";

import { pageStructure as loanAmount } from "../../../../app/elements/survey/components/pages/new/loanAmountPage/constants";

import { pageStructure as personalFinances } from "../../../../app/elements/survey/components/pages/new/personalFinancesPage/constants";

import { pageStructure as offer } from "../../../../app/elements/survey/components/pages/new/offerPage/constants";

import { pageStructure as contact } from "../../../../app/elements/survey/components/pages/new/contactPage/constants";

import { pageStructure as confirmation } from "../../../../app/elements/survey/components/pages/new/bookingConfirmationPage/constants";

const workflow: Workflow = {
    flow: {
        [SURVEY_WORKFLOW_PAGE_INDICES.keyData]: {
            type: SURVEY_PAGE_TYPES.keyDataPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
            property: SURVEY_PAGE_PROPERTIES.keyData,
            progressStep: 0,
            meta: {
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.historyBack,
            },
            pageStructure: keyData,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.loanAmount]: {
            type: SURVEY_PAGE_TYPES.loanAmountPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
            property: SURVEY_PAGE_PROPERTIES.loanAmount,
            progressStep: 1,
            meta: {
                text: {
                    nextButton: "Weiter",
                },
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.keyData,
            },
            pageStructure: loanAmount,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.personalFinances]: {
            type: SURVEY_PAGE_TYPES.personalFinancesPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
            property: SURVEY_PAGE_PROPERTIES.personalFinances,
            progressStep: 2,
            meta: {
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.loanAmount,
            },
            pageStructure: personalFinances,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.offer]: {
            type: SURVEY_PAGE_TYPES.offer,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.offer,
            property: SURVEY_PAGE_PROPERTIES.offer,
            progressStep: 3,
            meta: {
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.contact,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.personalFinances,
            },
            pageStructure: offer,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.contact]: {
            type: SURVEY_PAGE_TYPES.contactPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.contact,
            property: SURVEY_PAGE_PROPERTIES.contact,
            progressStep: 4,
            meta: {
                text: {
                    navigationButtonToolTip:
                        "Unsere Berater kümmern sich persönlich um dein Anliegen - kostenlos und unverbindlich.",
                },
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                },
            },
            context: {
                defaultNextPage: SURVEY_WORKFLOW_PAGE_INDICES.confirmation,
                defaultPrevPage: SURVEY_WORKFLOW_PAGE_INDICES.offer,
            },
            pageStructure: contact,
            submitType: SURVEY_PAGE_SUBMIT_TYP.create,
        },
        [SURVEY_WORKFLOW_PAGE_INDICES.confirmation]: {
            type: SURVEY_PAGE_TYPES.confirmationPage,
            pageIndex: SURVEY_WORKFLOW_PAGE_INDICES.confirmation,
            property: SURVEY_PAGE_PROPERTIES.confirmation,
            hidePageTitle: true,
            progressStep: 5,
            meta: {
                text: null,
                ui: {
                    showNextButton: true,
                    showPrevButton: true,
                    hasStickyFooter: true,
                },
            },
            context: {
                defaultNextPage: null,
                defaultPrevPage: null,
            },
            pageStructure: confirmation,
            submitType: SURVEY_PAGE_SUBMIT_TYP.none,
        },
    },
    // Should be equal to the largest "progressStep" value of the flow pages (top-offer not included)
    totalSteps: 5,
    version: "33",
    /* You may change the page of zero for testing purposes */
    currentPage: initializeWorkflow(SURVEY_WORKFLOW_PAGE_INDICES.keyData),
};

export default workflow;
