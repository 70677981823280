import { PageStructure } from "../types";
import InputTypes from "../../../new/common/ComponentTypes";
import CommonProperties from "../../../new/common/Properties";
import { isValidEmail, isValidPhone } from "../../../new/common/PageComponentConditionals";
import { SURVEY_WORKFLOW_PAGE_INDICES } from "../../../../../../../redux/reducers/survey/config/survey.config";
import {
    contactIsNotLookingAround,
    contactOnlyViaPhoneCallOrSMS,
    contactViaEmail,
    contactViaPhoneCall,
    contactViaSMS,
    noContact,
} from "../../../new/common/PageComponentValidators";

export const pageStructure: PageStructure = {
    TITLE_CONTACT_PHONE: {
        type: InputTypes.title,
        label: null,
        property: null,
        attributes: {
            value: "Unter welcher Telefonnummer bist du erreichbar um das Angebot zu besprechen?",
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                component: "CONTACT_BY",
                expression: contactViaPhoneCall,
            },
        ],
        conditional: null,
    },
    TITLE_CONTACT_SMS: {
        type: InputTypes.title,
        label: null,
        property: null,
        attributes: {
            value: "An welche Telefonnummer sollen wir das Angebot schicken?",
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                component: "CONTACT_BY",
                expression: contactViaSMS,
            },
        ],
        conditional: null,
    },
    TITLE_CONTACT_EMAIL: {
        type: InputTypes.title,
        label: null,
        property: null,
        attributes: {
            value: "An welche E-Mail-Adresse sollen wir das Angebot verschicken?",
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                component: "CONTACT_BY",
                expression: contactViaEmail,
            },
        ],
        conditional: null,
    },
    TITLE_LOOKING_AROUND: {
        type: InputTypes.title,
        label: null,
        property: null,
        attributes: {
            value: "Wie können wir dir weiterhelfen?",
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                component: "CONTACT_BY",
                expression: noContact,
            },
        ],
        conditional: null,
    },
    FIRST_NAME: {
        type: InputTypes.textInput,
        label: "Vor- und Nachname",
        property: CommonProperties.FIRST_NAME,
        attributes: {
            value: null,
            placeholder: "z. B. Max Mustermann",
            required: true,
            autocomplete: "given-name",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib deinen Vor- und Nachnamen ein.",
            invalid: null,
        },
        // helperText: "Für wen sollen wir den Kreditvergleich machen?",
        tooltip: null,
        gamification: null,
        visibility: null,
        conditional: null,
    },
    PHONE: {
        type: InputTypes.phoneInput,
        label: "Telefonnummer",
        property: CommonProperties.PHONE,
        attributes: {
            value: null,
            placeholder: "z. B. 00436781221017",
            required: true,
            autocomplete: "tel",
            allowLeadingZeros: true,
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib deine Telefonnummer an.",
            invalid: "Überprüfe ob deine Telefonnummer korrekt ist.",
        },
        helperText: null,
        tooltip:
            "Die telefonische Beratung erlaubt es uns deinen Finanzierungswunsch im Detail zu verstehen und somit einen genauen Kreditvergleich für dich erstellen zu können.",
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                component: "CONTACT_BY",
                expression: contactOnlyViaPhoneCallOrSMS,
            },
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                component: "CONTACT_BY",
                expression: contactIsNotLookingAround,
            },
        ],
        conditional: [
            {
                expression: isValidPhone,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.contact,
                        component: "PHONE",
                    },
                ],
            },
        ],
        disabledConditional: null,
    },
    EMAIL: {
        type: InputTypes.emailInput,
        label: "E-Mail",
        property: CommonProperties.EMAIL,
        attributes: {
            value: null,
            placeholder: "z. B. max@mustermann.com",
            required: true,
            autocomplete: "email",
        },
        isError: [],
        errorMessage: {
            required: "Bitte gib deine E-Mail an.",
            invalid: "Überprüfe ob deine E-Mail korrekt ist.",
        },
        // helperText: "An diese E-Mail-Adresse werden wir alle weiteren Informationen zuschicken.",
        tooltip: null,
        tooltipAlignBottom: true,
        gamification: null,
        visibility: null,
        conditional: [
            {
                expression: isValidEmail,
                parameters: [
                    {
                        page: SURVEY_WORKFLOW_PAGE_INDICES.contact,
                        component: "EMAIL",
                    },
                ],
            },
        ],
    },
    LOOKING_AROUND_HELPER: {
        type: InputTypes.textarea,
        label: null,
        property: CommonProperties.AD_RESEARCH,
        attributes: {
            value: false,
            placeholder: "Wir kümmern uns um dein Anliegen.",
            required: false,
            hidden: true,
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: [
            {
                page: SURVEY_WORKFLOW_PAGE_INDICES.offer,
                component: "CONTACT_BY",
                expression: noContact,
            },
        ],
        conditional: null,
    },
    AGREEMENT: {
        type: InputTypes.checkBox,
        label: `Mit Klick auf "Weiter" werden die <a href='https://miracl.at/datenschutzerklaerung/' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a>, <a href='https://miracl.at/agb/' target='_blank' rel='noopener noreferrer'> die AGB</a> und das <a href='https://miracl.at/standardinfoblatt/' target='_blank' rel='noopener noreferrer'>Standardinformationsblatt</a> akzeptiert.`,
        property: CommonProperties.AGREEMENT,
        attributes: {
            value: false,
            placeholder: null,
            required: false,
            hidden: true,
        },
        isError: [],
        errorMessage: {
            required: null,
            invalid: null,
        },
        tooltip: null,
        gamification: null,
        visibility: null,
        conditional: null,
    },
};
