import { parsePhoneNumber } from "libphonenumber-js";

export const openUrlInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");

    if (newWindow) {
        newWindow.opener = null;
    }
};

export const openHistoryBack = () => {
    window.history.go(-1);
};

export const openObjectUrl = (_object: Blob | MediaSource, newWindow: Window) => {
    newWindow.location = URL.createObjectURL(_object);
};

// https://stackoverflow.com/a/21125098/198996
export function getCookie(name: string | number) {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
}

export function b64EncodeUnicode(str: string | number | boolean) {
    return btoa(encodeURIComponent(str));
}

export function UnicodeDecodeB64(str: string) {
    return decodeURIComponent(atob(str));
}

// TODO: TS - wrong type returned
export function handleCookies(): any {
    const trackedCookies = [
        "fbclid",
        "gclid",
        "user_id",
        "utm_campaign",
        "utm_content",
        "utm_medium",
        "utm_source",
        "utm_term",
    ];

    const cookieProps = {};

    trackedCookies.map((cookieName) => {
        const cookieValue = getCookie(cookieName);

        if (cookieValue) {
            let key: string;
            if (cookieName === "fbclid") {
                key = "hs_facebook_click_id";
            } else if (cookieName === "gclid") {
                key = "hs_google_click_id";
            } else {
                key = cookieName;
            }

            cookieProps[key] = cookieValue;
        }
    });

    return cookieProps;
}

export function normalizeName(fullName: string) {
    const nameSplit = fullName.split(" ");

    /* Split name if possible */
    const normalizedFirstName = nameSplit[0];

    /* If the last name is split into multiple splits, join them as the lastname  */
    const normalizedLastName = nameSplit.length === 1 ? "" : nameSplit.splice(1, nameSplit.length - 1).join(" ");

    return {
        firstName: normalizedFirstName,
        lastName: normalizedLastName,
    };
}

export function normalizePhoneNumber(phoneNumber?: string) {
    return phoneNumber ? parsePhoneNumber(phoneNumber, "AT").number : undefined;
}
