import { CookieType } from "../../models/Cookie";
import {
    HubspotActions,
    SET_APPOINTMENT_LINK,
    SET_COOKIE_PROPERTIES,
    SET_ERROR_HUBSPOT,
    SET_IS_CONTACT_ERROR,
    SET_IS_CONTACT_LOADING,
    SET_IS_EXISTING_CONTACT,
    SET_PROPERTY,
    SET_SUCCESS_HUBSPOT,
    SUBMIT_USER_HUBSPOT,
} from "../actions/hubspot.actions";

export type HubsportState = {
    cookies: CookieType[];
    /* A Property list of values collected during the user survey */
    properties: any;
    /* Success flag indicating that the property list was submitted to the miracl api */
    success: boolean;
    /* Loading flag indicating that the property list is being submitted to the miracle api */
    isLoading: boolean;
    /* Convenient error flag indicating the request for submitting the property list failed */
    isError: boolean;
    /* Error message field when submitting the property list fails */
    errorMessage?: string | null;
    contact: {
        isLoading: boolean;
        isError: boolean;
        errorMessage: string | null;
        appointmentLink: string | null;
        isExistingContact: boolean;
    };
};

const initialState: HubsportState = {
    cookies: [],
    properties: {},
    success: false,
    isLoading: false,
    isError: false,
    errorMessage: null,
    contact: {
        isLoading: false,
        isError: false,
        errorMessage: null,
        appointmentLink: null,
        isExistingContact: false,
    },
};

const hubspotReducer = (state: HubsportState = initialState, action: HubspotActions): HubsportState => {
    switch (action.type) {
        case SET_COOKIE_PROPERTIES: {
            const { cookies } = action;

            return Object.assign({}, state, {
                cookies,
            });
        }
        case SET_PROPERTY: {
            const { value, property } = action;

            state.properties[property] = value;

            return Object.assign({}, state, {
                properties: state.properties,
            });
        }
        case SUBMIT_USER_HUBSPOT: {
            return Object.assign({}, state, {
                success: false,
                isLoading: true,
                isError: false,
                errorMessage: null,
            });
        }
        case SET_ERROR_HUBSPOT: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                success: false,
                isLoading: false,
                isError,
                errorMessage,
            });
        }
        case SET_SUCCESS_HUBSPOT: {
            return Object.assign({}, state, {
                success: true,
                isLoading: false,
                isError: false,
                errorMessage: null,
            });
        }
        case SET_IS_CONTACT_ERROR: {
            const { isError, errorMessage } = action;

            return Object.assign({}, state, {
                contact: {
                    ...state.contact,
                    isError,
                    errorMessage,
                },
            });
        }
        case SET_IS_CONTACT_LOADING: {
            const { isLoading } = action;

            return Object.assign({}, state, {
                contact: {
                    ...state.contact,
                    isLoading,
                },
            });
        }
        case SET_APPOINTMENT_LINK: {
            const { appointmentLink } = action;

            return Object.assign({}, state, {
                contact: {
                    ...state.contact,
                    appointmentLink,
                },
            });
        }
        case SET_IS_EXISTING_CONTACT: {
            const { isExistingContact } = action;

            return Object.assign({}, state, {
                contact: {
                    ...state.contact,
                    isExistingContact,
                },
            });
        }
        default:
            return state;
    }
};

export default hubspotReducer;
