import { Component } from "react";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { getTopOffer } from "../../../redux/actions/engine.actions";
import {
    notifyAboutUserIntentionToSlack,
    pollMeetingsLinkAndUserContact,
    setCookieProperties,
    setProperty,
    submitUserContacts,
} from "../../../redux/actions/hubspot.actions";
import { setPageComponentError, setPageComponentValue, setSurveyPage } from "../../../redux/actions/survey.actions";
import type { Workflow } from "../../../redux/reducers/survey/workflows/types";
import { RootState, ThunkBaseDispatch } from "../../../redux/store";
import { handleCookies } from "../../../service/common/helper";
import Page from "../../elements/survey/components/pages/page.component";
import { PageLoading } from "../../styles/base";
import { colors } from "../../styles/constants/colors";
import GlobalStyles from "../../styles/global-styles.new";
import SurveyTrackerInstance from "../../../service/gtm/surveyTracker.service";

export type SurveyContainerProps = {
    currentPage: string;
    pageSize: number;
    workflow: Workflow;
};

type PageContentContainerProp = { surveyVersion: string };

type Props = SurveyContainerProps & SurveyContainerStateProps & SurveyContainerDispatchProps & RouteComponentProps;

class PageContentContainer extends Component<PageContentContainerProp> {
    componentDidMount() {
        SurveyTrackerInstance.trackSurveyStartGeneral(this.props.surveyVersion);
    }

    render() {
        return <div>{this.props.children}</div>;
    }
}

export class SurveyContainer extends Component<Props> {
    state = {
        pageHistory: [],
    };

    constructor(props: Props) {
        super(props);

        this.setPageWithHistory = this.setPageWithHistory.bind(this);
    }

    handleCookieProps = () => {
        this.props.setCookieProperties(handleCookies());
    };

    componentDidMount() {
        this.handleCookieProps();
    }

    setPageWithHistory(value: string) {
        this.setState({ pageHistory: [...this.state.pageHistory, this.props.currentPage] });
        this.props.setPage(value);
    }

    renderCurrentPage() {
        const currentPageConfig = this.props.workflow[this.props.currentPage];

        return (
            <Page
                user={this.props.user}
                getTopOffer={this.props.getTopOffer}
                topOffer={this.props.topOffer}
                engine={this.props.engine}
                isLoadingHubspotSubmit={this.props.isLoadingHubspotSubmit}
                history={this.props.history}
                key={currentPageConfig.pageIndex}
                /* Page schema used to build a page */
                page={currentPageConfig}
                /* Show / hide page based on idx workflow */
                show={true}
                /* Action to set the page index manually  */
                setPage={this.setPageWithHistory}
                /* Value change of the page structure configs */
                setPageComponentValue={this.props.setPageComponentValue}
                /* Error change of the page structure configs */
                setPageComponentError={this.props.setPageComponentError}
                /* Action for appending properties for all non-contact data creation, such as LTV, dsti etc  */
                setProperty={this.props.setProperty}
                /* Property list of collected user-data */
                properties={this.props.properties}
                /* Location router props */
                location={this.props.location}
                /* Cookie property list */
                cookieProperties={this.props.cookieProperties}
                /* Slack Notification about user sign-ups */
                notifyAboutUser={this.props.notifyAboutUser}
                /* Action for creating and submitting a user-contact */
                submitUserContacts={this.props.submitUserContacts}
                contact={this.props.contact}
                /* Action for fetching meetingLink */
                getMeetingsLink={this.props.getMeetingsLink}
                /* Workflow object of the survey */
                workflow={this.props.workflow}
                /* Current survey version / flow fetched by the redux */
                surveyVersion={this.props.surveyVersion}
                pageSize={this.props.pageSize}
            />
        );
    }

    renderLoadingPage() {
        return (
            <PageLoading>
                <div>Lädt...</div>
                <Oval wrapperClass="loader" color="#aaa" secondaryColor="#aaa" height={76} width={76} />
            </PageLoading>
        );
    }

    render() {
        if (this.props.isLoadingGoogleOptimize) {
            return this.renderLoadingPage();
        }

        return (
            <PageContentContainer surveyVersion={this.props.surveyVersion}>
                <GlobalStyles />
                <PageContent>{this.renderCurrentPage()}</PageContent>
            </PageContentContainer>
        );
    }
}

const PageContent = styled.div`
    background: ${colors.gray50};
`;

const mapStateToProps = (state: RootState) => {
    return {
        surveyVersion: state.survey.version,
        contact: state.hubspot.contact,
        properties: state.hubspot.properties,
        workflow: state.survey.flow,
        currentPage: state.survey.currentPage,
        pageSize: state.survey.totalSteps,
        nextPage: state.survey.nextPage,
        cookieProperties: state.hubspot.cookies,
        engine: state.engine,
        isLoadingGoogleOptimize: state.config.optimize.isLoading,
        isLoadingHubspotSubmit: state.hubspot.isLoading,
        topOffer: state.engine.topOffer,
        user: state.hub.user,
    };
};

type SurveyContainerStateProps = ReturnType<typeof mapStateToProps>;

// TODO: TS - properly define types for all methods (might make sense making them global/reusable as these methods are used all around)
const mapDispatchToProps = (dispatch: ThunkBaseDispatch) => {
    return {
        getTopOffer: (_financingObject, _cb) => dispatch(getTopOffer(_financingObject, _cb)),
        setPage: (_nextPage) => dispatch(setSurveyPage(_nextPage)),
        setProperty: (_property, _value) => dispatch(setProperty(_property, _value)),
        setPageComponentValue: (_page, _component, _value) =>
            dispatch(setPageComponentValue(_page, _component, _value)),
        setPageComponentError: (_page, _component, _isError) =>
            dispatch(setPageComponentError(_page, _component, _isError)),
        submitUserContacts: (_properties, _cookies, _magicLink, _cb) =>
            dispatch(submitUserContacts(_properties, _cookies, _magicLink, _cb)),
        getMeetingsLink: (_timeout) => dispatch(pollMeetingsLinkAndUserContact(_timeout)),
        notifyAboutUser: (_callNow, _phoneNumber) => dispatch(notifyAboutUserIntentionToSlack(_callNow, _phoneNumber)),
        setCookieProperties: (_props) => dispatch(setCookieProperties(_props)),
    };
};

type SurveyContainerDispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyContainer));
